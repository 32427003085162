import { ContextOptions } from "../interfaces";
import { ContextBuilder } from "./context-builder";
import { TaskContext } from "./task-context";

export abstract class Task<
  T = any,
  O = void,
  TOptions extends ContextOptions = {},
  Context extends TaskContext<T, O, TOptions> = TaskContext<T, O, TOptions>>
  extends ContextBuilder<T, O, TOptions, Context>
{
  execute(payload: T, opts?: TOptions): Promise<Context> {
    return this.executeContext(this.buildContext(payload, opts));
  }

  abstract executeContext(context: Context): Promise<Context>
}
