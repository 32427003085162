import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, ElementRef, Input, TemplateRef } from "@angular/core";
import { NgI18nGetter } from "src/@prisma/ng-i18n";
import { DefaultCreateComponent } from "../default-create.component";

@Component({
  selector: "default-form-container",
  templateUrl: "./default-form-container.component.html",
  styleUrls: ["./default-form-container.component.scss"]
})
export class DefaultFormContainerComponent<Model extends object> extends NgI18nGetter {
  private _disableDelete = false;
  private _disableDeleteMessage?: string;

  @Input() templateRef: TemplateRef<DefaultCreateComponent<Model>> | null = null;
  @Input() component!: DefaultCreateComponent<Model>;

  @Input()
  get disableDelete(): boolean { return this._disableDelete; }
  set disableDelete(value: string | boolean | undefined) {
    this._disableDelete = coerceBooleanProperty(value);
    if (!this.disableDelete) this._disableDeleteMessage = void 0;
    else if (typeof value !== "boolean" && value !== "false" && value !== "true")
      this._disableDeleteMessage = value;
  }

  get disableDeleteMessage(): string | undefined { return this._disableDeleteMessage; }

  inProcess = false;

  get btnText(): string {
    return this.component.editing ? /*@i18n*/("Edit") : /*@i18n*/("Save");
  }

  constructor(
    private element: ElementRef<HTMLElement>
  ) {
    super();
  }

  backButtonClick(): void {
    this.component.back();
  }

  deleteButtonClick(): void {
    this.component.delete();
  }

  async saveButtonClick(): Promise<void> {
    this.inProcess = true;
    this.element.nativeElement.querySelectorAll<HTMLInputElement>("input").forEach(e => {
      e.value = e.value.trim();
      e.dispatchEvent(new Event("input"));
    });
    await this.component.save();
    this.inProcess = false;
  }
}
