import { Injectable } from "@angular/core";
import { ApiConfig } from "../api-config.service";
import { NgDefaultTask } from "./default.task";

@Injectable()
export class TokenStoreTask extends NgDefaultTask {
  static STAGE = "token-store";
  MAP = { [TokenStoreTask.STAGE]: this };

  constructor(
    apiConfig: ApiConfig
  ) {
    super(context => {
      context.beforeOutput(async resp => {
        await apiConfig.setToken(resp, context);
        return resp;
      });
    });
  }
}
