import { Bulk } from "../core";
import { FormatContextOptions, FormatOptions } from "../interfaces";
import { Runner, TaskContext } from "../runner";

export class FormatterRunner<
  T extends object, Stage extends Extract<keyof T, string> = Extract<keyof T, string>>
  extends Runner<any, any, FormatContextOptions, Stage>
{
  protected override onInit(): void {
    this.setDefaultContext(TaskContext);
  }

  /**
   * Format each field of the Model as a Stage.
   */
  async format<Input extends Bulk<T> | T | T[] = Bulk<T> | T | T[]>(
    bulkOrModel: Input,
    optsOrStages: FormatOptions<Stage> | Stage[] = this.getOrder()): Promise<Input>
  {
    const stages = Array.isArray(optsOrStages)  ? optsOrStages : optsOrStages?.stages || this.getOrder();
    const event  = !Array.isArray(optsOrStages) ? optsOrStages.event : void 0;
    const bulk   = bulkOrModel instanceof Bulk ? bulkOrModel as Bulk<any> : void 0;

    const array = bulkOrModel instanceof Bulk ?
      bulkOrModel.value : Array.isArray(bulkOrModel) ?
        bulkOrModel : [ bulkOrModel ];

    for (let i = 0, value = array[i]; i < array.length; value = array[++i])
      for (let i = 0, stage = stages[i]; i < stages.length; stage = stages[++i]) {
        const context = await this
          .executeContext(this.buildContext(value[stage], { bulk, model: value, event }), [ stage ]);

        value[stage] = context.payload;
      }

    return bulkOrModel;
  }
}
