import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { NgI18nArgs } from "../ng-i18n";

export interface MatFeedProfileConfig extends MatSnackBarConfig {
  args?:       NgI18nArgs
  actionArgs?: NgI18nArgs
}

export interface MatFeedConfig {
  defaultMessage?: string | (() => string)
  default?:        MatSnackBarConfig
  profiles?:       MatFeedProfiles
}

export interface MatFeedProfiles {
  primary?:         MatSnackBarConfig
  accent?:          MatSnackBarConfig
  warn?:            MatSnackBarConfig
  success?:         MatSnackBarConfig
  [color: string]:  MatSnackBarConfig | undefined
}
