import { FormatContextOptions } from "../interfaces";
import { Task, TaskContext } from "../runner";

export abstract class FormatTaskTemplate<T extends object = any>
  extends Task<any, any, FormatContextOptions<T>>
{
  constructor() {
    super();
    this.setDefaultContext(TaskContext);
  }

  abstract configure(...args: any[]): this

  abstract format(value: any, context: TaskContext<any, any, FormatContextOptions<T>>): any | Promise<any>;

  override async executeContext(
    context: TaskContext<any, any, FormatContextOptions<T>>
  ): Promise<TaskContext<any, any, FormatContextOptions<T>>>
  {
    context.payload = await this.format(context.payload, context);
    return context;
  }
}
