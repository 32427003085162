import { Injectable } from "@angular/core";
import { Serializable } from "../js-proto";
import { CacheGroupIndex, GetCacheOptions, SetCacheOptions } from "./interface";
import { ApiConfig } from "./api-config.service";

@Injectable()
export class Cache {
  private static getGroupIndex(groupIndex: CacheGroupIndex): string {
    return typeof groupIndex === "string" ? groupIndex : groupIndex.name;
  }
  private cache: Record<string, Record<string, {
    time: number, data: Serializable | Promise<Serializable>
  }>> = {};

  constructor(
    private apiConfig: ApiConfig
  ) {}

  get(
    groupIndex:    CacheGroupIndex,
    index:         string,
    { saveError }: GetCacheOptions = {}
  ): Serializable | Promise<Serializable | null> | null
  {
    const group = this.cache[Cache.getGroupIndex(groupIndex)];

    if (!group) return null;

    const cache = group[index];
    if (!cache || (Date.now() >= cache.time)) {
      return null;
    }

    if (cache.data instanceof Promise) {
      return cache.data
        .then(data => {
          cache.data = data;
          return data;
        })
        .catch(e => {
          if (!saveError) this.clear(groupIndex, index);
          throw e;
        });
    }

    return cache.data || null;
  }

  set(
    groupIndex: CacheGroupIndex,
    index:      string,
    data:       Serializable | Promise<Serializable>,
    { time = this.apiConfig.cacheDuration, saveError = false }: SetCacheOptions = {}
  ): Serializable | Promise<Serializable> {
    groupIndex = Cache.getGroupIndex(groupIndex);

    if (!this.cache[groupIndex]) this.cache[groupIndex] = {};

    this.cache[groupIndex][index] = { time: time + Date.now(), data: data };
    if (data instanceof Error) throw data;
    if (data instanceof Promise) data.catch(() => {
      if (!saveError) this.clear(groupIndex, index);
    });
    return data;
  }

  /**
   * Remove all data
   */
  clear(): void;
  /**
   * Remove all class data from cache
   * @param groupIndex class group to clear
   */
  clear(groupIndex: CacheGroupIndex): void;
  /**
   * Remove data from cache
   * @param groupIndex class group to clear
   * @param index index to clear
   */
  clear(groupIndex: CacheGroupIndex, index: string): void;

  clear(groupIndex?: CacheGroupIndex, index?: string): void {
    if (groupIndex) {
      groupIndex = Cache.getGroupIndex(groupIndex);

      if (index) {
        delete this.cache[groupIndex]?.[index];
        return;
      }
      delete this.cache[groupIndex];
      return;
    }
    this.cache = {};
  }
}
