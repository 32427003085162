import { Injectable } from "@angular/core";
import { Bulk } from "src/@prisma/js-proto";
import { NgDefaultTask } from "./default.task";

@Injectable()
export class BulkImportTask extends NgDefaultTask {
  static STAGE = "bulk-import";
  MAP = { [BulkImportTask.STAGE]: this };

  constructor() {
    super(context => {
      if (!context.options?.import) return;

      context.changeOutput(async resp => {
        if (context.payload instanceof Bulk) {
          await context.payload.import(resp, { clear: true });
          return context.payload.toArray();
        } else return resp;
      });
    });
  }
}
