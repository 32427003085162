/**
 * Stores output cache from given input value
 */
export class CacheManager {
  private cacheMap = new Map();

  /**
   * Get the cache associated to input value
   * @param src input to get the associated cache
   * @returns cache stored or undefined
   */
  public get<R, T>(src: T): R | undefined {
    return this.cacheMap.get(src);
  }

  /**
   * Sets the output cache for input value
   * @param src input value
   * @param value output to store
   * @returns void
   */
  public set<T, R>(src: T, value: R): void {
    if (!this.cacheMap.has(src)) {
      this.cacheMap.set(src, value);
    }
  }

  /**
   * Clear the cache for given input value
   * @param src input value
   * @returns true if cache was cleared
   * 
   * @deprecated use remove instead
   */
  public clear<T>(src: T): boolean
  /**
   * Clear the cache
   */
  public clear(): void
  /**
   * Implementation method
   */
  public clear<T>(src?: T): boolean | void {
    if (src) {
      return this.remove(src);
    } else {
      this.cacheMap.clear();
    }
  }

  /**
   * Remove the cache for given input value
   * @param src input value
   * @returns true if cache was removed
   */
  public remove<T>(src: T): boolean {
    return this.cacheMap.delete(src);
  }

  /**
   * Clear all cache
   * @deprecated use clear instead
   */
  public clearAll(): void {
    this.cacheMap.clear();
  }

  /**
   * Get the current cache for given input or execute callback to generate new value
   * @param src input value
   * @param callback function to generate the next stored cache for the input value
   * @returns output cache associated to input value
   */
  public getCacheOrExecute<R, T>(src: T, callback: (value: T) => R): R {
    const cache = this.get<R, T>(src);
    if (cache) return cache;
    const value = callback(src);
    this.set(src, value);
    return value;
  }
}
