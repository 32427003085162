import { PermissionManager } from "../js-permission-manager";

export const permissions = new PermissionManager({
  ADMIN:      { code: "admin", description: /*@i18n*/("Admin") },
  OPERATOR:   { code: "operator", description: /*@i18n*/("Operator") }
});

permissions.initDependencies({
  ADMIN: [ ...permissions.keys() ]
});

export type Permission = ReturnType<typeof permissions.keys>[number]
