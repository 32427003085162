import { Ngi18nTextNode, NgI18nArgs, TextNodeWalker, NgI18nConfiguration } from "./interface";
import { NgI18n } from "./service/ng-i18n.service";

export class NgI18nTranslator {
  static NG_I18N_ENABLED = "ngi18n-enabled";
  static NG_I18N_IGNORED = "ngi18n-ignored";
  static NG_I18N_IGNORE = [".notranslate"];

  private hasViewInit = false;
  private listenerIdx: number;

  private args: string[] = [];
  private translateArgs = false;

  private childrenObserver = new MutationObserver((mutations: MutationRecord[]) => {
    for (const mutation of mutations) {
      mutation.addedNodes.forEach(e => this.forEachNode(text => {
        if (!text.ngi18n) this.initNode(text);
      }, e));

      mutation.removedNodes.forEach(this.destroyNodes.bind(this));
    }
  });

  set setArgs(args: NgI18nArgs | undefined) {
    if (!Array.isArray(args)) args = args !== void 0  ? [args] : [];

    this.args = args.map(e => e.toString());

    this.resetNodeValue();
  }

  /**
   * Set to true or just place the attribute to ignore this element and children (Default: `false`).
   */
  set ignore(value: boolean | "true" | "false" | "" | undefined) {
    if (value === true || value === "true" || value === "")
      this.element.setAttribute(NgI18nTranslator.NG_I18N_IGNORED, "");
    else this.element.removeAttribute(NgI18nTranslator.NG_I18N_IGNORED);

    this.resetNodeValue();
  }

  /**
   * Set to true or just place the attribute to translate the arguments (Default: `false`).
   */
  set setTranslateArgs(value: boolean | string | undefined) {
    this.translateArgs = value !== false && value !== "false";

    this.resetNodeValue();
  }

  /**
   * Set function to ignore child; return true to ignore and false to allow.
   * By default will ignore all `mat-icon`'s nodes
   */
  set ignoreChild(value: typeof this.ignoreChildFn) {
    this.ignoreChildFn = value;

    this.resetNodeValue();
  }

  constructor(
    private element: HTMLElement,
    private ngi18n: NgI18n,
    config?: NgI18nConfiguration<string>
  ) {
    this.listenerIdx = ngi18n.setOnSwitch(() => this.resetNodeValue());
    if (config?.ignoreChild !== void 0) this.ignoreChild = config.ignoreChild;
    if (config?.translateArgs !== void 0) this.setTranslateArgs = config.translateArgs;
  }

  private ignoreChildFn: ((node: Node) => boolean) | null = (node) => {
    return node instanceof HTMLElement && (
      node.classList.contains(NgI18nTranslator.NG_I18N_IGNORED) ||
      NgI18nTranslator.NG_I18N_IGNORE.some(e => node.matches(e))
    );
  };

  init(): this {
    this.element.setAttribute(NgI18nTranslator.NG_I18N_ENABLED, "");

    this.hasViewInit = true;
    this.forEachNode(this.initNode.bind(this));

    this.childrenObserver.observe(this.element, {
      childList: true,
      subtree: true
    });

    return this;
  }

  destroy(): void {
    this.ngi18n.removeSwitchListener(this.listenerIdx);
    this.destroyNodes();
    this.childrenObserver.disconnect();
  }

  private initNode(textNode: Ngi18nTextNode): void {
    textNode.ngi18n = {
      originalValue: textNode.ngi18n?.originalValue ?? textNode.nodeValue,
      observer:      this.initObserver(textNode),
      provider:      this
    };
    this.change(textNode, false);
  }

  private initObserver(node: Ngi18nTextNode, textObserver?: MutationObserver): MutationObserver {
    return (
      textObserver ??= new MutationObserver(
        mutations => this.change(mutations[mutations.length - 1].target as Ngi18nTextNode)
      )
    ).also(it => {
      it.disconnect();
      it.observe(node, {
        characterData: true
      });
    });
  }

  private change(textNode: Ngi18nTextNode, replace = true): void {
    const ngi18nNode = textNode.ngi18n;

    if (!ngi18nNode || !this.hasViewInit) return;

    if (replace) ngi18nNode.originalValue = textNode.nodeValue;

    let nodeValue: string;

    if (!this.translateArgs) {
      nodeValue = NgI18n.get(ngi18nNode.originalValue || "", ...this.args);
    } else {
      nodeValue = NgI18n.get(ngi18nNode.originalValue || "", ...this.args.map(e => NgI18n.get(e)));
    }

    ngi18nNode.observer.disconnect();
    textNode.nodeValue = nodeValue;
    this.initObserver(textNode, ngi18nNode.observer);
  }

  private forEachNode(cb: (node: Ngi18nTextNode) => void, node?: Node, ignoreSelf?: boolean): void {
    const textNodes = this.getTextNodes(node, ignoreSelf);
    while (textNodes?.nextNode()) cb(textNodes.currentNode);
  }

  /**
   * Return the list of all `Text` node in element
   */
  private getTextNodes(node: Node = this.element, ignoreSelf = false): TextNodeWalker | null {
    let parent: Node | null = node;

    if (node !== this.element) {
      do {
        if (this.nodeReject(parent)) return null;
      } while ((parent = parent.parentNode) && parent !== this.element);

      if (!parent) return null;
    }

    if (!ignoreSelf && this.element.hasAttribute(NgI18nTranslator.NG_I18N_IGNORED))
      return null;

    return document.createTreeWalker(
      node,
      NodeFilter.SHOW_ALL,
      {
        acceptNode: (n) => {
          if (this.nodeReject(n)) {
            return NodeFilter.FILTER_REJECT;
          }
          if (n.nodeType === Node.TEXT_NODE) return NodeFilter.FILTER_ACCEPT;
          return NodeFilter.FILTER_SKIP;
        }
      }
    ) as TextNodeWalker;
  }

  private nodeReject(node: Node): boolean {
    return [ "STYLE", "SCRIPT", "TITLE" ].includes(node.nodeName) ||
      this.ignoreChildFn?.(node) ||
      (node instanceof HTMLElement && (
        node.hasAttribute(NgI18nTranslator.NG_I18N_IGNORED) ||
        node.hasAttribute(NgI18nTranslator.NG_I18N_ENABLED)
      ));
  }

  private resetNodeValue(node?: Node, ignoreSelf = true): void {
    this.forEachNode(n => this.change(n, false), node, ignoreSelf);
  }

  private destroyNodes(node?: Node): void {
    this.forEachNode(textNode => {
      textNode.ngi18n?.observer.disconnect();
      textNode.nodeValue = textNode.ngi18n?.originalValue ?? textNode.nodeValue;
      textNode.ngi18n = void 0;
    }, node);
  }
}
