import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, Subscription } from "rxjs";
import { __ } from "src/@prisma/ng-i18n";
import { fadeInOutAnimation } from "src/utils/fade-in-out-animation";
import { InactivityCounter } from "src/utils/inactivity-counter";
import { FooterMenuItem } from "src/utils/interfaces";
import { UserService } from "../services/user.service";
import { DefaultHeaderTitle } from "../utils/default-header/default-header.component";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  animations: [
    fadeInOutAnimation([
      {
        query: "default-header:leave",
        style: {
          position: "absolute",
          width: "100%",
          height: "100%"
        }
      }, {
        query: ".page-body :leave",
        style: {
          width: "calc(100% - clamp(16px, 2vmin, 40px) * 2)",
          height: "calc(100% - clamp(16px, 2vmin, 40px) * 2)"
        }
      }
    ])
  ]
})
export class PagesComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscription = new Subscription();

  @ViewChild("pageContent")
  private pageContent!: ElementRef<HTMLElement>;

  @ViewChild("content")
  private content!: ElementRef<HTMLDivElement>;

  footerMenus: (FooterMenuItem & { title: DefaultHeaderTitle })[] = [
    {
      label: __("Calls"),
      icon: "email",
      path: "/pages/messages",
      title: {
        line1: /*@i18n*/("Call System Administrative Panel"),
        line2: /*@i18n*/("Messages"),
        line3: /*@i18n*/("Messages and confirmations (MESSAGES)")
      }
    },
    {
      label: __("Reports"),
      icon: "description",
      path: "/pages/reports",
      title: {
        line1: /*@i18n*/("Call System Administrative Panel"),
        line2: /*@i18n*/("Reports and Exports"),
        line3: /*@i18n*/("Diverse reports exports (REPORTS)")
      }
    },
    {
      label: __("Groups"),
      icon: "group",
      path: "/pages/group",
      title: {
        line1: /*@i18n*/("Call System Administrative Panel"),
        line2: /*@i18n*/("User Group"),
        line3: /*@i18n*/("Register, Edit, List and Delete (GROUPS)")
      }
    },
    {
      label: __("Users"),
      icon: "person",
      path: "/pages/users",
      title: {
        line1: /*@i18n*/("Call System Administrative Panel"),
        line2: /*@i18n*/("Users and Permissions"),
        line3: /*@i18n*/("Register, Edit, List and Delete (USERS)")
      }
    }
  ];

  showLogo = false;
  logo?: string;

  selected?: (FooterMenuItem & { title: DefaultHeaderTitle });

  constructor(
    private userService:      UserService,
    public  router:           Router,
  ) {
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
      const navigation = e as NavigationEnd;
      const url = navigation.url;
      const menu = this.footerMenus
        .find(menu => menu.path === url || menu?.path && url.startsWith(menu.path || ""));

      this.selected = menu || this.footerMenus[0];
    }).also(it => this.subscription.add(it));
  }

  private removeMenus(paths: string[]): void {
    this.footerMenus = this.footerMenus.filter(e => !e.path || !paths.includes(e.path));
  }

  async ngOnInit(): Promise<void> {
    const [ operator, admin ] = await Promise.all([
      this.userService.canActivate("OPERATOR"),
      this.userService.canActivate("ADMIN")
    ]);
    if (!operator) this.removeMenus([ "/pages/messages" ]);
    if (!admin) this.removeMenus([ "/pages/reports", "/pages/group", "/pages/users" ]);
  }

  ngAfterViewInit(): void {
    const counter = new InactivityCounter(this.content.nativeElement);

    counter.wait(25000).subscribe(async () => {
      await this.userService.logout();
    }).also(it => this.subscription.add(it));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
