import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { MessageService } from "src/app/services/message.service";
import { ConfirmDialogComponent } from "src/app/utils/confirm-dialog/confirm-dialog.component";
import { DefaultDialogComponent } from "src/app/utils/default-dialog/default-dialog.component";
import { ParamKeys } from "src/enums/param-keys.enum";
import { Message } from "src/models/message.model";
import { ConfirmDialogData, DefaultDialogOptions } from "src/utils/interfaces";
import { formatDateTime } from "src/utils/util";

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"]
})
export class DetailsComponent {

  ParamKeys = ParamKeys;
  details = false;
  disabled = !!this.message?.fulfilledAt;
  canDelete = true;

  get message(): Message | undefined {
    return this.options?.data;
  }

  constructor(
    private dialogRef:  MatDialogRef<DetailsComponent>,
    private dialog:     MatDialog,
    private service:    MessageService,
    private feed:       MatFeed,

    @Inject(MAT_DIALOG_DATA)
    private options?:   DefaultDialogOptions<Message>
  ) {}

  getCreatedAt(message?: Message): string {
    return message?.createdAt ? formatDateTime(message.createdAt) : "-";
  }

  getReceivedAt(message?: Message): string {
    return message?.receivedAt ? formatDateTime(message.receivedAt) : "-";
  }

  getConfirmedAt(message?: Message): string {
    return message?.confirmedAt ? formatDateTime(message.confirmedAt) : "-";
  }

  close(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    const dialog = this.dialog.open(DefaultDialogComponent, {
      data: <DefaultDialogOptions>{
        title: /*@i18n*/("Confirm supply"),
        icon: "help_outline",
        component: ConfirmDialogComponent,
        data: <ConfirmDialogData>{
          text: /*@i18n*/("Do you really want to confirm the supply?"),
          btnText: {
            yes:  /*@i18n*/("Confirm"),
            no:   /*@i18n*/("Cancel")
          },
          btnColor: {
            yes: "accent"
          },
          confirm: async () => {
            try {
              if (!this.message?.id) return;
              await this.service.confirm(this.message.id);
              this.feed.success/*@i18n*/("Supply successfully confirmed");
              this.disabled = true;
              dialog.close();
              this.dialogRef.close();
            } catch (e) {
              this.feed.fromError(e);
            }
          },
        }
      }
    });
  }

  deleteDialog(): void {
    this.service.deleteDialog(this.message || [], () => {
      this.dialogRef.close();
    });
  }
}
