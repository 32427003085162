import { Bulk } from "../core";
import { FormatContextOptions, FormatOptions } from "../interfaces";
import { RunnerPool, TaskContext } from "../runner";
import { FormatterRunner } from "./formatter";

export class FormatterPool<T extends object>
  extends RunnerPool<string, any, any, FormatContextOptions,
  TaskContext<any, FormatContextOptions>, FormatterRunner<T>>
{
  async formatDefault<Input extends Bulk<T> | T | T[] = Bulk<T> | T | T[]>(
    bulkOrModel: Input,
    optsOrStages?: FormatOptions<Extract<keyof T, string>> | Extract<keyof T, string>[]
  ): Promise<Input> {
    return this.getDefault()?.format(bulkOrModel, optsOrStages) || bulkOrModel;
  }

  async format<Input extends Bulk<T> | T | T[] = Bulk<T> | T | T[]>(
    name: string | string[],
    bulkOrModel: Input,
    optsOrStages?: FormatOptions<Extract<keyof T, string>> | Extract<keyof T, string>[]
  ): Promise<Input> {
    if (typeof name === "string")
      return this.get(name)?.format(bulkOrModel, optsOrStages) || bulkOrModel;

    for (let i = 0; i < name.length; i++)
      await this.get(name[i])?.format(bulkOrModel, optsOrStages) || bulkOrModel;

    return bulkOrModel;
  }
}
