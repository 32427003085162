import { MetadataManager } from "../core";
import { FormatChildTask, FormatTaskTemplate, FormatterPool, FormatterRunner } from "../formatter";
import { ModelMetadata } from "../metadata";

function decorate(
  target: Object,
  key: string | symbol,
  name: string,
  formats: FormatTaskTemplate | FormatTaskTemplate[]): void
{
  if (typeof key !== "string") return;

  const metadata  = MetadataManager.getOrSet(target, ModelMetadata, () => new ModelMetadata());
  const formatter = metadata.formatter;

  const runner = (formatter.get(name) || new FormatterRunner()).append(key, formats);
  formatter.add(name, runner);
}

export function FormatMany(
  names: string[], formats: FormatTaskTemplate | FormatTaskTemplate[]): PropertyDecorator
{
  return (target, key): void => {
    for (let i = 0; i < names.length; i++)
      decorate(target, key, names[i], formats);
  };
}

export function Format(
  name: string, formats: FormatTaskTemplate | FormatTaskTemplate[]): PropertyDecorator
export function Format(formats: FormatTaskTemplate | FormatTaskTemplate[]): PropertyDecorator
export function Format(
  formatOrName: FormatTaskTemplate | FormatTaskTemplate[] | string,
  formats?: FormatTaskTemplate | FormatTaskTemplate[]): PropertyDecorator
{
  const name = typeof formatOrName === "string" ? formatOrName : FormatterPool.DEFAULT;
  formats    = typeof formatOrName !== "string" ? formatOrName : formats;

  return (target, key): void => decorate(target, key, name, formats || []);
}

/**
 * Add the `FormatChildTask` to a specified formatter runner.
 * @param name Name of the runner to add the format. Default `"child"`
 */
export function FormatChild(name?: string | string[]): PropertyDecorator {
  const names = Array.isArray(name) ? name : [ name || "child" ];

  return (target, key) => {
    const format = new FormatChildTask().configure();

    for (let i = 0; i < names.length; i++)
      decorate(target, key, names[i], format);
  };
}
