import { Injectable } from "@angular/core";
import { Bulk } from "src/@prisma/js-proto";
import { RunnerPool } from "src/@prisma/js-proto/runner/pool";
import { NgDefaultTask } from "./default.task";

@Injectable()
export class BulkValidateTask extends NgDefaultTask {
  static STAGE = "bulk-validate";
  MAP = { [BulkValidateTask.STAGE]: this };

  constructor() {
    super(async context => {
      const options = context.requireOptions();
      const payload = context.payload;

      if (!(payload instanceof Bulk)) return;

      const names = [ RunnerPool.DEFAULT ];
      if (options.event !== void 0 && options.event !== RunnerPool.DEFAULT) names.push(options.event);
      await payload.getValidator().assert(names, payload, options.validationOptions);
    });
  }
}
