import { Field, Format, format, HttpPath, pattern, Primary, Validate } from "src/@prisma/js-proto";
import { GroupUserModel } from "./group-user.model";
import { UserModel } from "./user.model";
import { AuthorField, DateField, DateFieldValidation } from "../decorators";

@HttpPath("group")
export class GroupModel {
  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid group id"), "GRP-0x001" ]) ])
  @Validate("update", [ pattern("required", [ /*@i18n*/("Group id not informed"), "GRP-U-0x001" ]) ])
  @Primary()
  public id?: string;

  @Validate([
    pattern("min", [ /*@i18n*/("Group name too short"), "GRP-0x002" ], 1),
    pattern("max", [ /*@i18n*/("Group name too long"), "GRP-0x003" ], 60)
  ])
  @Validate("create", pattern("required", [ /*@i18n*/("Group name not informed"), "GRP-C-0x001" ]))
  @Format([ format("trim"), format("lowercase") ])
  @Field()
  public name?: string;

  @Validate([
    pattern("min", [ /*@i18n*/("Group observation too short"), "GRP-0x005" ], 1),
    pattern("max", [ /*@i18n*/("Group observation too long"), "GRP-0x006" ], 200)
  ])
  @Format([
    format("trim"),
    format("nullify")
  ])
  @Field({ type: "string", nullable: true })
  public obs?: string | null;

  @Field()
  public enabled?: boolean;

  @Field({ type: () => GroupUserModel, array: true })
  public users?: GroupUserModel[];

  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid group created_by id"), "GRP-0x007" ]) ])
  @Field()
  public createdBy?: string;

  @DateFieldValidation({ code: "GRP-0x008" })
  @DateField()
  public createdAt?: Date;

  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid group updated_by id"), "GRP-0x009" ]) ])
  @Field()
  public updatedBy?: string;

  @DateFieldValidation({ code: "GRP-0x00A" })
  @DateField()
  public updatedAt?: Date;

  @AuthorField()
  public createdByUser?: UserModel;

  @AuthorField()
  public updatedByUser?: UserModel;
}
