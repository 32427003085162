import { Field, Format, HttpPath, Primary, Validate, format, pattern } from "src/@prisma/js-proto";
import { DateField } from "../decorators";

@HttpPath("device")
export class DeviceModel {
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid UUID"), "DVC-0x001" ]))
  @Primary()
  public id?: string;

  @Validate("create", [
    pattern("required", [ /*@i18n*/("Device serial not informed"), "DVC-C-0x001" ])
  ])
  @Validate([
    pattern("min", [ /*@i18n*/("Device serial too short"), "DVC-0x002" ], 5),
    pattern("max", [ /*@i18n*/("Device serial too long"), "DVC-0x003" ], 30)
  ])
  @Format([
    format("trim"),
    format("lowercase")
  ])
  @Field()
  public serial?: string;

  @Validate([
    pattern("min", [ /*@i18n*/("Device name too short"), "DVC-0x004" ], 5),
    pattern("max", [ /*@i18n*/("Device name too long"), "DVC-0x005" ], 60)
  ])
  @Format([
    format("trim"),
    format("lowercase")
  ])
  @Field()
  public name?: string;

  @DateField()
  public createdAt?: Date;
}
