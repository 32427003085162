import { Runner } from "./runner";
import { Task } from "./task";
import { TaskContext } from "./task-context";

export class TaskRunner<
  T = any,
  O = any,
  TOptions extends object = {},
  Stage extends string = string,
  Context extends TaskContext<T, O, TOptions> = TaskContext<T, O, TOptions>>
  extends Task<T, O, TOptions, Context>
{
  constructor(public readonly runner: Runner<T, O, TOptions, Stage, Context>) {
    super();
  }

  executeContext(context: Context): Promise<Context> {
    return this.runner.executeContext(context);
  }
}
