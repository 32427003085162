<div [formGroup]="form" class="container">
  <div class="row" *ngIf="templates && templates.length !== 1">
    <div class="col-md col-xs-12">
      <mat-form-field>
        <mat-label> <!--@i18n-->Message template: *<!----> </mat-label>
        <mat-select formControlName="templateId">
          <mat-option *ngFor="let template of templates" [value]="template.id">
            <span>{{ template.title }} ({{ template.id }})</span>
          </mat-option>
        </mat-select>
        <mat-error>{{ form.getError("templateId") }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md col-xs-12">
      <mat-form-field>
        <mat-label> <!--@i18n-->Bin code: *<!----> </mat-label>
        <input matInput formControlName="itemCode" autocomplete="off"
        [mask]="form.getMask('itemCode')" (focus)="trigger.openPanel()" (input)="trigger.openPanel()" />
        <input hidden formControlName="itemCode" [matAutocomplete]="autocompleteItems"
        #trigger="matAutocompleteTrigger" />
        <button mat-icon-button matSuffix *ngIf="hasCode()" (click)="clear('itemCode')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error>{{ form.getError("itemCode") }}</mat-error>
      </mat-form-field>
      <mat-autocomplete #autocompleteItems>
        <mat-option *ngFor="let item of filteredLastItems" [value]="item">
          <div class="autocomplete-option">
            <span>{{ item }}</span>
            <button mat-icon-button color="warn" (click)="removeItem(item, $event)">
              <mat-icon class="material-icons-outlined">close</mat-icon>
            </button>
          </div>
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-md col-xs-12">
      <mat-form-field>
        <mat-label> <!--@i18n-->Amount: *<!----> </mat-label>
        <input matInput formControlName="amount" [mask]="form.getMask('amount')"
        (focus)="trigger.closePanel()" />
        <mat-error>{{ form.getError("amount") }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md col-xs-12">
      <mat-form-field>
        <mat-label> <!--@i18n-->Work Station: *<!----> </mat-label>
        <input matInput formControlName="station" [mask]="form.getMask('station')" />
        <mat-error>{{ form.getError("station") }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md col-xs-12">
      <mat-form-field>
        <mat-label> <!--@i18n-->Target: *<!----> </mat-label>
        <mat-select formControlName="target" class="filter">
          <div [ngStyle]="{ position: 'sticky', top: '0', padding: '10px', height: '40px', backgroundColor: '#424242', zIndex: '1' }">
            <mat-form-field [ngStyle]="{ margin: '0' }">
              <mat-label> <!--@i18n-->Filter:<!----> </mat-label>
              <input matInput type="text" [formControl]="filter">
            </mat-form-field>
          </div>
          <mat-optgroup *ngFor="let group of targets" [label]="group.name">
            <mat-option *ngFor="let target of group.items" [value]="{ key: group.key, value: target.value }"
            [ngClass]="{ 'd-none': !showTarget(target) }">
              <span>{{ target.name }}</span>
            </mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-error>{{ form.getError("target") }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md col-xs-12">
      <mat-form-field>
        <mat-label> <!--@i18n-->Supply Priority: *<!----> </mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let type of types" [value]="type.key">
            <span>{{ type.name }}</span>
          </mat-option>
        </mat-select>
        <mat-error>{{ form.getError("type") }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button mat-raised-button color="success" (click)="save()" class="btn-request">
        <!--@i18n-->Request<!---->
      </button>
    </div>
  </div>
</div>
