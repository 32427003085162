import { Injectable } from "@angular/core";
import { BulkFormatTask, BulkValidateTask, HttpTask, ParseParamsTask, PreRequestTask, GetCacheTask, RequestTask, ClearCacheTask, EmitEventTask } from "../tasks";
import { NgEventRunner } from "./event.runner";

@Injectable()
export class NgEventDeleteRunner extends NgEventRunner {
  override readonly RUNNER = "delete";

  protected override onInit(): void {
    this.reorder([
      BulkFormatTask.STAGE,
      BulkValidateTask.STAGE,
      HttpTask.STAGE,
      ParseParamsTask.STAGE,
      PreRequestTask.STAGE,
      GetCacheTask.STAGE,
      RequestTask.STAGE,
      ClearCacheTask.STAGE,
      EmitEventTask.STAGE
    ]);
  }

}
