export const AppBreakpoints = {
  XSmall:  "(max-width: 575.98px)",
  Small:   "(min-width: 576px) and (max-width: 767.98px)",
  Medium:  "(min-width: 768px) and (max-width: 991.98px)",
  Large:   "(min-width: 992px) and (max-width: 1199.98px)",
  XLarge:  "(min-width: 1200px) and (max-width: 1399.98px)",
  XXLarge: "(min-width: 1400px) and (max-width: 1799.98px)",
  EXLarge: "(min-width: 1800px)",

  SmallOrLess:  "(max-width: 767.98px)",
  MediumOrLess: "(max-width: 991.98px)",
  LargeOrLess:  "(max-width: 1199.98px)",
  XLargeOrLess: "(max-width: 1399.98px)",

  SmallOrMore:   "(min-width: 576px)",
  MediumOrMore:  "(min-width: 768px)",
  LargeOrMore:   "(min-width: 992px)",
  XLargeOrMore:  "(min-width: 1200px)",
  XXLargeOrMore: "(min-width: 1400px)",

  All: [
    "(max-width: 575.98px)",
    "(min-width: 576px) and (max-width: 767.98px)",
    "(min-width: 768px) and (max-width: 991.98px)",
    "(min-width: 992px) and (max-width: 1199.98px)",
    "(min-width: 1200px) and (max-width: 1399.98px)",
    "(min-width: 1400px) and (max-width: 1799.98px)",
    "(min-width: 1800px)",
  ]
};
