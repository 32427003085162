import { interval, Observable, Subject } from "rxjs";

export class InactivityCounter {
  readonly events$ = new Subject<string>();

  constructor(
    private element:  HTMLElement
  ) {}

  wait(seconds: number, multiple = false): Observable<void> {
    let count = seconds *= 10;

    return new Observable<void>(subject => {
      const events = [
        "mousedown", "mousemove", "keypress", "scroll", "touchstart", "touchmove", "touchend"
      ];
      const callback = (event?: Event): void => {
        if (event?.type) {
          this.events$.next(event.type);
        }
        count = seconds;
      };

      events.forEach(e => {
        this.element.addEventListener(e, callback);
      });
      const subscription = interval(100).subscribe(() =>
      {
        if (count) {
          count--;
        }
        if (count <= 0) {
          callback();
          subject.next();

          if (!multiple) {
            subject.complete();
          }
        }
      });

      return () => {
        subscription.unsubscribe();
        events.forEach(e => {
          this.element.removeEventListener(e, callback);
        });
      };
    });
  }
}
