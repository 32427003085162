import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AppBreakpoints } from "src/utils/breakpoints";

export interface DefaultHeaderTitle {
  line1: string
  line2: string
  line3: string
}
@Component({
  selector: "default-header",
  templateUrl: "./default-header.component.html",
  styleUrls: ["./default-header.component.scss"]
})
export class DefaultHeaderComponent implements OnDestroy {
  @Input() title: DefaultHeaderTitle = {
    line1: "",
    line2: "",
    line3: ""
  };
  _img?: string;

  get img(): string {
    return this._img || "assets/images/alghorit-logo.png";
  }
  @Input() set img(value: string | undefined) {
    this._img = value;
  }

  showLogo = true;
  subscription = new Subscription();

  constructor(
    private breakpoint: BreakpointObserver
  ) {
    this.subscription.add(
      this.breakpoint.observe(AppBreakpoints.XSmall).subscribe(({ breakpoints }) => {
        this.showLogo = !breakpoints[AppBreakpoints.XSmall];
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
