import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DefaultDialogComponent } from "./default-dialog.component";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";


@NgModule({
  declarations: [
    DefaultDialogComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class DefaultDialogModule { }
