<div class="item">
  <div>
    <mat-icon class="material-icons-outlined">qr_code</mat-icon>
    <span> <!--@i18n-->Bin Code:<!----> </span>
  </div>
  <div>{{ message?.templateParams?.[ParamKeys.CODE] || "-" }}</div>
</div>

<div class="item">
  <div>
    <mat-icon class="material-icons-outlined">schedule</mat-icon>
    <span> <!--@i18n-->Request Date/Time:<!----> </span>
  </div>
  <div>{{ getCreatedAt(message) }}</div>
</div>

<div class="item">
  <div>
    <mat-icon class="material-icons-outlined">person</mat-icon>
    <span> <!--@i18n-->Requester:<!----> </span>
  </div>
  <div>{{ message?.createdByUser?.name || message?.createdBy || "-" }}</div>
</div>

<div class="item">
  <div>
    <mat-icon class="material-icons-outlined">inventory</mat-icon>
    <span> <!--@i18n-->Amount:<!----> </span>
  </div>
  <div class="case-sensitive">{{ message?.templateParams?.[ParamKeys.AMOUNT] || "-" }}</div>
</div>

<div class="item">
  <div>
    <mat-icon class="material-icons-outlined">location_on</mat-icon>
    <span> <!--@i18n-->Station:<!----> </span>
  </div>
  <div>{{ message?.templateParams?.[ParamKeys.STATION] || "-" }}</div>
</div>

<div class="item" *ngIf="message?.group?.name">
  <div>
    <mat-icon class="material-icons-outlined">groups</mat-icon>
    <span> <!--@i18n-->Target group:<!----> </span>
  </div>
  <div>{{ message?.group?.name }}</div>
</div>

<div class="item" *ngIf="message?.user?.name">
  <div>
    <mat-icon class="material-icons-outlined">person</mat-icon>
    <span> <!--@i18n-->Target user:<!----> </span>
  </div>
  <div>{{ message?.user?.name }}</div>
</div>

<!-- <div class="receive" *ngIf="!details">
  <button mat-stroked-button color="accent" (click)="details = true">
    <mat-icon class="material-icons-outlined">info</mat-icon>
    Show receive details
  </button>
</div> -->

<div class="details" *ngIf="details">
  <div class="created">
    <div>
      <mat-icon class="material-outlined-icons">create</mat-icon>
      <span> <!--@i18n-->Created:<!----> </span>
    </div>
    <div>{{ getCreatedAt(message) }}</div>
  </div>

  <div class="received">
    <div>
      <mat-icon class="material-outlined-icons">mark_as_unread</mat-icon>
      <span> <!--@i18n-->Received:<!----> </span>
    </div>
    <div>{{ getReceivedAt(message) }}</div>
  </div>

  <div class="confirmed">
    <div>
      <mat-icon class="material-outlined-icons">check</mat-icon>
      <span> <!--@i18n-->Confirmed:<!----> </span>
    </div>
    <div>{{ getConfirmedAt(message) }}</div>
  </div>
</div>


<div class="buttons">
  <button mat-raised-button color="warn" (click)="deleteDialog()"><!--@i18n-->Delete<!----></button>
  <button mat-raised-button color="success" (click)="confirm()"><!--@i18n-->Confirm supply<!----></button>
</div>
