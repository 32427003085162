<div mat-dialog-title class="title">
  <h1 *ngIf="title">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <span>{{ title }}</span>
  </h1>
  <button mat-icon-button *ngIf="button" (click)="btnClick()">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="content">
  <ng-template #content></ng-template>
</div>
