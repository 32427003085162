export type FilterValue = string | number | boolean | null | FilterValue[];
export type FilterAllowType = "string" | "number" | "boolean" | "null" | FilterAllowType[] | ((e: any) => boolean)

export type FilterOperator = "$not" | "$bt" | "$lt" | "$lte" | "$gt" | "$gte" | "$like" | "$in" | "$null"

type FilterComponentNot = { "$not": FilterValue | Exclude<FilterComponent, FilterComponentNot> }

export type FilterComponent = FilterValue
| FilterComponentNot
| { "$bt": [ FilterValue, FilterValue ] }
| { [O in Exclude<FilterOperator, "$bt" | "$not">]?: FilterValue  }

export type Filter<Model> = { [P in keyof Model]?: FilterComponent }

export type FilterQuery<Model> = Filter<Model> | Filter<Model>[]

export type FilterAllowOptions<Model> = { [ U in keyof Model ]?: FilterAllowType }

export type FilterTransformOptions<Model> = { [ U in keyof Model ]?: (value: any) => any }

export interface FilterOptions<Model> {
  take?:      string | number
  relations?: string | string[]
  order?:     Partial<Record<Extract<keyof Model, string>, "ASC" | "DESC">>
}

export interface CompiledFilterOptions<T = any> {
  take?:      number,
  relations?: string[]
  order?:     Partial<Record<Extract<keyof T, string>, "ASC" | "DESC">>
}

export interface AllowFilterOptions {
  /**
   * Allow the client to use a custom `take` value. The value specified will be matched to the `maxTakeSize`
   * (if specified). The max size will be ignored if a function is used.
   * @default true
   */
  take?:      boolean | ((limit?: number) => boolean)
  relations?: string[]
  order?:     string[]
}
