import { PartialRecord } from "../js-utils";
import { PermissionInfo } from "./interfaces";

export class PermissionManager<K extends string, E> {
  private permissionKeyMap: Record<string, K>;

  constructor(
    private readonly permissions: Record<K, PermissionInfo<E>>
  ) {
    this.permissionKeyMap = this.initKeyMap();
  }

  list(): PermissionInfo<E>[] {
    const permissions = [] as PermissionInfo<E>[];
    const keys = this.keys();

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const permission = this.permissions[key];

      permissions.push({ ...permission });
    }

    return permissions;
  }

  /**
   * Get PermissionInfo from a permission code. Returns `null` if not found.
   */
  translate(code: string): string | null {
    const keys = this.keys();

    for (let i = 0; i < keys.length; i++) {
      if (this.permissions[keys[i]].code === code) return keys[i];
    }

    return null;
  }

  /**
   * Get the permission code or return null if not found.
   */
  getOrNull(key: K): PermissionInfo<E> | null
  getOrNull(keyOrCode: string): PermissionInfo<E> | null
  getOrNull(keyOrCode: string): PermissionInfo<E> | null {
    const permission =
      this.permissions[this.permissionKeyMap[keyOrCode.toLowerCase()]] ||
      this.permissions[this.permissionKeyMap[this.translate(keyOrCode)?.toLowerCase() || ""]];

    return permission || null;
  }

  /**
   * Get the permission code. Throw a error if not found.
   */
  get(key: K): PermissionInfo<E>
  get(key: string): PermissionInfo<E>
  get(key: string): PermissionInfo<E> {
    const permission = this.getOrNull(key);

    if (!permission) throw new Error(`Permission "${key}" not found`);

    return permission;
  }

  getFromCode(code: string): PermissionInfo<E> | undefined {
    const keys = this.keys();

    for (let i = 0; i < keys.length; i++) {
      if (this.permissions[keys[i]].code === code) {
        return this.permissions[keys[i]];
      }
    }
  }

  /**
   * Get all permission base codes. Will not get any dependencies.
   */
  getAllCodes(): string[] {
    const keys  = this.keys();
    const codes = new Array<string>(keys.length);

    for (let i = 0; i < keys.length; i++)
      codes[i] = this.permissions[keys[i]].code;

    return codes;
  }

  /**
   * Get the permission code and it's dependencies.
   */
  getCodes(keyOrPermission: string | PermissionInfo<any>): string[] {
    const permission = typeof keyOrPermission === "string" ? this.get(keyOrPermission) : keyOrPermission;

    return [ permission.code ].concat(permission.dependencyOf || []);
  }

  /**
   * Get all permission keys.
   */
  keys(): K[] {
    return Object.keys(this.permissions) as K[];
  }

  /**
   * @deprecated Use `verify` instead.
   */
  canActivate(target: (PermissionInfo<any> | K)[], payload: (PermissionInfo<any> | string)[]): boolean {
    if (target.length === 0 || payload.length === 0) return false;

    const filteredPayload =
      payload
        .map(e => typeof e !== "string" ? e : this.getOrNull(e))
        .filter(e => !!e) as PermissionInfo<any>[];

    const payloadCodes = this.toCodeArray(filteredPayload, true);
    const targetCodes  = this.toCodeArray(target);

    for (const code of targetCodes) {
      if (payloadCodes.includes(code)) return true;
    }

    return false;
  }

  /**
   * Check if user has any of the allowed permissions.
   * @param allowedKeys Permission keys or info allowed.
   * @param codes User permissions codes
   */
  verify(allowedKeys: (PermissionInfo<any> | string)[], codes: string[]): boolean {
    for (let i = 0; i < allowedKeys.length; i++) {
      const allowedCodes = this.getCodes(allowedKeys[i]);

      for (let i = 0; i < allowedCodes.length; i++) {
        const includes = codes.includes(allowedCodes[i]);
        if (includes) return true;
      }
    }

    return false;
  }

  /**
   * Convert an array of PermissionInfo or permission keys in a array of codes.
   * @param includeDependencies - Include permissions dependencies in the output array.
   */
  private toCodeArray(permissions: (PermissionInfo<any> | K)[], includeDependencies?: boolean): string[] {
    const codes: string[] = [];

    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i];
      const pInfo = typeof permission === "string" ? this.get(permission) : permission;

      codes.push(pInfo.code);

      if (includeDependencies && pInfo.dependencyOf?.length)
        codes.push(...pInfo.dependencyOf);
    }

    return codes;
  }

  /**
   * Set the prepared index to all its dependencies.
   *
   * @example
   *
   * ```
   * // CREATE needs READ and UPDATE to work properly.
   * const prepared = {
   *   "CREATE": [ "READ", "UPDATE" ]
   * };
   *
   * const output = {
   *   "READ":   { dependencyOf: [ "perm_create" ] },
   *   "UPDATE": { dependencyOf: [ "perm_create" ] }
   * };
   * ```
   */
  initDependencies(prepared: PartialRecord<K, K[]>): this {
    const reverse: PartialRecord<K, K[]> = {};

    for (const key in prepared) {
      const deps = prepared[key];
      if (!deps) continue;

      for (let i = 0; i < deps.length; i++)
        (reverse[deps[i]] || (reverse[deps[i]] = []))?.push(key);
    }

    return this.initDependents(reverse);
  }

  /**
   * Fill `dependencyOf` field using Permission Key.
   *
   * @example
   *
   * ```
   * // CREATE, UPDATE and DELETE need READ to work properly.
   * const prepared = {
   *   "READ": [ "CREATE", "UPDATE", "DELETE" ]
   * };
   *
   * const output = {
   *   "READ": { dependencyOf: [ "perm_create", "perm_update", "perm_delete" ] }
   * };
   * ```
   */
  initDependents(prepared: PartialRecord<K, K[]>): this {
    const keys = this.keys();

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const permissionDeps = prepared[key];

      if (!permissionDeps) continue;

      const dependencies: string[] =
        this.permissions[key].dependencyOf || (this.permissions[key].dependencyOf = []);

      for (let i = 0; i < permissionDeps.length; i++) {
        const depKey = permissionDeps[i];
        const code = this.get(depKey).code;

        if (code !== this.permissions[key].code && !dependencies.includes(code))
          dependencies.push(code);
      }
    }

    return this;
  }

  private getCascadeDependencies(
    startPermission: PermissionInfo<any>,
    permission: PermissionInfo<any> = startPermission,
    ignoreCodes: string[] = []): void
  {
    if (!permission.dependencyOf) return;

    const depCodes = startPermission.dependencyOf || (startPermission.dependencyOf = []);

    for (let i = 0; i < permission.dependencyOf.length; i++) {
      const depCode = permission.dependencyOf[i];

      if (startPermission.code === depCode || ignoreCodes.includes(depCode)) continue;
      ignoreCodes.push(depCode);

      const depKey      = this.translate(depCode); if (!depKey) continue;
      const child       = this.get(depKey);
      const childCodes  = child.dependencyOf || [];

      for (let i = 0; i < childCodes.length; i++) {
        const code = childCodes[i];
        if (startPermission.code !== code && !depCodes.includes(code)) depCodes.push(code);
      }

      this.getCascadeDependencies(startPermission, child, ignoreCodes);
    }
  }

  /**
   * Fill the permission the dependencies with it's children's dependencies.
   */
  cascadeDependencies(): this {
    const keys = this.keys();

    for (let i = 0, key = keys[i]; i < keys.length; key = keys[++i]) {
      this.getCascadeDependencies(this.get(key));
    }

    return this;
  }

  private initKeyMap(): Record<string, K> {
    const map: Record<string, K> = {};

    for (const key of this.keys()) {
      map[key.toLowerCase()] = key;
    }

    return map;
  }
}
