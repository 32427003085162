import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Inject, Input, OnDestroy, Optional } from "@angular/core";
import { NgI18nArgs, NgI18nConfiguration } from "../interface";
import { NgI18nTranslator } from "../ngi18n-translator.class";
import { I18N_CONFIG, NgI18n } from "../service/ng-i18n.service";

@Directive({
  selector: "[ngi18n],[ngi18nIgnore]",
  exportAs: ""
})
export class NgI18nDirective<L extends string = string>
  extends NgI18nTranslator implements AfterViewInit, OnDestroy
{
  @Input("ngi18n")
  override set setArgs(args: NgI18nArgs | undefined) {
    super.setArgs = args;
  }

  /**
   * Set to true or just place the attribute to ignore this element and children (Default: `false`).
   */
  @Input("ngi18nIgnore")
  override set ignore(value: boolean | "true" | "false" | "" | undefined) {
    super.ignore = value;
  }

  /**
   * Set to true or just place the attribute to translate the arguments (Default: `false`).
   */
  @Input("ngi18nTranslateArgs")
  override set setTranslateArgs(value: boolean | string | undefined) {
    super.setTranslateArgs = value;
  }

  /**
   * Set function to ignore child; return true to ignore and false to allow.
   * By default will ignore all `mat-icon`'s nodes
   */
  @Input("ngi18nIgnoreChildFn")
  override set ignoreChild(value: ((node: Node) => boolean) | null) {
    super.ignoreChild = value;
  }

  constructor(
    @Optional() @Inject(I18N_CONFIG) config: NgI18nConfiguration<L> = {},
    private cd: ChangeDetectorRef,
    el: ElementRef<HTMLElement>,
    ngi18n: NgI18n
  ) {
    super(el.nativeElement, ngi18n, config);
  }

  ngAfterViewInit(): void {
    this.init();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy();
  }
}
