import { MessageModel } from "../../agent-common";
import { Field, HttpEvent, HttpPath, pattern, ProtoPartialRecord, Validate } from "../../js-proto";
import { AuthorField, DateField } from "../decorators";
import { GroupModel } from "./group.model";
import { MessageTemplateModel } from "./message-template.model";
import { UserModel } from "./user.model";

@HttpPath("message/registry")
@HttpEvent("confirm", "/confirm", "patch")
export class MessageRegistryModel extends MessageModel {
  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid message user_id"), "MSR-0x001" ]) ])
  @Field()
  public userId?: string;

  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid message group_id"), "MSR-0x002" ]) ])
  @Field()
  public groupId?: string;

  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid message template_id"), "MSR-0x003" ]) ])
  @Field()
  public templateId?: string;

  @Field({ json: true })
  public templateParams?: ProtoPartialRecord<string, string>;

  @DateField()
  public fulfilledAt?: Date;

  @Field({ type: () => MessageTemplateModel })
  public template?: MessageTemplateModel;

  @Field({ type: () => UserModel })
  public user?: UserModel;

  @Field({ type: () => GroupModel })
  public group?: GroupModel;

  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid message created_by id"), "MSR-0x004" ]) ])
  @Field()
  public createdBy?: string;

  @AuthorField()
  public createdByUser?: UserModel;

  @Validate("create", [], { override: true })
  public override deviceSerial?: string | undefined;
}
