import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UsersComponent } from "./users.component";
import { DataTableModule } from "src/app/utils/data-table/data-table.module";
import { UserFormComponent } from "./form/user-form.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { DefaultFormContainerModule } from "src/app/utils/default-form-container/default-form-container.module";
import { MatSelectModule } from "@angular/material/select";
import { UsersRoutingModule } from "./users-routing.module";
import { ConfirmDialogModule } from "src/app/utils/confirm-dialog/confirm-dialog.module";
import { UserListComponent } from "./list/user-list.component";
import { NgxMaskModule } from "ngx-mask";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    UsersComponent,
    UserFormComponent,
    UserListComponent
  ],
  imports: [
    CommonModule,
    DataTableModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    UsersRoutingModule,
    DefaultFormContainerModule,
    ConfirmDialogModule,
    NgxMaskModule
  ]
})
export class UsersModule { }
