import { Injectable } from "@angular/core";
import { IAbout } from "src/@prisma/js-common";
import { NgHttpPool } from "src/@prisma/ng-runner";

@Injectable({
  providedIn: "root"
})
export class AboutService {
  constructor(
    private http: NgHttpPool
  ) {}

  async get(): Promise<IAbout> {
    return this.http.httpGet("/about");
  }
}
