import { Bulk, MetadataManager, Proto } from "../core";
import { ValidationContext, ValidationContextOptions } from "../interfaces";
import { ModelMetadata } from "../metadata";
import { ValidatorPool } from "./pool";
import { ValidationTaskTemplate } from "./task-template";

export class ValidateChildTask extends ValidationTaskTemplate {
  override configure(): this {
    return this;
  }

  constructor() {
    super("Invalid child object");
  }

  /**
   * @param payload Expects: `Bulk` or Model Object.
   */
  protected async validate(payload: any, opts?: ValidationContextOptions): Promise<void> {
    const validator   = MetadataManager.get(payload, ModelMetadata)?.validator;
    const event       = opts?.event;
    const childStages = opts?.childStages;
    const constructor = childStages?.length && Proto.getConstructor(payload);

    let stages: string[] | undefined;

    // check constructor to match with child stages
    if (constructor) {
      for (let i = 0; i < childStages.length; i++) {
        const match   = childStages[i].match;
        const cStages = childStages[i].stages;

        if (
          match === constructor ||
          (Array.isArray(match) && match.includes(constructor))
        ) {
          stages = Array.isArray(cStages) ? cStages : [ cStages ];
          break;
        }
      }
    }

    if (Array.isArray(event)) {
      for (let i = 0; i < event.length; i++) {
        const result = await validator
          ?.get(event[i])?.validate(payload, { event, childStages, stages });

        if (result) throw this.getFieldError(result);
      }
    } else {
      await validator
        ?.get(event || ValidatorPool.DEFAULT)?.validate(payload, { event, childStages, stages }) || null;
    }
  }

  override async executeContext(context: ValidationContext): Promise<ValidationContext> {
    const value = context.payload;

    if (value instanceof Bulk || Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        await this.validate(value instanceof Bulk ? value.at(i) : value[i], context.options);
      }
    } else {
      await this.validate(context.payload, context.options);
    }

    return context;
  }
}
