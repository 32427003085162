import { animate, AnimationTriggerMetadata, group, query, style, transition, trigger } from "@angular/animations";


export function fadeInOutAnimation(
  queries?: { query: string, style?: Record<string, string> }[],
  time = 300): AnimationTriggerMetadata
{
  return trigger("fadeInOut", [
    transition("* <=> *", [
      group(
        (queries || [{ query: ":leave" }])
          .map(e => query(e.query, [
            style(Object.assign({
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "block"
            }, e.style))
          ], { optional: true }))
      ),
      group([
        query(":enter", [
          style({ opacity: 0, }),
          animate(time, style({ opacity: 1 })),
        ], { optional: true }),
        query(":leave", [
          animate(time, style({ opacity: 0 })),
        ], { optional: true })
      ])
    ])
  ]);
}
