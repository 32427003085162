import { I18nConfiguration } from "../js-i18n";
import { NgI18nTranslator } from "./ngi18n-translator.class";

export type NgI18nArgs = string | number | (string | number )[];
export type Ngi18nTextNode = Text & { ngi18n?: NgI18nNode };
export type TextNodeWalker = Omit<TreeWalker, "nextNode"> & {
  nextNode: () => Ngi18nTextNode | null;
  currentNode: Ngi18nTextNode
};

export interface TextObserver {
  observer: MutationObserver
  node: Ngi18nTextNode
}

export interface NgI18nNode {
  originalValue: string | null;
  observer:      MutationObserver;
  provider:      NgI18nTranslator;
}

export interface NgI18nConfiguration<L extends string> extends I18nConfiguration<L> {
  translateArgs?: boolean,
  ignore?:        string[],
  ignoreChild?:   ((node: Node) => boolean) | null,
}
