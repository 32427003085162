import { Injectable } from "@angular/core";
import { HttpMethods } from "src/@prisma/js-proto";
import { RunnerPool } from "src/@prisma/js-proto/runner/pool";
import { NgRunnerOptions } from "../interface";
import { NgHttpPostRunner } from "../runners";
import { NgHttpDeleteRunner } from "../runners/http-delete.runner";
import { NgHttpGetRunner } from "../runners/http-get.runner";
import { NgHttpPatchRunner } from "../runners/http-patch.runner";
import { NgHttpPutRunner } from "../runners/http-put.runner";
import { NgTaskContext } from "../tasks/task.context";

@Injectable()
export class NgHttpPool
  extends RunnerPool<`http-${HttpMethods}`, any, Promise<any>, NgRunnerOptions, NgTaskContext>
{
  constructor(
    protected postRunner:   NgHttpPostRunner,
    protected putRunner:    NgHttpPutRunner,
    protected patchRunner:  NgHttpPatchRunner,
    protected deleteRunner: NgHttpDeleteRunner,
    protected getRunner:    NgHttpGetRunner
  ) {
    super({
      ...postRunner.MAP,
      ...putRunner.MAP,
      ...patchRunner.MAP,
      ...deleteRunner.MAP,
      ...getRunner.MAP
    });

    this.setDefaultContext(NgTaskContext);
  }

  async run<Resp = any, Body = any>(
    method: HttpMethods, path: string, value?: Body, opts: NgRunnerOptions = {}
  ): Promise<Resp>
  {
    opts.http = { method, path };
    return await (await this.execute(`http-${method}`, value, opts)).output;
  }

  async httpPost<Resp = any, Body = any>(
    path: string, value?: Body, options?: NgRunnerOptions): Promise<Resp>
  {
    return await this.run("post", path, value, options);
  }
  async httpPut<Resp = any, Body = any>(
    path: string, value: Body, options?: NgRunnerOptions): Promise<Resp>
  {
    return await this.run("put", path, value, options);
  }
  async httpPatch<Resp = any, Body = any>(
    path: string, value: Body, options?: NgRunnerOptions): Promise<Resp>
  {
    return await this.run("patch", path, value, options);
  }
  async httpDelete<Resp = any>(
    path: string, options?: NgRunnerOptions): Promise<Resp>
  {
    return await this.run("delete", path, void 0, options);
  }
  async httpGet<Resp = any>(
    path: string, options?: NgRunnerOptions): Promise<Resp>
  {
    return await this.run("get", path, void 0, options);
  }
}
