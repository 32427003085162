import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { fadeInOutAnimation } from "src/utils/fade-in-out-animation";

@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.scss"],
  animations: [ fadeInOutAnimation() ]
})
export class GroupComponent {
  constructor(
    public router: Router
  ) {}
}
