import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GroupComponent } from "./group.component";
import { GroupRoutingModule } from "./group-routing.module";
import { GroupListComponent } from "./list/group-list.component";
import { GroupFormComponent } from "./form/group-form.component";
import { DataTableModule } from "src/app/utils/data-table/data-table.module";
import { DefaultFormContainerModule } from "src/app/utils/default-form-container/default-form-container.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgxMaskModule } from "ngx-mask";
import { MatTableModule } from "@angular/material/table";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { NgI18nModule } from "src/@prisma/ng-i18n";
import { MatCardModule } from "@angular/material/card";
import { NgLetModule } from "src/@prisma/ng-utils";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [
    GroupComponent,
    GroupListComponent,
    GroupFormComponent
  ],
  imports: [
    GroupRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    NgLetModule,
    DataTableModule,
    ScrollingModule,
    DefaultFormContainerModule,
    NgI18nModule,
    NgxMaskModule
  ]
})
export class GroupModule { }
