import { HttpContext } from "@angular/common/http";
import { Bulk, ConstructableAny, FormatOptions, HttpConfig, TaskContext, ValidationOptions } from "../js-proto";
import { FilterOptions, FilterQuery } from "./filter-interface";
import { NgTaskContext } from "./tasks/task.context";
import { PartialRecord } from "../js-utils";

export interface RequestOptions {
  headers?: PartialRecord<string, string | string[]>
  context?: HttpContext
  observe?: "body"
  reportProgress?: boolean
  responseType?: "json"
  withCredentials?: boolean
}

export interface RequestResponse {
  statusCode: number
  error:      string
  message:    string
}

export type FullRequestOption = RequestOptions & {
  timeout?:     number
  queryParams?: Record<string, any>
  params?:      Record<string, string> | string[]
};

export type CacheGroupIndex = ConstructableAny | string
export interface GetCacheOptions {
  saveError?: boolean;
}

export interface GetCacheOptions {
  saveError?: boolean;
}

export interface SetCacheOptions extends GetCacheOptions {
  time?: number;
}

export interface NgRunnerOptions<Model = any> extends FullRequestOption {
  event?:             string
  body?:              any
  singleBodyData?:    boolean
  clearCache?:        boolean
  http?:              Required<HttpConfig>
  hasCache?:          boolean
  filter?:            FilterQuery<Model>
  filterOptions?:     FilterOptions<Model>
  validationOptions?: ValidationOptions<Extract<keyof Model, string>>
  formatterOptions?:  FormatOptions<Extract<keyof Model, string>>
  emit?:              boolean
  force?:             boolean
  cache?:             boolean
  cacheError?:        boolean
  cacheTime?:         number
  import?:            boolean
}

export type NgPoolEventFN<T extends object> = (bulk: Bulk<T>, response: any, event: string) => any;

export type NgPoolEventIndex = {
  group: ConstructableAny
  event: string
  fn:    NgPoolEventFN<any>
};

export class NgPoolListeners extends Map<ConstructableAny, Partial<Record<string, NgPoolEventFN<any>[]>>> {}

export interface NgRunnerContext<Model extends object> {
  bulk:     Bulk<Model>,
  options:  NgRunnerOptions
}

export type ContextFn<T> = (context: NgTaskContext<any>) => Promise<T> | T
export type ContextOptFn<T> = (context?: NgTaskContext<any>) => Promise<T> | T
export type ContextFactory<T> = T | ContextFn<T>;
export type ContextOptFactory<T> = T | ContextOptFn<T>;

export type ApiHeader =
  Promise<PartialRecord<string, string | string[]>> | PartialRecord<string, string | string[]>

export interface IApiConfig {
  timeout:       ContextOptFactory<number | undefined>;
  baseUrl:       ContextOptFactory<string>;
  tokenKey:      string;
  cacheDuration: number;
  defaultHeader: ContextFactory<ApiHeader>;
  getToken:      ContextOptFn<string | null | undefined>;
  setToken:      (resp: any, context?: TaskContext<any, any, NgRunnerOptions<any>>) => Promise<void> | void;
  removeToken:   (opts?: any) => Promise<void> | void;
  buildBody:     ContextFn<any>;
}
