import { ConstructableFormatTask } from "../interfaces";
import { FormatEmptyTask } from "./empty";
import { FormatGenericTask } from "./generic";
import { FormatLowercaseTask } from "./lowercase";
import { FormatNullifyTask } from "./nullify";
import { FormatTaskTemplate } from "./task-template";
import { FormatTrimTask } from "./trim";
import { FormatUppercaseTask } from "./uppercase";

const NATIVE_FORMAT_TASKS = {
  lowercase: FormatLowercaseTask,
  uppercase: FormatUppercaseTask,
  trim:      FormatTrimTask,
  nullify:   FormatNullifyTask,
  generic:   FormatGenericTask,
  empty:     FormatEmptyTask
};

export type NativeFormatTask = keyof typeof NATIVE_FORMAT_TASKS

export function format<T extends FormatTaskTemplate>(
  task: ConstructableFormatTask<T>,
  ...args: Parameters<T["configure"]>
): T
export function format<K extends NativeFormatTask>(
  nativeKey: K,
  ...args: Parameters<InstanceType<typeof NATIVE_FORMAT_TASKS[K]>["configure"] >
): InstanceType<typeof NATIVE_FORMAT_TASKS[K]>
export function format(
  taskOrNativeKey: ConstructableFormatTask | NativeFormatTask,
  ...args: any[]
): FormatTaskTemplate {
  const task = typeof taskOrNativeKey === "function" ? taskOrNativeKey : NATIVE_FORMAT_TASKS[taskOrNativeKey];

  return new task().configure(...args);
}
