<div [formGroup]="form" class="container">
  <div class="row">
    <div class="col">
      <mat-form-field class="black">
        <mat-label> <!--@i18n-->Current password: *<!----> </mat-label>
        <input [type]="inputType.oldPassword" matInput formControlName="oldPassword" class="case-sensitive"
        [mask]="form.getMask('oldPassword')" />
        <button mat-icon-button matSuffix *ngIf="showInputIcon('oldPassword')"
        (click)="toggleInputType('oldPassword')">
          <mat-icon>{{ getInputIcon('oldPassword') }}</mat-icon>
        </button>
        <mat-error>{{ form.getError("oldPassword") }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field class="black">
        <mat-label> <!--@i18n-->New password: *<!----> </mat-label>
        <input [type]="inputType.password" matInput formControlName="password" class="case-sensitive"
        [mask]="form.getMask('password')" />
        <button mat-icon-button matSuffix *ngIf="showInputIcon('password')"
        (click)="toggleInputType('password')">
          <mat-icon>{{ getInputIcon('password') }}</mat-icon>
        </button>
        <mat-error>{{ form.getError("password") }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field class="black">
        <mat-label> <!--@i18n-->Confirm password: *<!----> </mat-label>
        <input [type]="inputType.passwordConfirm" matInput formControlName="passwordConfirm" class="case-sensitive"
        [mask]="form.getMask('passwordConfirm')" />
        <button mat-icon-button matSuffix *ngIf="showInputIcon('passwordConfirm')"
        (click)="toggleInputType('passwordConfirm')">
          <mat-icon>{{ getInputIcon('passwordConfirm') }}</mat-icon>
        </button>
        <mat-error>{{ form.getError("passwordConfirm") }}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row buttons">
    <div class="col">
      <button mat-raised-button (click)="close()">
        <!--@i18n-->Cancel<!---->
      </button>
    </div>

    <div class="col">
      <button mat-raised-button color="accent" [disabled]="inProcess" (click)="save()">
        <!--@i18n-->Change<!---->
      </button>
    </div>
  </div>
</div>
