export function getTextNode(node: Node): string | null {
  if (node.nodeType === Node.TEXT_NODE) {
    return node.nodeValue;
  }
  if (node instanceof HTMLElement) {
    node.innerText;
  }
  return node.textContent;
}

export function setStyle(
  element: HTMLElement,
  style: Partial<Record<keyof CSSStyleDeclaration, string | null>>
): void {
  for (const key in style) {
    const value = style[key];

    if (value) {
      element.style[key] = value;
    } else {
      const name = key.replace(/[A-Z]/g, e => `-${ e.toLowerCase() }`);
      element.style.removeProperty(name);
    }
  }
}
