import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { MatIconModule } from "@angular/material/icon";
import { AuthRoutingModule } from "./auth-routing.module";
import { AuthComponent } from "./auth.component";
import { NgxMaskModule } from "ngx-mask";
import { NgI18nModule } from "src/@prisma/ng-i18n";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { FormComponent } from "./form/form.component";
import { BannerComponent } from "./banner/banner.component";
import { ThemeModule } from "../theme/theme.module";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    AuthComponent,
    FormComponent,
    BannerComponent
  ],
  imports: [
    AuthRoutingModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    NgI18nModule,
    NgxMaskModule.forChild(),
    RouterModule,
    ThemeModule
  ]
})
export class AuthModule { }
