import { Field, Validate, pattern, format, Format } from "src/@prisma/js-proto";
import { User } from "./user.model";
import { GroupModel } from "src/@prisma/js-common";
import { Mask, NgMask } from "src/@prisma/ng-forms";
import { GroupUser } from "./group-user.model";

export class Group extends GroupModel {
  override id!: string;

  @Validate("update", pattern("required", [ /*@i18n*/("Group name not informed") ]))
  @Mask(NgMask.maxLength(60))
  override name!: string;

  @Mask(NgMask.maxLength(200))
  override obs!: string | null;

  @Field({ type: () => GroupUser })
  override users: GroupUser[] = [];

  override createdAt!: Date;
  override createdBy!: string;

  @Field({ type: () => User })
  @Format(format("empty"))
  override createdByUser!: User;

  @Field({ type: () => User })
  @Format(format("empty"))
  override updatedByUser?: User;
}
