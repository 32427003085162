import { Field, Format, HttpPath, Primary, Validate, format, pattern } from "src/@prisma/js-proto";
import { DateField } from "../../decorators";
import { PoolMessageCoreMessageModel } from "./message-core-message.model";

@HttpPath("plugin/pool/message")
export class PoolMessageModel {
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid UUID"), "POL-MSG-0x001" ]))
  @Primary()
  public id?: string;

  @Field()
  @Validate("create", [
    pattern("required", [ /*@i18n*/("Group ID not informed"), "POL-MSG-C-0x001" ])
  ])
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid group UUID"), "POL-MSG-0x001" ]))
  public groupId?: string;

  @Field()
  @Validate("create", [
    pattern("required", [ /*@i18n*/("Message type not informed"), "POL-MSG-C-0x002" ])
  ])
  @Validate([
    pattern("enum", [ /*@i18n*/("Invalid message type"), "POL-MSG-0x002" ], [ 1, 2, 3 ])
  ])
  public type?: number;

  @Field()
  @Validate([
    pattern("min", [ /*@i18n*/("Message title too short"), "POL-MSG-0x003" ], 1),
    pattern("max", [ /*@i18n*/("Message title too long"), "POL-MSG-0x004" ], 60)
  ])
  @Format([
    format("trim")
  ])
  public title?: string;

  @Field()
  @Validate("create", [
    pattern("required", [ /*@i18n*/("Message body not informed"), "POL-MSG-C-0x003" ])
  ])
  @Validate([
    pattern("min", [ /*@i18n*/("Message body too short"), "POL-MSG-0x004" ], 1),
    pattern("max", [ /*@i18n*/("Message body too long"), "POL-MSG-0x005" ], 200)
  ])
  @Format([
    format("trim")
  ])
  public body?: string;

  @Field()
  public broadcast?: boolean;

  @DateField()
  public fulfilledAt?: Date;

  @Field()
  @Validate([
    pattern("min", [ /*@i18n*/("Expiration time too short"), "POL-MSG-0x006" ], 1000),
    pattern("max", [ /*@i18n*/("Expiration time too long"), "POL-MSG-0x007" ], 2 ** 31)
  ])
  public expiresIn?: number;

  @DateField()
  public expiresAt?: Date;

  @Field({ array: true, type: () => PoolMessageCoreMessageModel })
  public coreMessages?: PoolMessageCoreMessageModel[];

  @DateField()
  public createdAt?: Date;
}
