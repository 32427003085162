export type I18nDictionary = Record<string, string>
export type I18nLocales<T extends string> = Record<T, I18nDictionary>

export type I18nSimpleGetter = (text: string) => string

/**
 * @param text - Text to search in the dictionary.
 * @param args - Values to replace %s (string) and %d (number).
 *
 * Ex: `("I have %d %s", 2, "dogs") => "I have 2 dogs"`
 */
export type I18nGetter = (text: string, ...args: (string | number)[]) => string

export interface I18nConfiguration<L extends string> {
  locales?: { name: L | L[], dict: I18nDictionary }[]
  defaultLocale?: L
  /**
   * Use this locale in case the text is not found in current locale. default `defaultLocale`.
   */
  retryInLocale?: L
  /**
   * @default true
   */
  switchToDefault?: boolean
}
