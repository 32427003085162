import { ValidationError, ValidationResult } from "../interfaces";

export class FieldError<T = any> extends Error {
  override readonly name = "FieldError";
  public code?: string;

  static isFieldError(value: any): value is FieldError {
    return (
      value instanceof FieldError ||
      value instanceof Error && value.name === "FieldError"
    );
  }

  constructor(public readonly error: ValidationResult<T>[] | ValidationError | ValidationError[] | string) {
    super();
    [ this.message, this.code ] = this.getFirstMessage() || [ "" ];
  }

  private isValidationError(value: any): value is ValidationError | ValidationError[] {
    return typeof value?.message === "string" || typeof value?.[0]?.message === "string";
  }

  private getFirstMessage(result?: ValidationResult<any>[]): [ string, string? ] | void {
    if (!result && this.isValidationError(this.error)) {
      return Array.isArray(this.error) ?
        [ this.error[0].message || "", this.error[0].code ] :
        [ this.error.message || "", this.error.code ];
    }

    const value = result || this.error;

    if (typeof value === "string") return [ value ];
    if (!value || this.isValidationError(value)) return;

    // Give preference to the main object
    for (const e of value) {
      if (e.errors?.length) return [ e.errors[0]?.message || "", e.errors[0]?.code ];
    }

    // Now check the nested errors
    for (const e of value) {
      if (!e.result?.length) continue;
      const message = this.getFirstMessage(e.result);
      if (message) return message;
    }
  }
}
