import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DefaultFormContainerComponent } from "./default-form-container.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatTooltipModule } from "@angular/material/tooltip";


@NgModule({
  declarations: [
    DefaultFormContainerComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatTooltipModule
  ],
  exports: [
    DefaultFormContainerComponent
  ]
})
export class DefaultFormContainerModule { }
