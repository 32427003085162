import { Injectable } from "@angular/core";
import { Bulk, HttpConfig } from "src/@prisma/js-proto";
import { Cache } from "../cache";
import { NgDefaultTask } from "./default.task";

export function buildCacheIndex(http: Required<HttpConfig>): string {
  const [ path, query ] = http.path.split("?");
  return `${http.method.toUpperCase()}::${path}${(query ? "?" + query.split("&").sort().join("&") : "")}`;
}

export function buildCacheGroupIndex(http: Required<HttpConfig>): string {
  const url = http.path.replace(/(^[^/]*:\/\/|$\/)/, "");
  const i = url.indexOf("/");
  return url.substring(0, i > -1 ? i : void 0);
}

@Injectable()
export class GetCacheTask extends NgDefaultTask {
  static STAGE = "get-cache";
  MAP = { [GetCacheTask.STAGE]: this };

  constructor(
    private cache: Cache
  ) {
    super(async context => {
      const options = context.requireOptions();
      if (!options.http) throw new Error("HttpConfig not informed");
      if (options.force || (options.force === void 0 && options.http.method !== "get")) return;

      const saved = this.cache.get(
        context.payload instanceof Bulk ? context.payload.type : buildCacheGroupIndex(options.http),
        buildCacheIndex(options.http),
        { saveError: options.cacheError }
      );

      if (saved) {
        options.hasCache = true;
        context.resolve(await saved);
      } else if (
        options.cache ||
        (options.cache === void 0 && options.http.method === "get")
      ) {
        options.hasCache = false;
        this.cache.set(
          context.payload instanceof Bulk ? context.payload.type : buildCacheGroupIndex(options.http),
          buildCacheIndex(options.http),
          context.output
        );
      }
    });
  }
}

@Injectable()
export class ClearCacheTask extends NgDefaultTask {
  static STAGE = "clear-cache";
  MAP = { [ClearCacheTask.STAGE]: this };

  constructor(
    private cache: Cache
  ) {
    super(async context => {
      context.beforeOutput(() => {
        const options = context.requireOptions();
        if (!options.http) throw new Error("HttpConfig not informed");
        if (!options.clearCache) return;

        this.cache.clear(
          context.payload instanceof Bulk ?
            context.payload.type : buildCacheGroupIndex(options.http)
        );
      });
    });
  }
}
