import { ValidationContext } from "../interfaces";
import { ValidationTaskTemplate } from "./task-template";

export class ValidateGenericTask extends ValidationTaskTemplate {
  private cb!: (context: ValidationContext) => boolean | Promise<boolean>;

  override configure(cb: (context: ValidationContext) => boolean | Promise<boolean>): this {
    this.cb = cb;
    return this;
  }

  override async validate(context: ValidationContext): Promise<any> {
    if (!(await this.cb(context))) throw this.getFieldError();
  }
}
