import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FormGroupModel } from "src/@prisma/ng-forms";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { UserService } from "src/app/services/user.service";
import { DefaultDialogComponent } from "src/app/utils/default-dialog/default-dialog.component";
import { User } from "src/models/user.model";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"]
})
export class PasswordComponent {
  inputType = {
    oldPassword: "password",
    password: "password",
    passwordConfirm: "password"
  };
  inProcess = false;
  form;

  constructor(
    private userService:  UserService,
    private dialogRef:    MatDialogRef<DefaultDialogComponent>,
    private feed:         MatFeed
  ) {
    this.form = new FormGroupModel(User, {
      event: "password"
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  async save(): Promise<void> {
    this.inProcess = true;

    try {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();

      if (this.form.invalid) {
        this.feed.warn/*@i18n*/("Fill in all form fields");
        return;
      }
      await this.userService.password(this.form.value);
      this.feed.success/*@i18n*/("User password successfully changed");
      this.close();
    } catch (e) {
      this.feed.fromError(e);
    } finally {
      this.inProcess = false;
    }
  }

  showInputIcon(field: keyof User): boolean {
    return !!this.form.controls[field].value;
  }

  getInputIcon(field: keyof typeof this.inputType): string {
    return this.inputType[field] === "text" ? "visibility_off" : "visibility";
  }

  toggleInputType(field: keyof typeof this.inputType): void {
    this.inputType.let(it => it[field] = it[field] === "text" ? "password" : "text");
  }
}
