import { Injectable } from "@angular/core";
import { Bulk } from "src/@prisma/js-proto";
import { RunnerPool } from "src/@prisma/js-proto/runner/pool";
import { NgDefaultTask } from "./default.task";

@Injectable()
export class BulkFormatTask extends NgDefaultTask {
  static STAGE = "bulk-format";
  MAP = { [BulkFormatTask.STAGE]: this };

  constructor() {
    super(async context => {
      const options = context.requireOptions();
      const payload = context.payload;

      if (!(payload instanceof Bulk)) return;

      const names = [ RunnerPool.DEFAULT ];
      if (options.event !== void 0 && options.event !== RunnerPool.DEFAULT) names.push(options.event);
      await payload.getFormatter().format(names, payload, options.formatterOptions);
    });
  }
}
