import { Injectable } from "@angular/core";
import { BulkFormatTask, BulkValidateTask, HttpTask, BodyBuilderTask, ParseParamsTask, GetCacheTask, PreRequestTask, RequestTask, TokenStoreTask, EmitEventTask, ClearCacheTask } from "../tasks";
import { NgEventRunner } from "./event.runner";

@Injectable()
export class NgEventAuthRunner extends NgEventRunner {
  override readonly RUNNER = "auth";

  protected override onInit(): void {
    this.reorder([
      BulkFormatTask.STAGE,
      BulkValidateTask.STAGE,
      HttpTask.STAGE,
      BodyBuilderTask.STAGE,
      ParseParamsTask.STAGE,
      PreRequestTask.STAGE,
      GetCacheTask.STAGE,
      RequestTask.STAGE,
      TokenStoreTask.STAGE,
      ClearCacheTask.STAGE,
      EmitEventTask.STAGE
    ]);
  }
}
