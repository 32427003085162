import { Field, Format, HttpPath, Primary, Validate, format, pattern } from "src/@prisma/js-proto";
import { DateField } from "../../decorators";
import { PoolGroupModel } from "./group.model";
import { DeviceModel } from "../../models";

@HttpPath("plugin/pool/group/device")
export class PoolGroupDeviceModel {
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid UUID"), "POL-GRP-0x001" ]))
  @Primary()
  public id?: string;

  @Validate("create", [
    pattern("required", [ /*@i18n*/("Group ID not informed"), "POL-GRP-C-0x001" ])
  ])
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid group UUID"), "POL-GRP-0x002" ]))
  @Field()
  public groupId?: string;

  @Validate("create", [
    pattern("required", [ /*@i18n*/("Device serial not informed"), "POL-GRP-C-0x002" ])
  ])
  @Validate([
    pattern("min", [ /*@i18n*/("Device serial too short"), "POL-GRP-0x003" ], 5),
    pattern("max", [ /*@i18n*/("Device serial too long"), "POL-GRP-0x004" ], 30)
  ])
  @Format([
    format("trim"),
    format("lowercase")
  ])
  @Field()
  public deviceSerial?: string;

  @DateField()
  public createdAt?: Date;

  @Field({ type: () => DeviceModel })
  public device?: DeviceModel;

  @Field({ type: () => PoolGroupModel })
  public group?: PoolGroupModel;
}
