import { AfterSerialize, BeforeSerialize, ConstructableNode, Serializable, SerializableArray, SerializableObject, SimpleFieldType } from "../interfaces/index";
import { ModelNode } from "./node";
import { Proto } from "./proto";

export class NodeFunctionIterator<T> {
  constructor(
    public readonly node: ModelNode,
    public readonly fnFieldName: keyof T,
    public readonly iterated: any[] = []
  ) {}

  async iterate(): Promise<void> {
    if (typeof this.node.value !== "object" || this.node.value === null) return;

    if (Array.isArray(this.node.value)) {
      for (let i = 0, len = this.node.value.length; i < len; i++)
        await new NodeFunctionIterator(
          this.node.grow(this.node.value[i], this.node.parentRef), this.fnFieldName, this.iterated).iterate();

      return;
    }

    for (let i = 0; i < this.iterated.length; i++)
      if (this.iterated[i] === this.node.value) return;

    this.iterated.push(this.node.value);

    for (let i = 0, children = this.node.getChildren(); i < children.length; i++) {
      await new NodeFunctionIterator(children[i], this.fnFieldName, this.iterated).iterate();
    }

    if (typeof this.node.value[this.fnFieldName] === "function")
      await this.node.value[this.fnFieldName]();
  }
}

export class SerializableContext<T = any> {
  static from<T>(value: T): SerializableContext<T> {
    return new SerializableContext(new ModelNode<T, any>(value));
  }

  static fromType<T extends object>(
    type: ConstructableNode<T>, value: T): SerializableContext<T>
  {
    return new SerializableContext(ModelNode.fromType(type, value));
  }

  get value(): T { return this.node.value; }

  constructor(public readonly node: ModelNode<T>) {}

  /**
   * Digest each element, recursively, from node if value is an array.
   */
  private async digestArray(): Promise<SerializableArray> {
    const arr: SerializableArray = [];

    if (Array.isArray(this.node.value))
      for (let i = 0, len = this.node.value.length; i < len; i++)
        arr.push(
          await new SerializableContext(this.node.sibling(this.node.value[i], {
            type: this.node.dirty ? this.node.type : void 0
          })).digestValue()
        );

    return arr;
  }

  /**
   * Digest all fields, recursively, from current node if value is an object with metadata.
   */
  private async digestObject(): Promise<SerializableObject | undefined> {
    if (typeof this.value !== "object" || Array.isArray(this.value) || this.value === null) return;

    const serialized: SerializableObject = {};

    for (let i = 0, children = this.node.getChildren(); i < children.length; i++) {
      const node = children[i];
      const name = node.alias || node.parentRef;
      if (name)
        serialized[name] = await new SerializableContext(node).digest();
    }

    return serialized;
  }

  /**
   * Digest node value ignoring validations such: `array`, `json` and `nullable`.
   * `digestObject` will be called if node type is a function.
   */
  private async digestValue(): Promise<Serializable> {
    if (typeof this.node.type === "function") return await this.digestObject();

    if (
      this.node.type === void 0 ||
      (typeof this.node.type === "string" && typeof this.node.value !== this.node.type) ||
      (Array.isArray(this.node.type) && !this.node.type.includes(typeof this.node.value as SimpleFieldType))
    ) {
      return;
    }

    return Proto.isSerializableValue(this.node.value) ? this.node.value : void 0;
  }

  /**
   * Execute all children `afterSerialize` callbacks and then run current node callback.
   */
  private async afterSerialize(): Promise<void> {
    await new NodeFunctionIterator<AfterSerialize>(this.node, "afterSerialize").iterate();
  }

  /**
   * Execute all children `beforeSerialize` callbacks and then run current node callback.
   */
  private async beforeSerialize(): Promise<void> {
    await new NodeFunctionIterator<BeforeSerialize>(this.node, "beforeSerialize").iterate();
  }

  /**
   * Serialize context node.
   *
   * * `beforeSerialize` / `afterSerialize` callbacks will only be called when digesting the root node.
   * @returns
   */
  async digest(): Promise<Serializable> {
    if (this.node.isRoot()) await this.beforeSerialize();

    const serialize = this.getSerializeMethod();
    if (serialize) return await serialize(this);

    if (this.node.exclude) return;

    if (this.node.value === null) {
      return this.node.nullable ? null : void 0;
    }

    if (this.node.json) return Proto.isSerializable(this.node.value) ? this.node.value : undefined;

    let result: Serializable;

    if (this.node.array)
      result = this.node.value ? await this.digestArray() : void 0;
    else
      result = await this.digestValue();

    if (this.node.isRoot()) await this.afterSerialize();

    return result;
  }

  /**
   * Get custom `serialize` function defined in `fieldOptions`.
   */
  getSerializeMethod(): ((node: SerializableContext<T>) => Serializable | Promise<Serializable>) | undefined {
    return this.node.fieldOptions?.serialize;
  }
}
