export function numberToBitwise<T extends number>(value: number): T[] {
  const ret: T[] = [];

  for (let i = 0; i <= value; i++) {
    if (Math.log2(i) % 1 === 0) {
      if (value & i) {
        ret.push(i as T);
      }
    }
  }
  return ret;
}

export function bitwiseToNumber<T extends number>(values: T[]): number {
  return values.reduce((prev, current) => prev + current, 0);
}
