import { AsyncValidatorFn, FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { FieldError, ModelNode, RunnerPool, ValidationContextOptions } from "../../js-proto";
import { DeepPartialArray, factory, toArray } from "../../js-utils";
import { FormArrayModel } from "./form-array-model";
import { FormGroupModel } from "./form-group-model";
import { IFormModel, FormModelOptions } from "./interfaces";
import { getValidators, parseValidator } from "../util";
import { NgValidateDefaultTask } from "../validators";
import { NgMask } from "../mask";

export class FormControlModel<T = any> extends FormControl<DeepPartialArray<T> | null> implements IFormModel {
  private taskValidators: NgValidateDefaultTask[] = [];
  subscription = new Subscription();

  get parentGroup(): FormGroupModel | null {
    let parent: FormGroupModel | FormArrayModel | null = this.parent;
    while (parent instanceof FormArrayModel && parent)
      parent = parent?.parent || null;

    return parent;
  }
  get parentArray(): FormArrayModel | null {
    let parent: FormGroupModel | FormArrayModel | null = this.parent;
    while (parent instanceof FormGroupModel && parent)
      parent = parent?.parent || null;

    return parent;
  }

  override readonly errors!: FieldError | null;
  override get parent(): FormGroupModel | FormArrayModel | null {
    return super.parent as any;
  }
  override get root(): FormGroupModel | FormArrayModel {
    return super.root as any;
  }

  constructor(
    public readonly node: ModelNode<T>,
    opts: FormModelOptions<T> = {}
  ) {
    super(opts.value ?? null, {
      nonNullable: true,
    });


    this.addAsyncValidators(this.getFieldValidators({ event: opts.event }));
  }

  private getFieldValidators(
    opts?: ValidationContextOptions
  ): AsyncValidatorFn[] {
    const node = this.node;
    const event = toArray(opts?.event || []).slice();

    event.pushOne(RunnerPool.DEFAULT);
    return node.parentRef && node.parent ? getValidators(node.parent, event)[node.parentRef]
      ?.map(e => {
        this.taskValidators.push(e);
        return parseValidator(e, { ...opts, extras: this });
      }) || [] : [];
  }

  initValidators(): this {
    this.taskValidators.forEach(e => e.init?.(this));
    return this;
  }

  removeFromArray(): boolean {
    if (this.parent instanceof FormArrayModel) return this.parent.remove(this);
    return false;
  }

  getMask(): string {
    const type = this.node.parent?.typeObject;
    if (!type) return "";
    const parentRef = this.node.parentRef as keyof InstanceType<typeof type>;
    return factory(NgMask.get(type).fields[parentRef]?.pattern, this.parentGroup?.value) || "";
  }

  hasMask(): boolean {
    return !!this.getMask();
  }

  destroy(): void {
    this.subscription.unsubscribe();
  }

  watch(cb: (value: DeepPartialArray<T> | null) => any): Subscription {
    const subs = this.valueChanges.subscribe(cb);
    this.subscription.add(subs);
    return subs;
  }

  override getError(): string | undefined {
    return super.getError("message");
  }
}
