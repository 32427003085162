<div class="default-header">
  <div class="default-header-title">
    <div class="lines">
      <h6 class="line1 text-primary">{{ title.line1 }}</h6>
      <h1 class="line2">{{ title.line2 }}</h1>
      <h3 class="line3">{{ title.line3 }}</h3>
    </div>

    <div class="img-container">
      <img *ngIf="showLogo" [src]="img">
    </div>
  </div>

  <hr>
</div>
