import { MetadataManager, Proto, ProtoDebugLevel } from "../core";
import { FieldOptions } from "../interfaces";
import { ModelMetadata } from "../metadata";

function getType(target: Object, key: string | symbol): "string" | "boolean" | "number" | Function {
  const type = Reflect.getMetadata("design:type", target, key);

  switch (type) {
    case String:  return "string";
    case Number:  return "number";
    case Boolean: return "boolean";
  }

  return type;
}

export function Field<T>(options: FieldOptions<T> = {}): PropertyDecorator {
  return (target, key): void => {
    if (typeof key !== "string") return;

    if (!options.type) {
      const type = getType(target, key);
      if (typeof type === "string") options.type = type;
    }

    const metadata = MetadataManager.getOrSet(target, ModelMetadata, () => new ModelMetadata());
    Object.assign(metadata.fields[key] || (metadata.fields[key] = {}), options);

    if (!metadata.fields[key]?.type && (Proto.debugLevel & ProtoDebugLevel.WARNING))
      console.warn(`[Field] Type not specified for model '${target.constructor.name}' => '${key}'`);
  };
}
