<div class="container-flex-column" #content>
  <theme-header></theme-header>

  <div class="page">
    <div [@fadeInOut]="router.url">
      <ng-container [ngSwitch]="selected?.path">
        <ng-container *ngFor="let menu of footerMenus">
          <default-header *ngSwitchCase="menu.path" [img]="logo"
            [title]="menu.title || { line1: '', line2: '', line3: '' }">
          </default-header>
        </ng-container>
      </ng-container>

      <div class="page-body" #pageContent>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <theme-footer [menus]="footerMenus"></theme-footer>
</div>
