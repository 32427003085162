import { ContextOptions, ProtoPartialRecord } from "../interfaces";
import { ContextBuilder } from "./context-builder";
import { Task } from "./task";
import { TaskContext } from "./task-context";

export class Runner<
  T = any,
  O = any,
  TOptions extends ContextOptions = {},
  Stage extends string = string,
  Context extends TaskContext<T, O, TOptions> = TaskContext<T, O, TOptions>>

  extends ContextBuilder<T, O, TOptions, Context>
{
  private stageTasks: ProtoPartialRecord<Stage, Task<T, O, TOptions, Context>[]> = {};
  private stages: Stage[] = [];

  constructor(
    stageTasks?: ProtoPartialRecord<Stage, Task<T, O, TOptions, Context> | Task<T, O, TOptions, Context>[]>)
  {
    super();
    if (stageTasks) this.append(stageTasks);

    this.onInit?.();
  }

  /**
   * Override this function to initialize some configuration without the need to change the constructor.
   */
  protected onInit?(): void;

  /**
   * Get the reference for the array of the specified stage. If stage doesn't exists a new array will
   * be created.
   */
  getTasks(stage: Stage): Task<T, O, TOptions, Context>[] {
    return this.stageTasks[stage] || (this.stageTasks[stage] = []);
  }

  /**
   * Get all stages and it's respective tasks.
   */
  getStages(): ProtoPartialRecord<Stage, Task<T, O, TOptions, Context>[]> {
    return { ...this.stageTasks };
  }

  /**
   * Change the order of execution of the registered stages.
   */
  reorder(stages: Stage[]): this { return (this.stages = stages, this); }

  /**
   * Get the reference of the current execution order array.
   */
  getOrder(): Stage[] { return this.stages; }

  /**
   * Add a new task at the end of a specified stage.
   * If the stage doesn't exits it will be created and added at the end of the order of execution.
   */
  append(stage: Stage, tasks?: Task<T, O, TOptions, Context> | Task<T, O, TOptions, Context>[]): this
  append(
    stages: Partial<Record<Stage, Task<T, O, TOptions, Context> | Task<T, O, TOptions, Context>[]>>): this
  append(
    stageOrTasks: Stage | ProtoPartialRecord<Stage, Task<T, O, TOptions, Context>
    | Task<T, O, TOptions, Context>[]>,
    tasks: Task<T, O, TOptions, Context> | Task<T, O, TOptions, Context>[] = []): this
  {
    if (typeof stageOrTasks === "object") {
      for (const stage in stageOrTasks)
        this.append(stage, stageOrTasks[stage]);

      return this;
    }

    if (Array.isArray(tasks))
      this.getTasks(stageOrTasks).push(...tasks);
    else
      this.getTasks(stageOrTasks).push(tasks);

    if (!this.stages.includes(stageOrTasks)) this.stages.push(stageOrTasks);

    return this;
  }

  /**
   * Add a new task at the beginning of a specified stage.
   * If the stage doesn't exits it will be created and added at the end of the order of execution.
   */
  prepend(stage: Stage, tasks?: Task<T, O, TOptions, Context> | Task<T, O, TOptions, Context>[]): this
  prepend(
    stages: Partial<Record<Stage, Task<T, O, TOptions, Context> | Task<T, O, TOptions, Context>[]>>): this
  prepend(
    stageOrTasks: Stage | ProtoPartialRecord<Stage, Task<T, O, TOptions, Context>
    | Task<T, O, TOptions, Context>[]>,
    tasks: Task<T, O, TOptions, Context> | Task<T, O, TOptions, Context>[] = []): this
  {
    if (typeof stageOrTasks === "object") {
      for (const stage in stageOrTasks)
        this.prepend(stage, stageOrTasks[stage]);

      return this;
    }

    if (Array.isArray(tasks))
      this.getTasks(stageOrTasks).unshift(...tasks);
    else
      this.getTasks(stageOrTasks).unshift(tasks);

    if (!this.stages.includes(stageOrTasks)) this.stages.push(stageOrTasks);

    return this;
  }

  /**
   * Execute runner with a generated context. Uses `buildContext()`.
   */
  execute(payload: T, stages?: Stage[]): Promise<Context>
  execute(payload: T, opts?: TOptions, stages?: Stage[]): Promise<Context>
  execute(payload: T, optsOrStages?: TOptions | Stage[], stages?: Stage[]): Promise<Context> {
    stages = stages || Array.isArray(optsOrStages) ? optsOrStages as Stage[] : void 0;
    optsOrStages = !Array.isArray(optsOrStages) ? optsOrStages : void 0;

    return this.executeContext(this.buildContext(payload, optsOrStages), stages);
  }

  /**
   * Execute runner with a specified context. Avoid using the same context for multiples executions
   * unless you know what are you doing.
   */
  async executeContext(context: Context, stages: Stage[] = this.stages): Promise<Context> {
    try {
      for (let i = 0; i < stages.length; i++) {
        const stage = stages[i];
        const tasks = this.getTasks(stage);

        for (let i = 0; i < tasks.length; i++) {
          if (context.isStopped()) break;
          await tasks[i].executeContext(context);
        }

        if (context.isStopped()) {
          context.emit("stopped", stage);
          break;
        }
      }
    } catch (e) {
      context.emit("error", e);
      throw e;
    }

    return context;
  }
}
