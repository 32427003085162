import { Exception } from "./exception";

const __proto__ = ({} as any)["__proto__"];

/**
 * Check if variable is `undefined`.
 */
export function isEmpty(value: any): value is undefined {
  return value === void 0;
}

/**
 * Check if variable is `null`.
 */
export function isNull(value: any): value is null {
  return value === null;
}

/**
 * Check if variable is `boolean`.
 */
export function isBool(value: any): value is boolean {
  return typeof value === "boolean";
}

/**
 * Remove duplicated elements in array.
 * @param compareFn - Function to determine if the element is duplicated.
 *
 * Ex.: `unique(userArray, (current, next) => current.id === next.id)`.
 * "next" will be removed from array.
 */
export function unique<T>(arr: T[], compareFn?: (current: T, next: T) => boolean): T[] {
  for (let i = 0; i < arr.length; i++) {
    const value = arr[i];

    for (let j = i + 1; j < arr.length; j++) {
      if (compareFn ? compareFn(value, arr[j]) : arr[j] === value) { arr.splice(j, 1); j--; }
    }
  }

  return arr;
}

/**
 * Checks if object is a JSON.
 */
export function isCommonObject(value: any): boolean {
  return typeof value === "object" && value !== null && value.__proto__ === __proto__;
}

/**
 * Check if variable is a empty object.
 */
export function isEmptyObject(value: any): boolean {
  return isCommonObject(value) && JSON.stringify(value) === "{}";
}

/**
 * Remove fields with `undefined` values.
 * @deprecated Will be removed in future updates.
 */
export function clean<T>(obj: Required<T>): Partial<T> {
  for (const key in obj) {
    if (obj[key] === void 0) delete obj[key];
  }

  return obj;
}

/**
 * Check if required fields exists in a object.
 * @param fields - Field name and exception if field is not found.
 *
 * `key` - Object key name or array of keys if **only one of them** is required.
 */
export function requiredFields<T>(
  object: T, fields: { key: keyof T | (keyof T)[]; exception: Exception }[]): void
{
  for (const field of fields) {
    const keys  = field.key;
    let success = false;

    for (const key of Array.isArray(keys) ? keys : [ keys ]) {
      const item = object[key];

      if (item !== void 0 && item !== null && item as any !== "") {
        success = true;
      }
    }

    if (!success) throw field.exception;
  }
}

/**
 * Holds the execution of current block for a given number of milliseconds.
 */
export async function sleep(ms: number): Promise<void> {
  await new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Checks whether the key exists in the object
 */
export function isInObject<T extends object>(key: string, obj: T): key is Extract<keyof T, string> {
  return key in obj;
}

/**
 * Get value from a factory function.
 */
export function factory<T>(
  value: T, ...params: T extends (...args: any) => any ? Parameters<T> : never
): T extends (...args: any) => any ? ReturnType<T> : T
export function factory(value: any, ...params: any[]): any {
  return typeof value === "function" ? value(...params) : value;
}

/**
 * Return array of `element` if `element` isn't array
 */
export function toArray<T>(element: T | T[]): T[] {
  return Array.isArray(element) ? element : [ element ];
}
