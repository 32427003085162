import { Injectable } from "@angular/core";
import { ApiConfig } from "../api-config.service";
import { NgDefaultTask } from "./default.task";

@Injectable()
export class BodyBuilderTask extends NgDefaultTask {
  static STAGE = "body-builder";
  MAP = { [BodyBuilderTask.STAGE]: this };

  constructor(
    apiConfig: ApiConfig
  ) {
    super(async context => {
      const options = context.requireOptions();
      if (options.body) return;
      options.body = await apiConfig.buildBody(context);
      if (options.singleBodyData && Array.isArray(options.body)) {
        options.body = options.body?.[0];
      }
    });
  }
}
