import { ConstructableNode, MetadataManager, Proto } from "../../js-proto";
import { IConfig, initialConfig } from "ngx-mask";
import { PartialRecord } from "src/@prisma/js-utils";
import { NgMaskOptions } from "./interfaces";

export function ngMaskConfig(config: Partial<IConfig> = {}): IConfig {
  return Proto.merge(
    config, Object.assign(initialConfig, <Partial<IConfig>>{
      decimalMarker:         ",",
      thousandSeparator:     ".",
      validation:            false,
      dropSpecialCharacters: false,
      patterns: {
        C: { pattern: /./ },
        c: { pattern: /./, symbol: "*" },
        A: { pattern: /[a-záàâäãåçéèêëíìîïñóòôöõúùûü ]/i },
        a: { pattern: /[a-záàâäãåçéèêëíìîïñóòôöõúùûü ]/i, symbol: "*" },
        N: { pattern: /[0-9]/i },
        n: { pattern: /[0-9]/i, symbol: "*" }
      }
    })
  );
}

export class NgMask<Model> {
  static readonly CPF          = "NNN.NNN.NNN-NN";
  static readonly CNPJ         = "NN.NNN.NNN/NNNN-NN";
  static readonly CEP          = "NNNNN-NNN";
  static readonly CELLPHONE_BR = "(NN) N NNNN-NNNN";
  static readonly PHONE_BR     = "(NN) NNNN-NNNN";

  fields: PartialRecord<keyof Model, NgMaskOptions> = {};

  static get<Model>(model: ConstructableNode<Model> | Model): NgMask<Model> {
    return MetadataManager.get(model, NgMask) || new NgMask();
  }

  /**
   * Create a mask with a limit of character
   * @param char character to be filled to create the pattern. Ex.: `limit(3, "C") => "CCC"` (Default: `C`)
   */
  static maxLength(limit: number, char = "C"): string {
    return char.repeat(limit);
  }
}
