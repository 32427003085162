import moment = require("moment-timezone");
import { Inject, InjectionToken, Optional, Pipe, PipeTransform } from "@angular/core";

export const DEFAULT_DATE_FORMAT = new InjectionToken<string>("DefaultDateFormat");

@Pipe({
  name: "date"
})
export class DatePipe implements PipeTransform {
  constructor(
    @Optional() @Inject(DEFAULT_DATE_FORMAT)
    private defaultDateFormat: string = ""
  ) {}

  transform(value: moment.MomentInput, format = this.defaultDateFormat): string {
    return moment(value).format(format);
  }
}
