export class Exception extends Error {
  "@instanceof" = Symbol.for("Exception");

  static isException(value: any): value is Exception {
    return (
      value instanceof Exception ||
      value?.["@instanceof"] === Symbol.for("Exception")
    );
  }

  constructor(override message: string, public code: string | number) {
    super(message);
  }
}

/**
 * @deprecated use `Exception.isException` instead
 */
export function isException(value: any): value is Exception {
  return Exception.isException(value);
}
