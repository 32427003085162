import { FormModel, NgValidationContext } from "../forms/interfaces";
import { NgValidateDefaultTask } from "./default.task";

export class NgValidateEqualTask extends NgValidateDefaultTask {
  targetRef!: string;

  override configure(targetRef: string): this {
    this.targetRef = targetRef;
    return this;
  }

  init(form: FormModel): void {
    form.parent?.get(this.targetRef)?.watch(() => {
      form.updateValueAndValidity({ emitEvent: false });
    });
  }

  override async validate(context: NgValidationContext): Promise<void> {
    const target = (
      context.options?.extras?.parent?.get(this.targetRef)?.value ||
      context.options?.model[this.targetRef]
    );
    if (!target) return;

    if ((target ?? "") !== (context.payload ?? "")) throw this.getFieldError();
  }
}
