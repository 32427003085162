import { TaskCallback } from "src/@prisma/js-proto/runner/task-callback";
import { NgRunnerOptions } from "../interface";
import { NgTaskContext } from "./task.context";


export abstract class NgDefaultTask<T = any>
  extends TaskCallback<T, Promise<any>, NgRunnerOptions, NgTaskContext<T>>
{
  abstract MAP: Record<string, this>

  override onInit(): void {
    this.setDefaultContext(NgTaskContext);
  }
}
