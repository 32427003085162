import { Injectable } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { Bulk, ConstructableAny } from "src/@prisma/js-proto";
import { Cache, NgEventPool } from "src/@prisma/ng-runner";

@Injectable({
  providedIn: "root"
})
export class PoolingEventService {
  private emit(clazz: ConstructableAny): void {
    this.cache.clear(clazz);
    NgEventPool.emit(clazz, "update", new Bulk(clazz));
  }

  constructor(
    private cache: Cache
  ) {}

  connect(classes: ConstructableAny[], time = 10000): Subscription {
    return interval(time).subscribe(() => {
      classes.forEach(e => this.emit(e));
    });
  }
}
