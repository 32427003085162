import { Bulk } from "../core";
import { FieldError } from "../exceptions";
import { ConstructableAny, ProtoPartialRecord, ValidationChildStages, ValidationContext, ValidationContextOptions, ValidationOptions } from "../interfaces";
import { Runner, TaskContext } from "../runner";
import { ValidationTaskTemplate } from "./task-template";

export class ValidatorRunner<
  T extends object, Stage extends Extract<keyof T, string> = Extract<keyof T, string>>
  extends Runner<any, any, ValidationContextOptions, Stage, ValidationContext>
{
  override getStages(): ProtoPartialRecord<Stage, ValidationTaskTemplate[]> {
    return super.getStages() as ProtoPartialRecord<Stage, ValidationTaskTemplate[]>;
  }

  /**
   * Does nothing. Just a trick to get the stages base on the match type.
   * @returns `{ match, stages }`
   */
  static safeChildStage<T extends ConstructableAny, I extends InstanceType<T> = InstanceType<T>>(
    match: T | T[], stages: Extract<keyof I, string> | Extract<keyof I, string>[]): ValidationChildStages
  {
    return { match, stages };
  }

  protected override onInit(): void {
    this.setDefaultContext(TaskContext);
  }

  /**
   * Validate each field of the Model as a Stage. Will throw `FieldError`.
   */
  async assert(
    bulkOrModel: Bulk<T> | T | T[],
    optsOrStages: ValidationOptions<Stage> | Stage[] = this.getOrder()): Promise<void>
  {
    const stages = Array.isArray(optsOrStages)  ? optsOrStages : optsOrStages?.stages || this.getOrder();
    const event  = !Array.isArray(optsOrStages) ? optsOrStages.event : void 0;
    const childStages = !Array.isArray(optsOrStages) ? optsOrStages.childStages : void 0;

    const bulk  = bulkOrModel instanceof Bulk ? bulkOrModel : void 0;
    const array = bulkOrModel instanceof Bulk ?
      bulkOrModel.value : Array.isArray(bulkOrModel) ?
        bulkOrModel : [ bulkOrModel ];

    for (let i = 0; i < array.length; i++) {
      const value = array[i];

      for (let i = 0; i < stages.length; i++) {
        const stage   = stages[i];
        const context = await this
          .executeContext(
            this.buildContext(value[stage], { bulk, model: value, event, childStages }),
            [ stage ]
          );

        const skippedInfo = context.getStoppedInfo();

        if (skippedInfo && FieldError.isFieldError(skippedInfo.data))
          throw skippedInfo.data;
      }
    }
  }

  /**
   * Does the same as `assert` but instead of throwing the `FieldError` it will return instead. Other
   * types of Error will still be thrown.
   */
  async validate(
    bulkOrModel: Bulk<T> | T | T[],
    optsOrStages: ValidationOptions<Stage> | Stage[] = this.getOrder()): Promise<FieldError | null>
  {
    try {
      await this.assert(bulkOrModel, optsOrStages);
    } catch (e) {
      if (FieldError.isFieldError(e))
        return e;
      else
        throw e;
    }

    return null;
  }
}
