import { Injectable } from "@angular/core";
import { Bulk, HttpMetadata } from "src/@prisma/js-proto";
import { RunnerPool } from "src/@prisma/js-proto/runner/pool";
import { factory } from "src/@prisma/js-utils";
import { ApiConfig } from "../api-config.service";
import { NgDefaultTask } from "./default.task";

@Injectable()
export class HttpTask extends NgDefaultTask {
  static STAGE = "http";
  MAP = { [HttpTask.STAGE]: this };

  constructor(
    apiConfig: ApiConfig
  ) {
    super(async context => {
      const options = context.requireOptions();
      const http = options.http;
      const subPath = [];

      let method;
      if (http) {
        subPath.unshift(http.path);
      } else if (context.payload instanceof Bulk) {
        const config = HttpMetadata.getHttpConfig(context.payload.type);
        if (!config) throw new Error("Bulk Http not configured");

        const {
          path: eventPath,
          method: eventMethod
        } = http || config.events?.[options.event || RunnerPool.DEFAULT] || {};
        subPath.unshift(eventPath);

        method = eventMethod;
        if (!HttpTask.isBaseUrl(eventPath)) subPath.unshift(config.path);
      }

      method ??= http?.method || "post";

      if (!HttpTask.isBaseUrl(subPath[0])) {
        const baseUrl = await factory(apiConfig.baseUrl, context);
        subPath.unshift(baseUrl);
      }

      const path = subPath.filter(e => e).map(
        (e, i) => i > 0 ? e?.replace(/(^\/|\/$)/gi, "") : e
      ).join("/");

      options.http = { path, method };
    });
  }

  static isBaseUrl(url?: string): boolean {
    return !!url?.match(/(^[^/]*:\/\/|$\/)/);
  }
}
