import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "generic"
})
export class GenericPipe implements PipeTransform {
  transform<T>(value: T, transform: (value: T) => string): string {
    return transform?.(value);
  }
}
