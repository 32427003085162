import { FormatTaskTemplate } from "./task-template";

export class FormatEmptyTask extends FormatTaskTemplate {
  static readonly singleton = new FormatEmptyTask();

  override configure(): this {
    return FormatEmptyTask.singleton as this;
  }

  override format(): any {
    return void 0;
  }
}
