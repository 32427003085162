import { MetadataManager } from "../core";
import { ValidationSetupOptions } from "../interfaces";
import { ModelMetadata } from "../metadata";
import { RunnerPool } from "../runner";
import { ValidateChildTask, ValidationTaskTemplate, ValidatorPool, ValidatorRunner } from "../validator";

function decorate(
  target: Object,
  key: string | symbol,
  name: string,
  patterns: ValidationTaskTemplate | ValidationTaskTemplate[],
  options?: ValidationSetupOptions): void
{
  if (typeof key !== "string") return;

  const metadata  = MetadataManager.getOrSet(target, ModelMetadata, () => new ModelMetadata());
  const validator = metadata.validator;
  const savedRunner = options?.override ? void 0 : validator.get(name);

  const runner = (savedRunner || new ValidatorRunner()).append(key, patterns);
  validator.add(name, runner);

  MetadataManager.set(target, metadata);
}

/**
 * Add validate task to multiple events.
 * @param names Events to add the tasks. Use `null` to use the default event.
 */
export function ValidateMany(
  names: (string | null)[],
  patterns: ValidationTaskTemplate | ValidationTaskTemplate[],
  options?: ValidationSetupOptions): PropertyDecorator
{
  return (target, key): void => {
    for (let i = 0; i < names.length; i++)
      decorate(target, key, names[i] ?? RunnerPool.DEFAULT, patterns, options);
  };
}

/**
 * Add validate task to an event.
 * @param name Event to add the tasks. Omit this field to use the default event.
 */
export function Validate(
  name: string,
  patterns: ValidationTaskTemplate | ValidationTaskTemplate[],
  options?: ValidationSetupOptions): PropertyDecorator
/**
 * Add validate task to default event.
 */
export function Validate(
  patterns: ValidationTaskTemplate | ValidationTaskTemplate[],
  options?: ValidationSetupOptions): PropertyDecorator
export function Validate(
  patternsOrName: ValidationTaskTemplate | ValidationTaskTemplate[] | string,
  patternsOrOptions?: ValidationTaskTemplate | ValidationTaskTemplate[] | ValidationSetupOptions,
  options?: ValidationSetupOptions): PropertyDecorator
{
  const hasName = typeof patternsOrName === "string";
  const name = hasName ? patternsOrName : ValidatorPool.DEFAULT;
  const patterns = hasName ?
    patternsOrOptions as ValidationTaskTemplate | ValidationTaskTemplate[] : patternsOrName;
  options = hasName ? options : patternsOrOptions as ValidationSetupOptions;

  return (target, key): void => decorate(target, key, name, patterns || [], options);
}

/**
 * Add the `ValidateChildTask` to a specified validator runner.
 * @param name Name of the runner to add the pattern. Default `"child"`
 */
export function ValidateChild(name?: string | string[]): PropertyDecorator {
  const names = Array.isArray(name) ? name : [ name || "child" ];

  return (target, key) => {
    const pattern = new ValidateChildTask().configure();

    for (let i = 0; i < names.length; i++)
      decorate(target, key, names[i], pattern);
  };
}
