import { ContextOptions, RunnerStoppedInfo } from "../interfaces";
import { ProtoEventEmitter } from "../misc";

export class TaskContext<T = any, O = any, TOptions extends ContextOptions = {}, TSkip = unknown>
  extends ProtoEventEmitter
{
  private stopped?: RunnerStoppedInfo<TSkip>;
  public output?: O;

  constructor(public payload: T, public options?: TOptions) { super(); }

  /**
   * Set the skipped stage name for `getSkippedInfo`.
   */
  private setStoppedStage(stage: string): void {
    (this.stopped || (this.stopped = {})).stage = stage;
  }

  override on(event: "error", listener: (error: unknown) => any | Promise<any>): this
  override on(event: "stopped", listener: (stage: string) => any | Promise<any>): this
  override on(event: string, listener: (...args: any[]) => any | Promise<any>): this
  override on(event: string, listener: (...args: any[]) => any | Promise<any>): this {
    return super.on(event, listener);
  }

  override emit(event: "error", error: unknown): this
  override emit(event: "stopped", stage: string): this
  override emit(event: string, ...args: any[]): this
  override emit(event: string, ...args: any[]): this {
    if (event === "stopped" && typeof args[0] === "string")
      this.setStoppedStage(args[0]);

    return super.emit(event, ...args);
  }

  /**
   * Get the context options or throw an error.
   */
  requireOptions(): TOptions {
    if (!this.options) throw new Error("Context options was not specified");
    return this.options;
  }

  /**
   * Stop the execution of the runner.
   * @param data Custom data to be used in `getStoppedInfo`.
   */
  stop(data?: TSkip): void {
    this.stopped = { data };
  }

  isStopped(): boolean { return !!this.stopped; }

  getStoppedInfo(): RunnerStoppedInfo<TSkip> | undefined { return this.stopped; }

  /**
   * @deprecated Use `on("error")` instead.
   */
  addOnErrorListener(listener: (error: unknown) => any | Promise<any>): this {
    return this.on("error", listener);
  }

  /**
   * @deprecated Use `removeListener("error", fn)` instead.
   */
  removeOnErrorListener(listener: (error: unknown) => any | Promise<any>): this {
    return this.removeListener("error", listener);
  }

  /**
   * @deprecated Use `emit("error")` instead.
   */
  callOnErrorListeners(error: unknown): void {
    this.emit("error", error);
  }
}
