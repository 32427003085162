import { ProtoPartialRecord } from "src/@prisma/js-proto";

export interface IMessagePayload {
  id?:             string,
  templateId?:     string;
  type?:           number;
  groupId?:        string;
  deviceSerial?:   string;
  templateParams?: ProtoPartialRecord<string, string | number>;

  /** TODO : review this field. I added it because the pipeline failed */
  userId?: string
}
