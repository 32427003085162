import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from "@angular/router";
import { Permission } from "src/@prisma/js-common";
import { Exception } from "src/@prisma/js-utils";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { UserService } from "src/app/services/user.service";

export interface GetUserOptions {
  force?: boolean;
}

export interface GuardPath {
  path:         string | RegExp | (string | RegExp)[],
  permissions?: Permission | Permission[],
  navigate?:    string
}

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {
  private static guardPaths: GuardPath[] = [];

  constructor (
    private router:       Router,
    private userService:  UserService,
    private feed:         MatFeed
  ) {}

  async canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const url = state.url;
    const guardPaths: GuardPath[] = [];

    for (const path of AuthGuard.guardPaths) {
      if (AuthGuard.test(path.path, url)) {
        guardPaths.push(path);
      }
    }

    if (!guardPaths.length) {
      return true;
    }

    try {
      for (const guardPath of guardPaths) {
        if (!(await this.userService.canActivate(guardPath.permissions))) {
          throw new Exception("Invalid user permissions", "E_ACCESS");
        }
      }
      return true;
    } catch (e) {
      if (e instanceof Exception) {
        if (e.code === "E_ACCESS") {
          if (this.userService.isLogged) {
            this.feed.warn/*@i18n*/("Permission denied");
          }
          await this.userService.logout();
          return false;
        }
      } else {
        await this.router.navigate([ "/auth" ]);
      }
      console.error(e);
      return false;
    }
  }

  async canActivateChild(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      return await this.canActivate(_, state);
    } catch (e) {
      return false;
    }
  }

  /**
   * Define protected paths
   * @param paths - path list
   */
  static setGuardedPaths(paths?: GuardPath[]): void {
    this.guardPaths = Array.isArray(paths) ? paths : [];
  }


  /**
   * Test URL with patterns
   * @param pattern - Paths protected
   * @param url - Url for verification
   */
  private static test(pattern: string | RegExp | (string | RegExp)[], url: string): boolean {
    if (typeof pattern === "string") {
      if (pattern === url) {
        return true;
      }
    } else if (pattern instanceof RegExp) {
      if (pattern.test(url)) {
        return true;
      }
    } else if (Array.isArray(pattern)) {
      for (const p of pattern) {
        if (this.test(p, url)) {
          return true;
        }
      }
    }

    return false;
  }
}
