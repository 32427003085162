import { AbstractControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { Bulk, ConstructableNode, ModelNode } from "../../js-proto";
import { DeepPartial, toArray } from "../../js-utils";
import { FormArrayModel } from "./form-array-model";
import { FormControlModel } from "./form-control-model";
import { ForcedControls, IFormModel, FormModelOptions, FormModel, FormModelComponents, NonArray, FCDefaultGroup } from "./interfaces";

export class FormGroupModel<
  Model extends object = any,
  FC extends ForcedControls<NonArray<Model>> = FCDefaultGroup<Model>>
  extends FormGroup implements IFormModel
{
  private subscription = new Subscription();

  readonly node:  ModelNode<Model>;

  get parentGroup(): FormGroupModel | null {
    let parent: FormGroupModel | FormArrayModel | null = this.parent;
    while (parent instanceof FormArrayModel && parent)
      parent = parent?.parent || null;

    return parent;
  }
  get parentArray(): FormArrayModel | null {
    let parent: FormGroupModel | FormArrayModel | null = this.parent;
    while (parent instanceof FormGroupModel && parent)
      parent = parent?.parent || null;

    return parent;
  }

  override controls!:       FormModelComponents<Model, FC>;
  override readonly value!: DeepPartial<Model>;
  override get parent(): FormGroupModel | FormArrayModel | null {
    return super.parent as any;
  }
  override get root(): FormGroupModel | FormArrayModel {
    return super.root as any;
  }

  constructor(
    seed: ConstructableNode<Model> | Model | Bulk<Model> | ModelNode<Model>,
    opts: FormModelOptions<Model, FC> = {}
  ) {
    const model = typeof seed === "function" ? new seed() : seed instanceof Bulk ?
      seed.at(0)?.value || new seed.type() : seed instanceof ModelNode ?
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        seed.value || new seed.typeObject!() : seed;

    const type = model.constructor as ConstructableNode<Model>;
    const node = seed instanceof ModelNode ? seed : ModelNode.fromType<Model>(type, model);

    const root = opts?._root !== false;
    opts._root = false;

    super(FormGroupModel.build(node, opts));
    this.node = node;
    if (root) this.initValidators();
  }

  static from<
    Model extends object,
    FC extends ForcedControls<NonArray<Model>> = {
      controls: [], children: ForcedControls<NonArray<Model>>["children"]
    }
  >(
    ...args: ConstructorParameters<typeof FormGroupModel<Model, FC>>): FormGroupModel<Model, FC> {
    return new FormGroupModel(...args);
  }

  private static build<Model extends object, FC extends ForcedControls<Model>>(
    node:    ModelNode<Model>,
    opts:    FormModelOptions<any, any> = {}
  ): FormModelComponents<Model, FC>
  {
    const components: { [key: string]: AbstractControl } = {};
    const model = node.value;
    const value = opts.value ??= (opts.useDefaultValues !== false ? model : void 0);
    opts.useDefaultValues = false;
    const cascade = opts.cascade;

    opts.ignore = toArray(opts.ignore || []).slice();
    if (node.typeObject) opts.ignore.push(node.typeObject);

    for (const field of node.getAbstractChildren()) {
      const name = field.parentRef as (keyof Model & string) | undefined;
      if (!name) continue;

      const nestedOpts: FormModelOptions<any, any> = {
        ...opts,
        forceControls: opts.forceControls?.children?.[name],
        value: value?.[name],
        cascade: typeof cascade === "object" ?
          cascade[name] : cascade
      };

      if (opts.forceControls?.controls?.includes(name))
        components[name] = new FormControlModel(field, nestedOpts);
      else {
        const control = this.buildChild(field, nestedOpts);
        if (control) components[name] = control;
      }
    }

    return components as any;
  }


  private static buildChild<T>(
    node: ModelNode<T>,
    opts: FormModelOptions<T, any> = {}): FormModel | null
  {
    const cascade = opts.cascade;
    const type = node.typeObject;

    opts.ignore = toArray(opts.ignore || []).slice();

    if (type && (opts.ignore.includes(type) || !cascade)) return null;

    if (node.array) {
      return new FormArrayModel(node as ModelNode, opts as FormModelOptions<T & any[]>);
    } else if (type) {
      return new FormGroupModel(node, opts as FormModelOptions<T & object>);
    }

    return new FormControlModel(node, opts);
  }

  async getBulk(): Promise<Bulk<Model>> {
    return await new Bulk(this.node.type as ConstructableNode<Model>).import(this.value as any);
  }

  removeFromArray(): boolean {
    if (this.parent instanceof FormArrayModel) return this.parent.remove(this);
    return false;
  }

  initValidators(): this {
    for (const key in this.controls) this.controls[key].initValidators();
    return this;
  }

  watch(cb: (value: Model) => any): Subscription
  watch<T extends string | (string | number)[]>(field: T, cb: (value: any) => any): Subscription
  watch(
    fieldOrCb: string | (string | number)[] | ((value: any) => any),
    cb?: (value: any) => any
  ): Subscription | undefined
  {
    if (typeof fieldOrCb !== "function") return cb && this.get(fieldOrCb)?.watch(cb);

    cb = fieldOrCb;
    const subs = this.valueChanges?.subscribe(cb);
    this.subscription.add(subs);
    return subs;
  }

  destroy(): void {
    for (const key in this.controls) {
      (this.controls[key] as IFormModel).destroy();
    }

    this.subscription.unsubscribe();
  }

  getMask(path: string | (string | number)[]): string {
    const form = this.get(path);
    return form instanceof FormControlModel && form.getMask() || "";
  }

  hasMask(path: string | (string | number)[]): boolean {
    const form = this.get(path);
    return form instanceof FormControlModel && !!form.hasMask();
  }

  override get(path: string | (string | number)[]): FormModel | null {
    return super.get(path) as any;
  }

  override getError(path?: string | (string | number)[]): string | undefined {
    return super.getError("message", path);
  }

  override removeControl<S extends string>(name: keyof Model & S, options?: {
    emitEvent?: boolean; destroy?: boolean
  }): void {
    if (options?.destroy !== false) this.get(name)?.destroy();
    super.removeControl(name, options);
  }
}
