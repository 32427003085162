const URL_ESCAPE_PREFIX     = "%";
const LITERAL_ESCAPE_PREFIX = "$";

const SPECIAL_CHARS: Readonly<Record<string, string>> = {
  " ":      "20",
  "<":      "3C",
  ">":      "3E",
  "#":      "23",
  "%":      "25",
  "\\+":    "2B",
  "{":      "7B",
  "}":      "7D",
  "\\|":    "7C",
  "\\^":    "5E",
  "~":      "7E",
  "\\[":    "5B",
  "\\]":    "5D",
  "`":      "60",
  ";":      "3B",
  "\\/":    "2F",
  "\\?":    "3F",
  ":":      "3A",
  "@":      "40",
  "=":      "3D",
  "&":      "26",
  "\\$":    "24",
  "\\\\":   "5C"
};

export interface EncodeURIOptions {
  literal?: boolean
}

export function encodeQueryURI(uri: string, opts?: EncodeURIOptions): string {
  const prefix = opts?.literal ? LITERAL_ESCAPE_PREFIX : URL_ESCAPE_PREFIX;

  for (const key in SPECIAL_CHARS) {
    const code = prefix + SPECIAL_CHARS[key];
    uri = uri.replace(new RegExp(key, "i"), code);
  }
  return uri;
}

export function decodeQueryURI(uri: string, opts?: EncodeURIOptions): string {
  const prefix = opts?.literal ? LITERAL_ESCAPE_PREFIX : URL_ESCAPE_PREFIX;

  for (const key in SPECIAL_CHARS) {
    const code = prefix + SPECIAL_CHARS[key];
    uri = uri.replace(new RegExp(code, "i"), key);
  }
  return uri;
}

function getQueryParams(
  value: Record<string, any> | string | number,
  initKey = "",
  stackCount = 0,
  opts = { encodeURI: encodeURI, encodeURIComponent: encodeURIComponent }
): string
{
  if (stackCount > 100) throw new Error("Maximum call stack size exceeded (100)");

  if (value === null || value === void 0) return initKey ? `${initKey}=` : "";
  if (typeof value === "string" || typeof value === "number")
    return `${initKey}=${opts.encodeURIComponent(value)}`;

  if (Array.isArray(value)) {
    const arr = [] as string[];

    for (let i = 0; i < value.length; i++)
      arr.push(getQueryParams(value[i], `${initKey}[${i}]`, stackCount + 1));

    return arr.join("&");
  }

  if (typeof value.toJSON === "function") return `${initKey}=${opts.encodeURIComponent(value.toJSON())}`;
  if (value.toString !== ({}).toString) return `${initKey}=${opts.encodeURIComponent(value.toString())}`;

  const nObj: string[] = [];
  for (const key in value) {
    nObj.push(
      getQueryParams(value[key], initKey ?
        `${initKey}[${opts.encodeURI(key)}]` : opts.encodeURI(key), stackCount + 1)
    );
  }

  return nObj.filter(e => e).join("&");
}

/**
 * Generate query params from a json.
 */
export function param(obj: Record<string, any>, opts?: { encode?: boolean }): string {
  return getQueryParams(obj, void 0, void 0, opts?.encode === false ? {
    encodeURI: e => e,
    encodeURIComponent: e => e.toString()
  } : void 0);
}
