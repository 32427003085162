import { Directive, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConstructableNode, MetadataManager, ModelMetadata } from "src/@prisma/js-proto";
import { Exception } from "src/@prisma/js-utils";
import { ForcedControls, FormGroupModel, FormModelOptions, NonArray } from "src/@prisma/ng-forms";
import { NgI18nGetter } from "src/@prisma/ng-i18n";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { NgRunnerOptions } from "src/@prisma/ng-runner";
import { DefaultService } from "../services/default.service";

@Directive()
export abstract class DefaultCreateComponent<
  Model extends object,
  Service extends DefaultService<Model> = DefaultService<Model>,
  FC extends ForcedControls<NonArray<Model>> = {
    controls: [], children: ForcedControls<NonArray<Model>>["children"]
  }
> extends NgI18nGetter implements OnDestroy {
  item?: Model;

  inProcess = false;
  form!: FormGroupModel<Model, FC>;
  model: ConstructableNode<Model>;

  protected feed:   MatFeed;

  editing;

  constructor(
    public    service: Service,
    protected router:  Router,
    protected route:   ActivatedRoute,
    protected opts: {
      createText:    string
      updateText:    string
      deleteText:    string
      formConfig?:   FormModelOptions<Model, FC>
      runnerConfig?: NgRunnerOptions<Model>
    }
  ) {
    super();

    this.model = this.service.model;
    this.feed = this.service.feed;

    const itemId = this.route.snapshot.queryParams.id;
    this.editing = !!itemId;
    this.formInit(itemId);
  }

  ngOnDestroy(): void {
    this.form.destroy();
  }

  async formInit(itemId?: string): Promise<void> {
    this.form = new FormGroupModel(this.model, this.mergeOpts("create"));

    if (itemId) {
      try {
        const primaryColumn = MetadataManager.get(this.model, ModelMetadata)?.primaryColumn as keyof Model;
        this.item = (await this.service.get(<NgRunnerOptions<Model>>{
          filter: { [primaryColumn]: itemId },
          ...this.opts.runnerConfig
        }))[0];
        if (!this.item) throw new Exception/*@i18n*/("Item not found.", "");

        this.form.destroy();
        this.form = new FormGroupModel(this.item, this.mergeOpts("update"));
      } catch (e) {
        this.back();
      }
    }
  }

  back(): void {
    this.router.navigate([ this.router.url.replace("/form", "/list") ]);
  }

  async save(): Promise<boolean> {
    try {
      this.inProcess = true;

      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();

      if (this.form.invalid) throw new Exception(
        /*@i18n*/("Invalid fields"),
        ""
      );

      if (this.item) {
        await this.service.update(this.form.value);
        this.back();
        this.feed.success(this.opts.updateText);
      } else {
        await this.service.create(this.form.value);
        this.reset();
        this.feed.success(this.opts.createText);
      }

      return true;
    } catch (e) {
      this.feed.fromError(e);
      return false;
    } finally {
      this.inProcess = false;
    }
  }

  reset(): void {
    this.form.destroy();
    this.formInit();
  }

  async delete(): Promise<void> {
    if (this.item) this.service.deleteDialog(this.item, this.back.bind(this));
  }

  private mergeOpts(event: "update" | "create"): FormModelOptions<Model, FC> {
    return Object.assign(<FormModelOptions<Model, FC>>{
      event,
      cascade: {},
      useDefaultValues: true
    }, this.opts.formConfig);
  }
}
