import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PagesRoutingModule } from "./pages-routing.module";
import { ConfirmDialogModule } from "../utils/confirm-dialog/confirm-dialog.module";
import { ThemeModule } from "../theme/theme.module";
import { PagesComponent } from "./pages.component";
import { DefaultHeaderModule } from "../utils/default-header/default-header.module";


@NgModule({
  declarations: [
    PagesComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    ConfirmDialogModule,
    ThemeModule,
    DefaultHeaderModule
  ]
})
export class PagesModule { }
