import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Permission, permissions } from "src/@prisma/js-common";
import { toArray } from "src/@prisma/js-utils";
import { __ } from "src/@prisma/ng-i18n";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { ApiConfig, Cache, NgEventPool, NgRunnerOptions } from "src/@prisma/ng-runner";
import { User } from "src/models/user.model";
import { DefaultService, ItemsParameters } from "./default.service";

@Injectable({
  providedIn: "root"
})
export class UserService extends DefaultService<User> {
  private logoutListeners: (() => void | Promise<void>)[] = [];
  private logged = false;

  get isLogged(): boolean {
    return this.logged;
  }

  constructor(
    private cache:  Cache,
    private api:    ApiConfig,
    private router: Router,
    pool:   NgEventPool,
    feed:   MatFeed,
    dialog: MatDialog
  ) {
    super(User, pool, feed, dialog, {
      name:        /*@i18n*/("User"),
      pluralName:  /*@i18n*/("Users"),
      deleteError: /*@i18n*/("No Users selected"),
      deleteName:  (item) => __("the user \"%s\"", item.name.toUpperCase())
    });
  }

  async canActivate(target: Permission | Permission[] = []): Promise<boolean> {
    return this.self().then(user => {
      target = toArray(target);
      return user && (!target.length || !!user?.role && permissions.verify(target, [ user.role ]));
    }).catch(e => {
      console.error(e);
      return false;
    });
  }

  async self(opts?: NgRunnerOptions<User>): Promise<User> {
    const user = await this.pool.run<User[]>("self", User, {
      import: true,
      ...opts
    });
    return user[0].also(it => this.logged = !!it);
  }

  addLogoutListener(callback: () => void | Promise<void>): { unregister: () => void } {
    this.logoutListeners.push(callback);
    return {
      unregister: (): void => {
        this.logoutListeners.remove(callback);
      }
    };
  }

  /**
   * Remove token and clear user cache
   */
  async logout(): Promise<void> {
    this.cache.clear();
    await Promise.all([
      this.api.removeToken(),
      this.router.navigate([ "/auth" ])
    ]);
    this.logged = false;
    await Promise.all(this.logoutListeners.map(async cb => cb()));
    this.dialog.closeAll();
  }

  async password(item: ItemsParameters<User>, opts?: NgRunnerOptions<User>): Promise<void> {
    return await this.pool.run("password", this.toBulk(item), { singleBodyData: true, ...opts });
  }
}
