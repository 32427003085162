import { ValidationContext } from "../interfaces";
import { ValidationTaskTemplate } from "./task-template";

export class ValidateEnumTask extends ValidationTaskTemplate {
  protected valid: any[] = [];

  override configure(valid: any[]): this {
    return (this.valid = valid, this);
  }

  async validate(context: ValidationContext): Promise<void> {
    const value = context.payload;
    if (value === null || value === void 0) return;

    if (Array.isArray(value))
      for (let i = 0; i < value.length; i++) {
        if (!this.valid.includes(value[i]))
          throw this.getFieldError();
      }
    else if (!this.valid.includes(value))
      throw this.getFieldError();
  }
}
