import { ValidationContext } from "../interfaces";
import { ValidationTaskTemplate } from "./task-template";

export class ValidateRegexTask extends ValidationTaskTemplate {
  protected regex!: RegExp;

  override configure(regex: RegExp): this {
    return (this.regex = regex, this);
  }

  override async validate(context: ValidationContext): Promise<void> {
    const value = context.payload;

    if (value === null || value === void 0) return;

    if (typeof value !== "string" || !new RegExp(this.regex).test(value))
      throw this.getFieldError();
  }
}
