import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { MatFeedConfig } from "./interfaces";
import { MatFeed, MAT_FEED_CONFIG } from "./mat-feed.service";

@NgModule({
  imports: [
    MatSnackBarModule
  ],
  providers: [
    MatFeed
  ]
})
export class MatFeedModule {
  static forRoot(config?: MatFeedConfig): ModuleWithProviders<MatFeedModule> {
    return {
      ngModule: MatFeedModule,
      providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: Object.assign({
          verticalPosition: "top",
          horizontalPosition: "end",
          duration: 2500
        }, config?.default) },
        { provide: MAT_FEED_CONFIG, useValue: config }
      ]
    };
  }
}
