import { Injectable, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ThemeModule } from "./theme/theme.module";
import { NgI18n, NgI18nConfigModule, NgI18nModule, __ } from "src/@prisma/ng-i18n";
import { MatFeedModule } from "src/@prisma/ng-mat-feed";
import { NgRunnerModule } from "src/@prisma/ng-runner";
import { NgxMaskModule } from "ngx-mask";
import { ngMaskConfig } from "src/@prisma/ng-forms";
import { environment } from "src/environments/environment";
import { I18nDatePickerModule } from "./utils/i18n-date-picker.module";
import { MatPaginatorDefaultOptions, MatPaginatorIntl, MAT_PAGINATOR_DEFAULT_OPTIONS } from "@angular/material/paginator";
import { MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from "@angular/material/tooltip";
import { MatTabsConfig, MAT_TABS_CONFIG } from "@angular/material/tabs";

import en = require("src/locales/en.json");
import pt = require("src/locales/pt.json");


@Injectable()
export class MatPaginatorIntlNgI18n extends MatPaginatorIntl {
  constructor(
    ngi18n: NgI18n
  ) {
    super();
    this.applyLabels();
    ngi18n.setOnSwitch(() => {
      this.applyLabels();
    });
  }

  applyLabels(): void {
    this.itemsPerPageLabel = __("Items per page");
    this.firstPageLabel    = __("First page");
    this.lastPageLabel     = __("Last page");
    this.nextPageLabel     = __("Next page");
    this.previousPageLabel = __("Previous page");
  }

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    return __("Page %d - %d\nTotal: %d items", page + 1, Math.ceil(length / pageSize), length);
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(ngMaskConfig({
      patterns: {
        L: { pattern: /[a-z0-9]/i },
        M: { pattern: /[#0]/ }
      }
    })),
    NgRunnerModule.forRoot({
      baseUrl: environment.baseUrl
    }),
    MatFeedModule.forRoot(),
    NgI18nConfigModule.forRoot({
      locales: [
        { name: "pt-BR", dict: pt },
        { name: "pt",    dict: pt },
        { name: "en-US", dict: en },
        { name: "en",    dict: en }
      ],
      defaultLocale: "pt"
    }),
    NgI18nModule,
    ThemeModule,
    I18nDatePickerModule
  ],
  providers: [
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: <MatPaginatorDefaultOptions>{
        formFieldAppearance: "fill",
        pageSizeOptions:     [ 5, 10, 25, 50 ],
        showFirstLastButtons: true
      }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: <MatDialogConfig<any>>{
        autoFocus: false,
        disableClose: true,
        hasBackdrop: true,
        maxWidth: "90%"
      }
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: <MatTabsConfig>{
        animationDuration: "0s"
      }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: <MatTooltipDefaultOptions>{
        position: "above"
      }
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: <MatDateFormats>{
        parse: {
          dateInput: ["l", "LL"],
        },
      },
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlNgI18n },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "fill" } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
