import { ValidationContext } from "../interfaces";
import { ValidationTaskTemplate } from "./task-template";

export class ValidateMaxTask extends ValidationTaskTemplate {
  protected threshold = 0;

  override configure(threshold: number): this {
    return (this.threshold = threshold, this);
  }

  override async validate(context: ValidationContext): Promise<void> {
    if (
      (typeof context.payload === "string" && context.payload.length > this.threshold) ||
      (typeof context.payload === "number" && context.payload > this.threshold)
    )
      throw this.getFieldError();
  }
}
