import { FormatContextOptions } from "../interfaces";
import { TaskContext } from "../runner";
import { FormatTaskTemplate } from "./task-template";

export class FormatGenericTask extends FormatTaskTemplate {
  private cb!: (e: any, context: TaskContext<any, any, FormatContextOptions>) => any;

  override configure(cb: (e: any, context: TaskContext<any, any, FormatContextOptions>) => any): this {
    this.cb = cb;
    return this;
  }

  override format(value: any, context: TaskContext<any, any, FormatContextOptions>): any {
    return this.cb(value, context);
  }
}
