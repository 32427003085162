import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { ModelNode, ValidationTaskTemplate, Proto, FieldError, ValidationContextOptions } from "../js-proto";
import { isEmptyObject, PartialRecord } from "../js-utils";

export function valueOrNull<T>(value: T): T | null {
  if (
    (typeof value === "string" && value === "") ||
    (Array.isArray(value) && value.length === 0) ||
    isEmptyObject(value)
  ) return null;
  return value;
}

export function getValidators<Model>(
  node: ModelNode<Model>,
  event?: string[]
): PartialRecord<string, ValidationTaskTemplate[]>
{
  const stages: PartialRecord<string, ValidationTaskTemplate[]> = {};

  event?.forEach(e => {
    const eventStages = node.metadata?.validator.get(e)?.getStages();
    Proto.merge(stages, eventStages || {}, { overwriteArrays: false });
  });

  return stages;
}

export function parseValidator(
  pattern: ValidationTaskTemplate,
  opts?: ValidationContextOptions): AsyncValidatorFn
{
  return async (field: AbstractControl) => {
    try {
      await pattern.execute(valueOrNull(field.value), { ...opts, model: opts?.extras?.value });
      return null;
    } catch (e: unknown) {
      if (e instanceof FieldError) return e;
      return null;
    }
  };
}
