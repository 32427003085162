import { AfterUnserialize, Constructable, FieldType, SimpleFieldType } from "../interfaces/index";
import { ModelNode } from "./node";
import { Proto } from "./proto";
import { NodeFunctionIterator } from "./serializable";

export class UnserializableContext<T> {
  public readonly node: ModelNode<T>;

  constructor(
    public readonly value: any,
    public readonly type?: FieldType | Constructable<T>,
    public readonly parent?: { node: ModelNode, ref: string }
  ) {
    this.node = this.initNode();
  }

  private initNode(): ModelNode<T> {
    const parent = this.parent?.node;
    const ref    = this.parent?.ref;

    if (typeof this.type === "function") {
      if (parent)
        return parent.grow(new this.type(), ref, { undefined: this.value === void 0 });
      else if (Array.isArray(this.value))
        return ModelNode.fromType<any>(this.type, this.value);
      else
        return new ModelNode(new this.type(), { undefined: this.value === void 0 });
    }

    if (Array.isArray(this.value))
      return parent ? parent.grow<any>([], ref) : new ModelNode<any>([]);

    return parent ? parent.grow(this.value, ref) : new ModelNode(this.value);
  }

  /**
   * Execute all children `afterUnserialize` callbacks and then run current node callback.
   */
  private async afterUnserialize(value: T | T[] | undefined): Promise<void> {
    if (!value || !this.node.typeObject) return;

    await new NodeFunctionIterator<AfterUnserialize>(
      ModelNode.fromType<T[] | T>(this.node.typeObject, value), "afterUnserialize").iterate();
  }

  /**
   * Digest each element, recursively, if value is an array.
   */
  private async digestArray(): Promise<T> {
    const arr: any[] = [];

    if (Array.isArray(this.value))
      for (let i = 0; i < this.value.length; i++) {
        const item   = this.value[i];
        const result = await new UnserializableContext(item, this.type).digestValue();
        if (result !== void 0) arr.push(result);
      }

    return arr as any;
  }

  /**
   * Digest all fields, recursively, if value is an object with metadata.
   */
  private async digestObject(): Promise<T | undefined> {
    if (typeof this.value !== "object" || Array.isArray(this.value) || this.value === null) return;

    for (let i = 0, children = this.node.getChildren(); i < children.length; i++) {
      const childNode = children[i];

      if (childNode.parentRef) {
        let value = childNode.alias !== void 0 ?
          this.value[childNode.alias] : this.value[childNode.parentRef];

        if (value === void 0 && childNode.alias !== void 0)
          value = this.value[childNode.parentRef];

        const result = await new UnserializableContext(value, childNode.type, {
          node: this.node,
          ref: childNode.parentRef
        }).digest();

        if (result !== void 0)
          this.node.value[childNode.parentRef as keyof T] = result;
      }
    }

    return this.node.value;
  }

  /**
   * Digest value ignoring validations such: `array`, `json` and `nullable`.
   * `digestObject` will be called if type is a function.
   */
  private async digestValue(): Promise<T | undefined> {
    if (typeof this.type === "function") return await this.digestObject();

    if (
      this.type === void 0 ||
      (typeof this.type === "string" && typeof this.value !== this.type) ||
      (Array.isArray(this.type) && !this.type.includes(typeof this.value as SimpleFieldType))
    ) {
      return;
    }

    return this.value;
  }

  /**
   * Unserialize value into the specified type.
   *
   * * `afterUnserialize` callbacks will only be called when digesting the root node.
   */
  async digest(): Promise<T | T[] | undefined> {
    const unserialize = this.getUnserializeMethod();
    if (unserialize) return await unserialize(this);

    if (this.node.exclude) return;

    if (this.value === null) {
      return this.node.nullable ? null as any : void 0;
    }

    if (this.node.json)  return Proto.isSerializable(this.value) ? this.value as any : void 0;

    let result: T | T[] | undefined;

    if (this.node.array)
      result = !this.node.undefined ? await this.digestArray() : void 0;
    else
      result = await this.digestValue();

    if (this.node.isRoot()) await this.afterUnserialize(result);

    return result;
  }

  /**
   * Get custom `unserialize` function defined in `fieldOptions`.
   */
  getUnserializeMethod(): ((node: UnserializableContext<T>) => T | Promise<T>) | undefined {
    return this.node.fieldOptions?.unserialize;
  }
}
