import { Injectable } from "@angular/core";
import { Runner, Bulk } from "src/@prisma/js-proto";
import { RunnerPool } from "src/@prisma/js-proto/runner/pool";
import { NgRunnerOptions } from "../interface";
import { BodyBuilderTask, BulkFormatTask, HttpTask, BulkImportTask, BulkValidateTask, GetCacheTask, EmitEventTask, ParseParamsTask, PreRequestTask, RequestTask, TokenStoreTask, ClearCacheTask } from "../tasks";
import { NgTaskContext } from "../tasks/task.context";

@Injectable()
export class NgDefaultRunner extends Runner<Bulk, Promise<any>, NgRunnerOptions, string, NgTaskContext> {
  readonly RUNNER?: string;
  get MAP(): Record<string, this> {
    return { [this.RUNNER || RunnerPool.DEFAULT]: this };
  }

  constructor(
    protected bodyBuilder:  BodyBuilderTask,
    protected bulkFormat:   BulkFormatTask,
    protected bulkHttp:     HttpTask,
    protected bulkImport:   BulkImportTask,
    protected bulkValidate: BulkValidateTask,
    protected getCache:     GetCacheTask,
    protected clearCache:   ClearCacheTask,
    protected emitEvent:    EmitEventTask,
    protected parseParams:  ParseParamsTask,
    protected preRequest:   PreRequestTask,
    protected request:      RequestTask,
    protected tokenStore:   TokenStoreTask
  ) {
    super({
      ...bodyBuilder.MAP,
      ...bulkFormat.MAP,
      ...bulkHttp.MAP,
      ...bulkImport.MAP,
      ...bulkValidate.MAP,
      ...getCache.MAP,
      ...clearCache.MAP,
      ...emitEvent.MAP,
      ...parseParams.MAP,
      ...preRequest.MAP,
      ...request.MAP,
      ...tokenStore.MAP
    });

    this.setDefaultContext(NgTaskContext);
  }

  override async executeContext(context: NgTaskContext<any>, stages?: string[]): Promise<NgTaskContext<any>> {
    try {
      await super.executeContext(context, stages);
    } catch (e) {
      // avoid double throws
    }

    return context;
  }
}
