import { MetadataManager } from "src/@prisma/js-proto";
import { NgMaskOptions } from "./interfaces";
import { NgMask } from "./mask.metadata";

/**
 * Set mask on field
 * @param optsOrPattern Options for mask
 */
export function Mask(
  optsOrPattern: NgMaskOptions | string | null | ((model: any) => string)
): PropertyDecorator {
  return (target, key) => {
    const meta = NgMask.get(target);
    const fields = meta.fields;

    if (typeof optsOrPattern === "string" || typeof optsOrPattern === "function")
      optsOrPattern = { pattern: optsOrPattern };

    fields[key as keyof typeof target] = optsOrPattern || {};

    MetadataManager.set(target, meta);
  };
}

