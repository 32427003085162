import { Component, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { MessageTypes } from "src/@prisma/js-common";
import { __ } from "src/@prisma/ng-i18n";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { NgRunnerOptions } from "src/@prisma/ng-runner";
import { CacheManager } from "src/@prisma/ng-utils";

import { MessageService } from "src/app/services/message.service";
import { UserService } from "src/app/services/user.service";
import { ConfirmDialogComponent } from "src/app/utils/confirm-dialog/confirm-dialog.component";
import { DefaultDialogComponent } from "src/app/utils/default-dialog/default-dialog.component";
import { Message } from "src/models/message.model";
import { User } from "src/models/user.model";
import { ConfirmDialogData, DefaultDialogOptions, TableField } from "src/utils/interfaces";
import { MESSAGE_TYPE_RECORD } from "src/utils/records";
import { formatDateTime } from "src/utils/util";

import { DetailsComponent } from "../details/details.component";

@Component({
  selector: "message-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"]
})
export class ListComponent {
  private cache = new CacheManager();
  private self?: User;

  @ViewChild("actions", { read: TemplateRef })
  private template!: TemplateRef<unknown>;

  runnerOptions: NgRunnerOptions<Message> = {
    filter: {
      fulfilledAt: { $null: true }
    },
    filterOptions: {
      relations: ["createdByUser", "user", "group"]
    }
  };

  fields: TableField<Message>[] = [
    {
      key: "title",
      label: __("Title"),
      get: (message: Message): string => message.title?.toUpperCase() || "-"
    },
    {
      key: "type",
      label: __("Priority"),
      get: (message: Message): string => __(MESSAGE_TYPE_RECORD[message.type as MessageTypes] ?? "-")
        .toUpperCase(),
      className: (message: Message): string[] => [
        {
          [MessageTypes.INFO]: "text-success",
          [MessageTypes.WARN]: "text-warn",
          [MessageTypes.ALARM]: "text-danger"
        }[message.type as MessageTypes],
        "priority"
      ]
    },
    {
      key: "createdAt",
      label: __("Created at"),
      get: (message: Message): string => message.createdAt ? formatDateTime(message.createdAt) : "-",
      date: true
    },
    {
      key: "templateParams",
      label: __("Parameters"),
      get: (message: Message): string => {
        const obj = message.templateParams;
        let formattedText = "";

        const sortedKeys = (Object.keys(obj) as Array<keyof typeof obj>).sort((k1, k2) => {
          if (k1 < k2) return -1;
          else if (k1 > k2) return 1;
          else return 0;
        });

        for (const element of sortedKeys) {
          formattedText += element +  ": " + obj[element] + "\n";
        }

        return formattedText;
      }
    },
    // {
    //   key: "confirmed",
    //   label: __("Confirmed at"),
    //   get: (message: Message): string => message.confirmedAt ? formatDateTime(message.confirmedAt) : "-",
    //   date: true
    // },
    {
      key: "actions",
      label: __("Actions"),
      get: (): TemplateRef<unknown> => this.template,
      sortable: false
    }
  ];

  constructor(
    public  service:      MessageService,
    private dialog:       MatDialog,
    private feed:         MatFeed,
    userService:          UserService
  ) {
    userService.self().then(user => this.self = user);
  }

  showDetails(row: Message): void {
    const dialog = this.dialog.open(DefaultDialogComponent, {
      maxWidth: "50vw",
      width: "400px",
      data: <DefaultDialogOptions<Message>>{
        component: DetailsComponent,
        icon: "apps",
        title: /*@i18n*/("Details"),
        data: row,
        btnClose: () => {
          dialog.close();
        }
      }
    });
  }

  confirmReceipt(row: Message): void {
    const dialog = this.dialog.open(DefaultDialogComponent, {
      data: <DefaultDialogOptions>{
        title: /*@i18n*/("Confirm supply"),
        icon: "help_outline",
        component: ConfirmDialogComponent,
        data: <ConfirmDialogData>{
          text: /*@i18n*/("Do you really want to confirm the supply?"),
          btnText: {
            yes:  /*@i18n*/("Confirm"),
            no:   /*@i18n*/("Cancel")
          },
          btnColor: {
            yes: "accent"
          },
          confirm: async () => {
            try {
              if (!row?.id) return;
              await this.service.confirm(row.id);
              this.feed.success/*@i18n*/("Supply successfully confirmed");
              dialog.close();
            } catch (e) {
              this.feed.fromError(e);
            }
          },
        }
      }
    });
  }

  get rowClassGetter(): (row: Message) => string[] {
    return this.cache.getCacheOrExecute(this.self, self => {
      return row => {
        return row.createdBy === self?.id ? ["self"] : [];
      };
    });
  }
}
