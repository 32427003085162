export enum AccessLevel {
  ADMIN           = 0,
  READ_ADVANCED   = 1 << 0,
  READ_BASIC      = 1 << 1,
  WRITE_ADVANCED  = 1 << 2,
  WRITE_BASIC     = 1 << 3,
  SEND_MESSAGE    = 1 << 4,
  PAIRED_DEVICE   = 1 << 29,
  DEVICE          = 1 << 30
}

export const PERMISSION_ARRAY: ReadonlyArray<number> = (()=> {
  const permissions: number[] = [];

  for (const key in AccessLevel) {
    const value = AccessLevel[key];
    if (typeof value === "number") permissions.push(value);
  }

  return permissions;
})();
