import { AfterViewChecked, Directive, ElementRef, Input } from "@angular/core";
import { getTextNode, setStyle } from "./functions";

@Directive({
  selector: "[filter]"
})
export class FilterDirective<T extends HTMLElement> implements AfterViewChecked {
  private current?: string | null;

  @Input() filter?: string | null;

  constructor(
    private elementRef: ElementRef<T>
  ) { }

  ngAfterViewChecked(): void {
    if (this.filter !== this.current) {
      this.filterChildren(this.filter || "");
      this.current = this.filter;
    }
  }

  private filterChildren(filter: string): void {
    const children = this.elementRef.nativeElement.children;

    for (let i = 0; i < children.length; i++) {
      const current = children[i];

      if (current instanceof HTMLElement) {
        const text = getTextNode(current);

        if (text?.includes(filter)) {
          setStyle(current, {
            display: current.getAttribute("store-display")
          });
          current.removeAttribute("store-display");
        } else {
          current.setAttribute("store-display", current.style.display);
          setStyle(current, {
            display: "none"
          });
        }
      }
    }
  }
}
