import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ConstructableAny } from "src/@prisma/js-proto";
import { ApiConfig, API_CONFIG } from "./api-config.service";
import { Cache } from "./cache";
import { NgHttpPool, NgEventPool } from "./pools";
import { NgEventAuthRunner, NgEventCreateRunner, NgEventDeleteRunner, NgEventReadRunner, NgEventRunner, NgEventUpdateRunner, NgHttpPostRunner, NgHttpRunner } from "./runners";
import { NgHttpDeleteRunner } from "./runners/http-delete.runner";
import { NgHttpGetRunner } from "./runners/http-get.runner";
import { NgHttpPatchRunner } from "./runners/http-patch.runner";
import { NgHttpPutRunner } from "./runners/http-put.runner";
import { BodyBuilderTask, BulkFormatTask, HttpTask, BulkImportTask, BulkValidateTask, GetCacheTask, EmitEventTask, ParseParamsTask, PreRequestTask, RequestTask, TokenStoreTask, ClearCacheTask } from "./tasks";

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    ApiConfig,
    Cache,
    NgHttpPool,
    NgEventPool,
    NgEventRunner,
    NgEventAuthRunner,
    NgEventCreateRunner,
    NgEventDeleteRunner,
    NgEventReadRunner,
    NgEventUpdateRunner,
    NgHttpRunner,
    NgHttpDeleteRunner,
    NgHttpGetRunner,
    NgHttpPatchRunner,
    NgHttpPostRunner,
    NgHttpPutRunner,
    BodyBuilderTask,
    BulkFormatTask,
    HttpTask,
    BulkImportTask,
    BulkValidateTask,
    GetCacheTask,
    ClearCacheTask,
    EmitEventTask,
    ParseParamsTask,
    PreRequestTask,
    RequestTask,
    TokenStoreTask
  ]
})
export class NgRunnerModule {
  static forRoot(
    config?: Partial<ApiConfig> | ConstructableAny<ApiConfig>
  ): ModuleWithProviders<NgRunnerModule>
  {
    return {
      ngModule: NgRunnerModule,
      providers: [ typeof config === "function" ? {
        provide:  ApiConfig,
        useClass: config
      } : {
        provide:  API_CONFIG,
        useValue: config
      } ]
    };
  }
}
