import { IMessagePayload } from "src/@prisma/js-common";
import { Field, HttpPath, pattern, Validate } from "src/@prisma/js-proto";
import { Mask, NgMask } from "src/@prisma/ng-forms";

@HttpPath("message/registry")
export class MessagePayload implements IMessagePayload {
  @Validate([
    pattern("uuid", /*@i18n*/("Invalid message template_id")),
    pattern("required", /*@i18n*/("Message template not informed"))
  ])
  @Field()
  public templateId!: string;

  @Validate([
    pattern("required", /*@i18n*/("Message type not informed"))
  ])
  @Field()
  public type!: number;

  @Validate([
    pattern("uuid", /*@i18n*/("Invalid message target group"))
  ])
  @Field({ type: "string" })
  public groupId?: string;

  @Validate([
    pattern("uuid", /*@i18n*/("Invalid message target user"))
  ])
  @Field({ type: "string" })
  public userId?: string;

  @Field({ json: true })
  public templateParams: Record<string, string> = {};

  @Validate([
    pattern("required", [ /*@i18n*/("Message target not informed") ])
  ])
  @Field({ json: true, exclude: true })
  public target?: {
    key: "userId" | "groupId"
    value: string
  };

  @Mask(NgMask.maxLength(30))
  @Validate([ pattern("required", [ /*@i18n*/("Bin code not informed") ]) ])
  @Field({ type: "string", exclude: true })
  public itemCode = "";

  @Mask(NgMask.maxLength(30))
  @Validate([ pattern("required", [ /*@i18n*/("Item amount not informed") ]) ])
  @Field({ type: "string", exclude: true })
  public amount = "";

  @Mask(NgMask.maxLength(60))
  @Validate([ pattern("required", [ /*@i18n*/("Station not informed") ]) ])
  @Field({ type: "string", exclude: true })
  public station = "";
}
