import { Injectable } from "@angular/core";
import { BodyBuilderTask, ParseParamsTask, GetCacheTask, PreRequestTask, RequestTask, ClearCacheTask, HttpTask } from "../tasks";
import { NgDefaultRunner } from "./default.runner";

@Injectable()
export class NgHttpRunner extends NgDefaultRunner {
  override RUNNER?: string;

  protected override onInit(): void {
    this.reorder([
      HttpTask.STAGE,
      BodyBuilderTask.STAGE,
      ParseParamsTask.STAGE,
      GetCacheTask.STAGE,
      PreRequestTask.STAGE,
      RequestTask.STAGE,
      ClearCacheTask.STAGE
    ]);
  }
}
