<default-form-container [templateRef]="formTemplate"
  [component]="this" [disableDelete]="disableDeleteMessage">
</default-form-container>

<ng-template #formTemplate>
  <div [formGroup]="form" class="container">
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label><!--@i18n-->Name: *<!----></mat-label>
          <input matInput type="text" formControlName="name" [mask]="form.getMask('name')">
          <mat-error>{{ form.getError("name") }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-7 col-sx-12">
        <mat-form-field>
          <mat-label><!--@i18n-->Alghorit Device:<!----></mat-label>
          <mat-select formControlName="deviceSerial" class="upper">
            <mat-option class="upper" *ngFor="let device of devices" [value]="device.serial">
              {{ device.name }} ({{ device.serial }})
            </mat-option>
          </mat-select>
          <button mat-icon-button matSuffix (click)="removeDevice($event)" *ngIf="hasDevice()">
            <mat-icon class="material-icons-outlined">close</mat-icon>
          </button>
          <mat-error>{{ form.getError("deviceSerial") }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md col-sx-12">
        <mat-form-field>
          <mat-label><!--@i18n-->Identification: *<!----></mat-label>
          <input matInput type="text" formControlName="identification"
            [mask]="form.getMask('identification')">
          <mat-error>{{ form.getError("identification") }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col col-sx-12">
        <mat-form-field>
          <mat-label><!--@i18n-->Password:<!----></mat-label>
          <input matInput type="password" class="case-sensitive" formControlName="password" [mask]="form.getMask('password')" #pass>

          <button mat-icon-button matSuffix (click)="togglePass(pass)">
            <mat-icon>{{ passVisibility(pass) }}</mat-icon>
          </button>
          <mat-error>{{ form.getError("password") }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col col-sx-12">
        <mat-form-field>
          <mat-label><!--@i18n-->Password Confirm:<!----></mat-label>
          <input matInput type="password" class="case-sensitive" formControlName="passwordConfirm"
            [mask]="form.getMask('passwordConfirm')" #passConfirm>

          <button mat-icon-button matSuffix (click)="togglePass(passConfirm)">
            <mat-icon>{{ passVisibility(passConfirm) }}</mat-icon>
          </button>
          <mat-error>{{ form.getError("passwordConfirm") }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3 col-sx-12">
        <mat-form-field>
          <mat-label><!--@i18n-->Permission: *<!----></mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let role of roles" [value]="role.code">{{ role.description }}</mat-option>
          </mat-select>
          <mat-error>{{ form.getError("role") }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>
