/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MessageTypes } from "src/@prisma/js-common";
import { CSVBuilder } from "src/@prisma/js-utils";
import { __ } from "src/@prisma/ng-i18n";

import { ReportType } from "src/app/services/report.service";
import { ParamKeys } from "src/enums/param-keys.enum";
import { Message } from "src/models/message.model";
import { MESSAGE_TYPE_RECORD } from "src/utils/records";
import { dateBetween, formatDateTime, secondsToTime } from "src/utils/util";

export function operationsBuilder() {
  return new CSVBuilder({
    header: [
      "id", "requester", "identification", "item", "quantity", "station",
      "type", "group", "target", "createdAt", "receivedAt", "confirmedAt",
      "fulfilledAt", "time", "workshift", "status"
    ],
    mapHeader: {
      id:             __("ID"),
      requester:      __("Requester Name"),
      identification: __("Requester Identification"),
      item:           __("Bin code"),
      quantity:       __("Quantity"),
      station:        __("Station"),
      type:           __("Priority"),
      group:          __("Target group"),
      target:         __("Target user"),
      createdAt:      __("Request at"),
      receivedAt:     __("Received at"),
      confirmedAt:    __("Receipt confirmed at"),
      fulfilledAt:    __("Finished at"),
      time:           __("Supply time"),
      workshift:      __("Workshift"),
      status:         __("Status")
    },
    mapData(row: Message) {
      const user = row.user;
      const group = row.group;
      const deviceSerial = row.deviceSerial?.toUpperCase();
      return {
        id:             row.id.toUpperCase(),
        requester:      row.createdByUser?.name.toUpperCase() || "-",
        identification: row.createdByUser?.identification.toUpperCase() || "-",
        item:           row.templateParams
          .let(it => it[ParamKeys.CODE] || it[ParamKeys.LEGACY_CODE])
          ?.toUpperCase() || "-",
        quantity:       row.templateParams[ParamKeys.AMOUNT]?.toUpperCase() || "-",
        station:        row.templateParams[ParamKeys.STATION]?.toUpperCase() || "-",
        type:           __(MESSAGE_TYPE_RECORD[row.type as MessageTypes] ?? "-").toUpperCase(),
        group:          group?.name.toUpperCase() || "-",
        target:         (user ? `${user.name.toUpperCase()} (${user.identification.toUpperCase()})`
          : deviceSerial) || "-",
        createdAt:      row.createdAt ? formatDateTime(row.createdAt) : "-",
        receivedAt:     row.receivedAt ? formatDateTime(row.receivedAt) : "-",
        confirmedAt:    row.confirmedAt ? formatDateTime(row.confirmedAt) : "-",
        fulfilledAt:    row.fulfilledAt ? formatDateTime(row.fulfilledAt) : "-",
        time:           row.fulfilledAt && row.createdAt ?
          secondsToTime(Math.round((row.fulfilledAt.valueOf() - row.createdAt.valueOf()) / 1e3)) : "-",
        workshift:      dateBetween(row.createdAt, [
          { start: "06:00", end: "13:59:59", value: "1" },
          { start: "14:00", end: "23:59:59", value: "2" }
        ]) ?? "-",
        status: row.fulfilledAt
          ? __("Fulfilled")
          : row.confirmedAt
            ? __("Confirmed")
            : row.receivedAt
              ? __("Received")
              : __("Pending")
      };
    }
  });
}


export const REPORT_BUILDER_MAP = {
  [ReportType.MESSAGE]:  operationsBuilder,
};

