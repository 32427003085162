import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { getErrorMessage, formatDate, validatorMessage } from "src/utils/util";
import { CSVBuilder, CSVMapHeader, Exception, isNumber } from "src/@prisma/js-utils";
import { Subscription } from "rxjs";
import moment = require("moment");
import { ReportService, ReportType } from "src/app/services/report.service";
import { REPORT_BUILDER_MAP } from "./reports.fields";
import { MatDialog } from "@angular/material/dialog";
import { ExcelReportInfo, exportExcel } from "src/utils/excel";
import { __ } from "src/@prisma/ng-i18n";

export type ReportTypeOption = {
  label:        string
  type:         ReportType
  ignoreStart?: boolean
  ignoreEnd?:   boolean
};

export type FormGroupType = {
  reportType: FormControl<ReportTypeOption | null>
  chassis:    FormControl<string | null>
  start:      FormControl<Date | null>
  end:        FormControl<Date | null>
  startTime:  FormControl<number | null>
  endTime:    FormControl<number | null>
};

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"]
})
export class ReportsComponent {
  private subscription = new Subscription();

  dataSource = new MatTableDataSource<any>();
  form: FormGroup<FormGroupType>;

  reportTypes: ReportTypeOption[] = [
    {
      label: __("Calls"),
      type: ReportType.MESSAGE
    }
  ];
  inProcess = false;
  _builder?: CSVBuilder<any, any>;

  timeOptions = new Array(24).fill(null).map((_, i) => {
    return {
      value: i,
      label: i.toString().padStart(2, "0")
    };
  });

  report?: ExcelReportInfo;

  header: string[] = [];
  mapHeader: CSVMapHeader<string> = {};

  get builder(): CSVBuilder<any, any> | undefined {
    return this._builder;
  }
  set builder(value: CSVBuilder<any, any> | undefined) {
    this._builder  = value;
    this.header    = value?.getHeader() || [];
    this.mapHeader = value?.getMapHeader() || {};
  }

  get disableGenerate(): boolean {
    return this.inProcess || !this.reportType?.value ||
      (!this.chassis.value && !this.start?.value && !this.reportType?.value?.ignoreStart) ||
      (!this.chassis.value && !this.end?.value && !this.reportType?.value?.ignoreEnd);
  }

  get reportType(): FormControl<ReportTypeOption | null> {
    return this.form.controls.reportType;
  }

  get chassis(): FormControl<string | null> {
    return this.form.controls.chassis;
  }

  get start(): FormControl<Date | null> {
    return this.form.controls.start;
  }

  get end(): FormControl<Date | null> {
    return this.form.controls.end;
  }

  constructor(
    private reportService:    ReportService,
    private dialog:           MatDialog,
    private feed:             MatFeed
  ) {
    this.form = new FormGroup<FormGroupType>({
      reportType: new FormControl(this.reportTypes[0], {
        validators: [
          validatorMessage(Validators.required, /*@i18n*/("Report type required"))
        ]
      }),
      chassis: new FormControl(null, {
        validators: [
          validatorMessage(Validators.minLength(17), /*@i18n*/("Chassis too short")),
          validatorMessage(Validators.maxLength(17), /*@i18n*/("Chassis too long"))
        ]
      }),
      start: new FormControl(null, {
        validators: [
          validatorMessage(Validators.required, /*@i18n*/("Filter start date required"))
        ],
      }),
      end: new FormControl(null, {
        validators: [
          validatorMessage(Validators.required, /*@i18n*/("Filter end date required"))
        ],
      }),
      startTime: new FormControl(0),
      endTime:   new FormControl(23)
    });

    this.subscription.add(
      this.form.valueChanges.subscribe(({ reportType, chassis }) => {
        this.report = void 0;
        this.dataSource.data = [];
        this.builder = void 0;

        const { ignoreStart, ignoreEnd } = reportType || {};

        const start     = this.form.controls.start;
        const startTime = this.form.controls.startTime;
        const end       = this.form.controls.end;
        const endTime   = this.form.controls.endTime;

        if (ignoreStart || chassis) {
          start.disable({ emitEvent: false });
          startTime.disable({ emitEvent: false });
        } else {
          start.enable({ emitEvent: false });
          startTime.enable({ emitEvent: false });
        }

        if (ignoreEnd || chassis) {
          end.disable({ emitEvent: false });
          endTime.disable({ emitEvent: false });
        } else {
          end.enable({ emitEvent: false });
          endTime.enable({ emitEvent: false });
        }
      })
    );
  }

  getErrorMessage(path: keyof FormGroupType): string {
    const control = this.form.get(path);
    return control ? getErrorMessage(control) : "";
  }

  formatDate = formatDate;

  clear(): void {
    this.form.reset({
      startTime: 0,
      endTime: 23
    });
    this.builder = void 0;
    this.dataSource.data = [];
  }

  async generate(): Promise<void> {
    try {
      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();

      if (this.form.invalid) throw new Exception/*@i18n*/("Invalid fields", "");

      this.inProcess = true;
      this.dataSource.data = [];

      const { reportType, chassis, start: startDate, end: endDate, startTime, endTime } = this.form.value;
      const hasStartOrIgnore = chassis || startDate || reportType?.ignoreStart;
      const hasEndOrIgnore = chassis || endDate || reportType?.ignoreEnd;

      if (reportType && hasStartOrIgnore && hasEndOrIgnore) {
        this.builder = REPORT_BUILDER_MAP[reportType.type]();

        const start = moment(startDate && isNumber(startTime)
          ? [ startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime ]
          : void 0
        ).valueOf();
        const end = moment(endDate && isNumber(endTime)
          ? [ endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime + 1 ]
          : void 0
        ).valueOf();

        if (!reportType.ignoreStart && !chassis) {
          if (!reportType.ignoreEnd && start >= end)
            throw new Exception/*@i18n*/("Start date should be less than final date", "");
          if (start >= Date.now())
            throw new Exception/*@i18n*/("Start date should be less than current date", "");
        }


        this.report = {
          type:    reportType.label,
          start:   (!chassis && !reportType.ignoreStart) ? start : Date.now(),
          end:     (!chassis && !reportType.ignoreEnd) ? end - 1 : void 0,
          builder: this.builder.setData(await this.reportService.get(reportType.type, {
            low:  start,
            high: end,
          }))
        };

        this.dataSource.data = this.builder.buildData(this.report.builder.getData().slice(0, 15));
      }
    } catch (e) {
      this.feed.fromError(e);
    } finally {
      this.inProcess = false;
    }
  }

  export(): void {
    if (this.report) exportExcel(this.report);
  }

  showStart(): boolean {
    return !this.reportType.value?.ignoreStart && !this.chassis.value?.length;
  }

  showEnd(): boolean {
    return !this.reportType.value?.ignoreEnd && !this.chassis.value?.length;
  }
}
