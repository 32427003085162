import { Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[html-element], :not([html-element])",
  exportAs: "htmlElement"
})
export class HTMLElementDirective<T extends HTMLElement> {

  get element(): T {
    return this.elementRef.nativeElement;
  }

  constructor(
    private elementRef: ElementRef<T>
  ) {}
}
