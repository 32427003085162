import { Field, format, Format, HttpPath, pattern, Primary, Validate } from "src/@prisma/js-proto";
import { DateField } from "../decorators";

@HttpPath("message/template")
export class MessageTemplateModel {
  @Validate([
    pattern("uuid", [ /*@i18n*/("Invalid message template id"), "MTP-0x001" ])
  ])
  @Validate("update", [
    pattern("required", [ /*@i18n*/("Message template id not informed"), "MTP-U-0x001" ])
  ])
  @Primary()
  public id?: string;

  @Validate([
    pattern("min", [ /*@i18n*/("Message template name too short"), "MTP-0x006" ],  1),
    pattern("max", [ /*@i18n*/("Message template name too long"),  "MTP-0x007" ], 40)
  ])
  @Format([
    format("trim"),
    format("lowercase")
  ])
  @Field()
  public name?: string;

  @Validate([
    pattern("min", [ /*@i18n*/("Message template title too short"), "MTP-0x002" ],  1),
    pattern("max", [ /*@i18n*/("Message template title too long"),  "MTP-0x003" ], 60)
  ])
  @Field({ type: "string", nullable: true })
  public title?: string | null;

  @Validate("create", [
    pattern("required", [ /*@i18n*/("Message template body not informed"), "MTP-C-0x001" ])
  ])
  @Validate([
    pattern("min", [ /*@i18n*/("Message template body too short"), "MTP-0x004" ], 1),
    pattern("max", [ /*@i18n*/("Message template body too long"), "MTP-0x005" ], 200)
  ])
  @Format([
    format("trim")
  ])
  @Field()
  public body?: string;

  @DateField()
  public createdAt?: Date;

  @Field()
  public createdBy?: string;
}
