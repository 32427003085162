export {};

Object.defineProperty(Object.prototype, "let", {
  enumerable: false,
  value: function(this, block: any) {
    return block(this);
  }
});

Object.defineProperty(Object.prototype, "also", {
  enumerable: false,
  value: function(this, block: any) {
    block(this);
    return this;
  }
});

Object.defineProperty(Object.prototype, "$debug", {
  enumerable: false,
  value: function(this, ...args: any[]) {
    console.log(...args, this);
    return this;
  }
});

Object.defineProperty(Object.prototype, "takeIf", {
  enumerable: false,
  value: function(this, predicate: any) {
    return predicate(this) ? this : void 0;
  }
});

Object.defineProperty(Object.prototype, "takeUnless", {
  enumerable: false,
  value: function(this, predicate: any) {
    return predicate(this) ? void 0 : this;
  }
});

Object.defineProperty(Boolean.prototype, "takeIf", {
  enumerable: false,
  value: function(this, predicate: any) {
    return (predicate && predicate(this.valueOf())) || this.valueOf() ? this.valueOf() : void 0;
  }
});

Object.defineProperty(Boolean.prototype, "takeUnless", {
  enumerable: false,
  value: function(this, predicate: any) {
    return (predicate && predicate(this.valueOf())) || this.valueOf() ? void 0 : this.valueOf();
  }
});

for (const type of [ Number, String, Boolean ]) {
  Object.defineProperty(type.prototype, "let", {
    enumerable: false,
    value: function(this, block: any) {
      return block(this.valueOf());
    }
  });

  Object.defineProperty(type.prototype, "also", {
    enumerable: false,
    value: function(this, block: any) {
      block(this.valueOf());
      return this.valueOf();
    }
  });

  Object.defineProperty(type.prototype, "$debug", {
    enumerable: false,
    value: function(this, ...args: any[]) {
      console.log(...args, this.valueOf());
      return this.valueOf();
    }
  });

  if (type === Boolean) continue;

  Object.defineProperty(type.prototype, "takeIf", {
    enumerable: false,
    value: function(this, predicate: any) {
      return predicate(this.valueOf()) ? this.valueOf() : void 0;
    }
  });

  Object.defineProperty(type.prototype, "takeUnless", {
    enumerable: false,
    value: function(this, predicate: any) {
      return predicate(this.valueOf()) ? void 0 : this.valueOf();
    }
  });
}

Object.defineProperty(Promise.prototype, "timeout", {
  enumerable: false,
  value: function(this, timeout: number): Promise<any> {
    let timer: NodeJS.Timeout;
    return Promise.race([
      this,
      new Promise((_, reject) => (timer = setTimeout(reject, timeout)))
    ]).finally(() => clearTimeout(timer));
  }
});
