<div class="header-content" [style.justify-content]="hideDate ? 'center' : 'space-between'">
  <div [innerHTML]="hourHTML" class="righteous separator" *ngIf="!hideDate" ngi18nIgnore></div>

  <div *ngIf="menuItems.length" class="righteous menu">
    <button mat-stroked-button *ngIf="showButtons" [disabled]="disablePrevButton" (click)="movePrev()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>

    <span class="menu-label">
      {{ selected?.label }}
    </span>

    <button mat-stroked-button *ngIf="showButtons" [disabled]="disableNextButton" (click)="moveNext()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

  <div [innerHTML]="dateHTML" class="date righteous separator" *ngIf="!hideDate" ngi18nIgnore></div>

  <div class="header-content-absolute" *ngIf="showLogin" routerLink="/auth">
    <mat-icon class="material-icons">login</mat-icon>
    <!--@i18n-->Sign In<!---->
  </div>
</div>

