import { CSVMapData, CSVMapHeader, CSVOptions } from "./interfaces";
import { isNumber } from "./number";
import { isString } from "./string";

/**
 * Create a CSV builder
 */
export class CSVBuilder<Model extends Record<string | number | symbol, any>, Map extends string> {
  private header: Map[];
  private data: Model[];
  private delims: string;
  private mapHeader: CSVMapHeader<Map>;
  private mapData: CSVMapData<Model, Map>;

  /**
   * @param options Options for build CSV
   */
  constructor(
    options: CSVOptions<Model, Map>
  ) {
    if (!options) options = {};
    this.header    = options.header || [];
    this.data      = options.data || [];
    this.delims    = options.delims || ";";
    this.mapHeader = options.mapHeader || {};
    this.mapData   = options.mapData || {};
  }

  private sort?: (a: Model, b: Model) => number;

  private footerGenerator?: (
    data: Model[],
    mappedData: Record<Map, string | number>[]
  ) => Record<Map, string | number>;

  setHeader(header: Map[]): this {
    this.header = header;
    return this;
  }

  getHeader(): Map[] {
    return this.header;
  }

  setData(data: Model[]): this {
    this.data = data;
    return this;
  }

  getData(): Model[] {
    return this.data;
  }

  /**
   * Set delimiter between cells
   * @param delims delimiter default: `;`
   */
  setDelims(delims = ";"): this {
    this.delims = delims;
    return this;
  }

  getDelims(): string {
    return this.delims;
  }

  /**
   * Set a function to sort the table body
   *
   * @example
   * Sort by sell price
   *
   * ``` typescript
   * const csvBuilder = new CSVBuilder(
   *    data: [
   *      { sell: 40, buy: 20 },
   *      { sell: 35, buy: 25 }
   *    ],
   *    header: [ "sell", "buy" ]
   * );
   * csvBuilder.setSort((a, b) => {
   *    return (a.sell || 0) - (b.sell || 0);
   * });
   * console.log(csvBuilder.toCSV());
   * // "sell","buy"
   * // 35,25
   * // 40,20
   * ```
   * @param sort sort function
   */
  setSort(sort?: (a: Model, b: Model) => number): this {
    this.sort = sort;
    return this;
  }

  /**
   * Set Map to transform the table header by column
   *
   * @example
   * Transform Table Header
   *
   * ``` typescript
   * const csvBuilder = new CSVBuilder(
   *    data: [
   *      { sell: 40, buy: 20 },
   *      { sell: 35, buy: 25 }
   *    ],
   *    header: [ "sell", "buy" ]
   * );
   * csvBuilder.setMapHeader({
   *    sell: "Sell Price",
   *    buy:  "Buy Price"
   * });
   * console.log(csvBuilder.toCSV());
   * // "Sell Price","Buy Price"
   * // 40,20
   * // 35,25
   * ```
   * @param mapHeader header map object
   */
  setMapHeader(mapHeader: CSVMapHeader<Map>): this {
    this.mapHeader = mapHeader;
    return this;
  }

  getMapHeader(): CSVMapHeader<Map> {
    return this.mapHeader;
  }

  /**
   * Set Map to transform cells in the table body
   *
   * @example
   * Transform Table Body
   *
   * ``` typescript
   * const csvBuilder = new CSVBuilder<{ sell: number, buy: number, net: number }>(
   *    data: [
   *      { sell: 40, buy: 20 },
   *      { sell: 35, buy: 25 }
   *    ],
   *    header: [ "sell", "buy", "net" ]
   * );
   * csvBuilder.setMapData({
   *    sell: (cell) => `$ ${cell.toFixed(2)}`,
   *    buy:  (cell) => `$ ${cell.toFixed(2)}`,
   *    net:  (cell, row) => `$ ${((row.sell || 0) - (row.buy || 0)).toFixed(2)}`
   * });
   * console.log(csvBuilder.toCSV());
   * // "sell","buy","net"
   * // "$ 40.00","$ 20.00","$ 20.00"
   * // "$ 35.00","$ 25.00","$ 10.00"
   * ```
   * @param mapData header map object
   */
  setMapData(mapData: CSVMapData<Model, Map>): this {
    this.mapData = mapData;
    return this;
  }

  getMapData(): CSVMapData<Model, Map> {
    return this.mapData;
  }

  /**
   * Set Map for transform cells in table body
   *
   * @example
   * Transform Table Body
   *
   * ``` typescript
   * const csvBuilder = new CSVBuilder<{ year: number, sell: number, buy: number }>(
   *    data: [
   *      { year: 2021, sell: 40, buy: 20 },
   *      { year: 2022, sell: 35, buy: 25 }
   *    ],
   *    header: [ "year", "sell", "buy" ]
   * );
   * csvBuilder.setFooterGenerator((data) => {
   *    let buy = 0;
   *    let sell = 0;
   *
   *    for (const row of data) {
   *      buy += row.buy || 0;
   *      sell += row.sell || 0;
   *    }
   *
   *    return {
   *      year: "Total",
   *      buy,
   *      sell,
   *    };
   * });
   * console.log(csvBuilder.toCSV());
   * // "year","sell","buy"
   * // 2021,40,20
   * // 2022,35,25
   * // "Total",75,45
   * ```
   * @param footerGenerator function to generate the footer
   */
  setFooterGenerator(footerGenerator: (
    data: Model[],
    mappedData: Record<Map, string | number>[]
  ) => Record<Map, string | number>): this {
    this.footerGenerator = footerGenerator;
    return this;
  }

  buildData(data = this.data.slice()): Record<Map, string | number>[] {
    if (this.sort) data.sort(this.sort);

    let body: Record<Map, string | number>[];
    const mapData = this.mapData;

    if (typeof mapData === "function") body = data.map(e => mapData(e, data));
    else {
      body = data.map((row) => {
        return this.header.reduce((acc, column) => {
          if (column in row) row[column];

          const value = row[column];
          const mapCell = mapData[column];
          acc[column] = mapCell ? mapCell(value, row, this.data)
            : isString(value) || isNumber(value) ? value : "";
          return acc;
        }, {} as Record<Map, string | number>);
      });
    }


    if (this.footerGenerator) {
      body.push(this.footerGenerator(this.data, body));
    }

    return body;
  }

  /**
   * Return the current table as csv
   */
  toCSV(data?: Model[], char = ""): string {
    const headerCSV = `"${this.header.map(e => (this.mapHeader[e] || e)?.replace(/"/ig, "\"\""))
      .join(`"${this.delims}"`)}"`;

    const bodyCSV = this.buildData(data).reduce((acc, row) => {
      return acc + `\n${this.header.map(column => {
        const value = row[column];
        return isNumber(value) ? value : isString(value) ?
          `"${value.replace(/"/ig, "\"\"") + char}"` : "";
      }).join(this.delims)}`;
    }, "");

    return `\ufeff${headerCSV}${bodyCSV}`;
  }

  toString(): string { return this.toCSV(); }
  valueOf():  string { return this.toCSV(); }
  toJSON():   string { return this.toCSV(); }
}
