<default-form-container [templateRef]="formRef" [component]="this"></default-form-container>

<ng-template #formRef>
  <div [formGroup]="form" class="container">
    <mat-form-field>
      <mat-label><!--@i18n-->Name: *<!----></mat-label>
      <input type="text" matInput formControlName="name" [mask]="form.getMask('name')">
      <mat-error>{{ form.getError("name") }}</mat-error>
    </mat-form-field>

    <div class="row users">
      <div class="col-md-6 col-xs-12">
        <mat-form-field>
          <mat-label><!--@i18n-->Observation:<!----></mat-label>
          <textarea type="text" matInput formControlName="obs" [mask]="form.getMask('obs')"></textarea>
          <mat-error>{{ form.getError("obs") }}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-xs-12">
        <mat-form-field>
          <mat-label><!--@i18n-->Search users and add to group<!----></mat-label>
          <input matInput type="search" (keyup)="filterSearch(searchInput.value)"
            (keyup.enter)="searchUserOpts[0] ? selectUser(searchUserOpts[0].user, searchInput) : null"
            [matAutocomplete]="auto" #searchInput>

          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let opts of searchUserOpts" [value]="opts.label"
              (keyup.enter)="selectUser(opts.user, searchInput)"
              (click)="selectUser(opts.user, searchInput)">
              {{ opts.label }}
            </mat-option>
          </mat-autocomplete>

          <mat-error>{{ form.getError("search") }}</mat-error>
        </mat-form-field>

        <div class="list-scroll" #listScroll>
          <table mat-table [dataSource]="dataSource" class="list">

            <ng-container matColumnDef="user">
              <th mat-header-cell *matHeaderCellDef><!--@i18n-->Users:<!----></th>

              <td mat-cell *matCellDef="let data" class="user" ngi18nIgnore>
                <div>
                  <span>({{ data.value.user?.identification?.toUpperCase() }}) {{ data.value.user?.name?.toUpperCase() || "..." }}</span>

                  <button mat-icon-button color="warn">
                    <mat-icon (click)="data.removeFromArray()">close</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="[ 'user' ]; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: [ 'user' ];" class="box"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="100"><!--@i18n-->Use the search field to add users.<!----></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
