import { Field, format, Format } from "src/@prisma/js-proto";
import { User } from "./user.model";
import { Group } from "./group.model";
import { GroupUserModel } from "src/@prisma/js-common";

export class GroupUser extends GroupUserModel {
  override groupId!: string;
  override userId!:    string;

  @Field({ type: () => User })
  @Format(format("empty"))
  override user?: User;

  @Field({ type: () => Group })
  @Format(format("empty"))
  override group?: Group;

  override createdAt!: Date;
  override createdBy!: string;

  @Field({ type: () => User })
  @Format(format("empty"))
  override createdByUser?: User;
}
