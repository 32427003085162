import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PagesComponent } from "./pages.component";

const routes: Routes = [
  {
    path: "",
    component: PagesComponent,
    children: [
      {
        path: "messages",
        loadChildren: () => import("./messages/messages.module")
          .then(e => e.MessagesModule)
      },
      {
        path: "reports",
        loadChildren: () => import("./reports/reports.module")
          .then(e => e.ReportsModule)
      },
      {
        path: "group",
        loadChildren: () => import("./group/group.module")
          .then(e => e.GroupModule)
      },
      {
        path: "users",
        loadChildren: () => import("./users/users.module")
          .then(e => e.UsersModule)
      },
      { path: "**", redirectTo: "messages" }
    ]
  },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
