<div class="data-table-content">
  <div class="table-header" [ngStyle]="{ flexDirection: inverse ? 'row-reverse' : 'row' }">
    <mat-form-field>
      <mat-label><!--@i18n-->Search:<!----></mat-label>
      <input matInput type="search" [formControl]="form">
      <mat-error></mat-error>
    </mat-form-field>

    <div *ngIf="headerTemplate">
      <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
    </div>

    <div class="buttons-container" *ngIf="showNew || showDelete">
      <button mat-raised-button color="warn" (click)="deleteItem(selection)"
        *ngIf="selection.selected.length && showDelete">
        <mat-icon>delete_outline</mat-icon>
        <span *ngIf="!xSmall"><!--@i18n-->Delete<!----></span>
      </button>

      <button mat-raised-button color="success" (click)="newItem()" *ngIf="showNew"
        [disabled]="blockNewItem && !blockMessage?.length">
        <mat-icon>add_circle_outline</mat-icon>
        <span *ngIf="!xSmall"><!--@i18n-->New<!----></span>
      </button>
    </div>
  </div>

  <div class="table" #divTable ngi18nIgnore [@fadeInOut]>
    <table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true"
    matSort [matSortActive]="sortActive" [matSortDirection]="sortDirection">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="!!getSelectedInPage().length && !isAllSelected()">
          </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row.data) : null"
            [checked]="selection.isSelected(row.data)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container *ngFor="let field of fields" [matColumnDef]="field.key">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="field.sortable === false" ngi18n>
          {{ field.label }}
        </th>
        <td mat-cell *matCellDef="let row" [ngClass]="getClass(row.data, field)">
          <ng-template *ngIf="isTemplate(row.formatted[field.key]); else text"
          [ngTemplateOutlet]="row.formatted[field.key]" [ngTemplateOutletContext]="{ message: row.data }">
          </ng-template>
          <ng-template #text>{{ row.formatted[field.key] }}</ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="action" stickyEnd>
        <th mat-header-cell *matHeaderCellDef ngi18n>
         <!--@i18n-->Edit<!---->
        </th>
        <td mat-cell *matCellDef="let row" class="config-cell" ngi18n>
          <button mat-raised-button color="accent" (click)="$event.stopPropagation(); editItem(row.data)">
            <mat-icon>edit</mat-icon>
            <span><!--@i18n-->Edit<!----></span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="details">
        <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplay.length" class="details">
          <ng-template *ngIf="detailsTemplate"
            [ngTemplateOutlet]="detailsTemplate"
            [ngTemplateOutletContext]="{ row, data: row.data, expanded: isExpanded(row) }"
          ></ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"
        [ngClass]="rowClasses(row.data)" (click)="toggleExpanded(row)"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: [ 'details' ]; when: whenExpand()"
        [ngClass]="{ double: details, collapsed: !isExpanded(row), animation: animation }"
        [@detailExpand]="isExpanded(row) ? 'expanded' : 'collapsed'"
      ></tr>

      <tr class="mat-row" *matNoDataRow class="box">
        <td class="mat-cell" colspan="100" [ngi18n]="factory(service.opts.name)" ngi18nTranslateArgs="true"><!--@i18n-->No %s found<!----></td>
      </tr>
    </table>
  </div>
  <mat-paginator [pageSize]="pageSize" (page)="setPageSize($event.pageSize)">
  </mat-paginator>
</div>
