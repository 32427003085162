import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { DefaultDialogComponent } from "src/app/utils/default-dialog/default-dialog.component";
import { AppBreakpoints } from "src/utils/breakpoints";
import { DefaultDialogOptions, FooterMenuItem } from "src/utils/interfaces";
import { OptionsComponent } from "./options/options.component";

@Component({
  selector: "theme-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit, OnDestroy {
  private _menus: FooterMenuItem[] = [ this.appMenu ];
  private subscription = new Subscription();

  showText = false;
  about? = {
    email: "suporte@alghorit.com",
    phone: "+55 (31) 3177-2758"
  };

  @Input()
  get menus(): FooterMenuItem[] {
    return this._menus;
  }
  set menus(values: FooterMenuItem[]) {
    this._menus = values.slice();
    const appIndex = values.length % 2 ? values.length : Math.floor(values.length / 2);
    this._menus.splice(appIndex, 0, this.appMenu);
  }

  @Output() selectedMenuIndexChange = new EventEmitter<number>();

  @Input() withoutMenu = false;

  private get appMenu(): FooterMenuItem {
    return {
      icon: "account_circle",
      className: "app",
      select: () => this.openOptions()
    };
  }

  constructor(
    private dialog:       MatDialog,
    private breakpoint:   BreakpointObserver,
    private feed:         MatFeed
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.subscription.add(
      this.breakpoint.observe(AppBreakpoints.XSmall).subscribe(({ breakpoints }) => {
        this.showText = !breakpoints[AppBreakpoints.XSmall];
      })
    );

    try {
      // this.about = await this.aboutService.get();
    } catch (e) {
      this.feed.fromError(e);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openOptions(): void {
    const dialog = this.dialog.open(DefaultDialogComponent, {
      maxWidth: "100%",
      data: <DefaultDialogOptions>{
        component: OptionsComponent,
        title: /*@i18n*/("System info"),
        icon: "account_circle",
        btnClose: () => {
          dialog.close();
        }
      }
    });
  }
}
