import { ValidationContext } from "../interfaces";
import { ValidationTaskTemplate } from "./task-template";

export class ValidateRequiredTask extends ValidationTaskTemplate {
  override configure(): this {
    return this;
  }

  override async validate(context: ValidationContext): Promise<void> {
    const value = context.payload;

    if (value === null || value === void 0 || value === "")
      throw this.getFieldError();
  }
}
