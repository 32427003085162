import { ProtoDeepPartial } from "../interfaces/index";
import { Bulk } from "./bulk";
import { ModelNode } from "./node";

type ExtractBulk<T, E = undefined> = T extends object ?
  Bulk<T extends any[] ? T[number] extends object ? T[number] : never : T> : E

export class BulkIterable<T extends object> {
  public readonly node: ModelNode<T>;

  // Save if value was remove to avoid unnecessary search when getting `index`.
  private removed = false;

  /**
   * Index of this iterable at it's bulk. Returns `-1` if value was removed from bulk.
   */
  get index(): number { return this.removed ? -1 : this.bulk.value.indexOf(this.value); }

  constructor(
    public readonly value: T,
    public readonly bulk: Bulk
  ) {
    const parentNode = bulk.getNode();
    this.node = parentNode.grow(value, parentNode.parentRef);
  }

  /**
   * Get a child bulk of the specified field. If field is not bulkable it will return `undefined` instead.
   */
  bulkFrom<K extends keyof T>(field: Extract<K, string>): ExtractBulk<T[K]> | undefined {
    const node = this.node.getChild(field);
    const type = node?.typeObject;

    if (!type) return;

    return new Bulk<any>(node, { parent: this.bulk }) as ExtractBulk<T[K]>;
  }

  /**
   * Get value from a specified field.
   */
  get<K extends keyof T>(field: K): T[K] {
    return this.value[field];
  }

  /**
   * Use `Bulk.populate` with the current iterable value.
   * @experimental
   */
  populate(source: ProtoDeepPartial<T>): this {
    Bulk.populate(this.value, source);
    return this;
  }

  /**
   * Remove value form it's bulk. This is a demanding task cause it uses `Array.splice`.
   */
  remove(): this {
    const index = this.index;

    if (index > 0)
      this.bulk.value.splice(index, 1);

    this.removed = true;
    return this;
  }
}
