export {};

Object.defineProperty(Array.prototype, "remove", {
  enumerable: false,
  value: function(this, value: any) {
    const index = this.indexOf(value);

    if (index >= 0)
      this.splice(index, 1);

    return index >= 0;
  }
});

Object.defineProperty(Array.prototype, "pushOne", {
  enumerable: false,
  value: function(this, ...values: any[]) {
    for (const value of values)
      if (!this.includes(value)) this.push(value);
  }
});
