import { ConstructableAny } from "../interfaces/index";
import { Proto } from "./proto";

export class MetadataManager<T = any> {
  private static readonly MODEL_META = Symbol("proto_metadata");

  private metadataArr: any[] = [];

  /**
   * Get the metadata manager instance stored in a constructor.
   */
  static from<T = any>(target: ConstructableAny<T>): MetadataManager<T> | undefined
  /**
   * Get the metadata manager instance stored in the constructor of a instance.
   */
  static from<T = any>(target: T): MetadataManager<T> | undefined
  static from<T = any>(target: T): MetadataManager<T> | undefined {
    const constructor = Proto.getConstructor(target);

    if (constructor)
      return Reflect.getMetadata(MetadataManager.MODEL_META, constructor);
  }

  /**
   * Set the metadata of this constructor (will replace current manager). If the manager param is omitted
   * a new manager will be created.
   *
   * @returns The manager that was set in this constructor.
   */
  static inject<T>(target: ConstructableAny<T>, manager?: MetadataManager<T>): MetadataManager<T>
  /**
   * Set the metadata of the instance constructor (will replace current manager).
   * If the manager param is omitted a new manager will be created.
   *
   * @returns The manager that was set in this instance constructor.
   */
  static inject<T>(target: T, manager?: MetadataManager<T>): MetadataManager<T>
  static inject(target: any, manager = new MetadataManager()): MetadataManager<any> {
    const constructor = Proto.getConstructor(target);

    if (!constructor)
      throw new Error("Invalid target to set metadata");

    Reflect.defineMetadata(MetadataManager.MODEL_META, manager, constructor);

    return manager;
  }

  /**
   * Try to get the metadata of a constructor or instance.
   */
  static get<TInput = any, TResult = TInput>(
    target: any, metadata: ConstructableAny<TInput>): TResult | undefined
  {
    return this.from(target)?.get(metadata);
  }

  static getOrSet<TInput = any>(
    target: any, metadata: ConstructableAny<TInput>, set: () => TInput): TInput
  {
    const obtained = this.from(target)?.get(metadata);
    if (obtained) return obtained;

    const toSet = set();
    this.set(target, toSet);

    return toSet;
  }

  /**
   * Set the metadata to this target manager.
   *  * If there is already a metadata of the same type in the manager it will be replaced.
   *  * If the target hasn't a manager a new one will be created.
   */
  static set(target: any, metadata: any): void {
    (this.from(target) || this.inject(target)).set(metadata);
  }

  constructor(target?: ConstructableAny<T>)
  constructor(target?: T)
  constructor(target?: any) {
    if (target)
      MetadataManager.inject(target, this);
  }

  /**
   * Get the index of the stored metadata based in it's constructor. Returns -1 if not found.
   */
  private indexOf(metadata: ConstructableAny<any>): number {
    for (let i = 0, len = this.metadataArr.length; i < len; i++) {
      if (this.metadataArr[i] instanceof metadata)
        return i;
    }

    return -1;
  }

  get<TInput = any, TResult = TInput>(metadata: ConstructableAny<TInput>): TResult | undefined {
    const index = this.indexOf(metadata);
    if (index >= 0)
      return this.metadataArr[index] as TResult;
  }

  /**
   * Add metadata to this manager.
   *  * If there is already a metadata of the same type in the manager it will be replaced.
   */
  set(metadata: any): this {
    const constructor = Proto.getConstructor(metadata);

    if (!constructor)
      throw new Error("Metadata should be a class instance");

    const index = this.indexOf(constructor);

    if (index >= 0)
      this.metadataArr[index] = metadata;
    else
      this.metadataArr.push(metadata);

    return this;
  }
}
