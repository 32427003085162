import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { PoolingEventService } from "src/app/services/pooling-event.service";
import { Device } from "src/models/device.model";
import { Group } from "src/models/group.model";
import { MessageTemplate } from "src/models/message-template.model";
import { Message } from "src/models/message.model";
import { User } from "src/models/user.model";
import { fadeInOutAnimation } from "src/utils/fade-in-out-animation";

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
  animations: [ fadeInOutAnimation() ]
})
export class MessagesComponent implements OnDestroy {
  private subscription = new Subscription();

  constructor(
    public router:  Router,
    poolingService: PoolingEventService
  ) {
    poolingService.connect([ Message, MessageTemplate, User, Device, Group ])
      .also(it => this.subscription.add(it));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
