import { AbstractControl, FormArray } from "@angular/forms";
import { Subscription } from "rxjs";
import { Bulk, ConstructableNode, FieldType, ModelNode } from "../../js-proto";
import { DeepPartial, DeepPartialArray, isNumber, toArray } from "../../js-utils";
import { FormControlModel } from "./form-control-model";
import { FormGroupModel } from "./form-group-model";
import { ForcedControls, IFormModel, FormModelOptions, FormModel, FormNodeModel, FCDefaultArray } from "./interfaces";

export class FormArrayModel<
  Model = any,
  FC extends ForcedControls<Model> = FCDefaultArray>
  extends FormArray implements IFormModel
{
  private subscription = new Subscription();

  readonly node:  ModelNode<Model[]>;

  get parentGroup(): FormGroupModel | null {
    let parent: FormGroupModel | FormArrayModel | null = this.parent;
    while (parent instanceof FormArrayModel && parent)
      parent = parent?.parent || null;

    return parent;
  }
  get parentArray(): FormArrayModel | null {
    let parent: FormGroupModel | FormArrayModel | null = this.parent;
    while (parent instanceof FormGroupModel && parent)
      parent = parent?.parent || null;

    return parent;
  }

  override controls!:       FormNodeModel<Model, FC>[];
  override readonly value!: DeepPartial<Model[]>;
  override get parent(): FormGroupModel | FormArrayModel | null {
    return super.parent as any;
  }
  override get root(): FormGroupModel | FormArrayModel {
    return super.root as any;
  }

  constructor(
    seed: ConstructableNode<Model> | ModelNode<(Model)[]> | Bulk<Model & object>,
    private  opts: FormModelOptions<Model[], FC> = {}
  ) {
    const model = toArray(
      typeof seed === "function" ? new seed() : seed instanceof Bulk ?
        seed.value : seed.value
    );

    const type = typeof seed === "function" ? seed : seed.type;
    const node = seed instanceof ModelNode ? seed : new ModelNode(model, {
      type: type as ConstructableNode<Model[]>
    });

    const root = opts?._root !== false;
    opts._root = false;

    super(FormArrayModel.build(node, opts));
    this.node = node;
    if (root) this.initValidators();
  }

  static from<Model, FC extends ForcedControls<Model>>(
    ...args: ConstructorParameters<typeof FormArrayModel<Model, FC>>): FormArrayModel<Model, FC> {
    return new FormArrayModel(...args);
  }

  private static build<Model, FC extends ForcedControls<Model>>(
    node:  ModelNode<Model[]>,
    opts:  FormModelOptions<Model[], FC> = {}): FormNodeModel<Model, FC>[]
  {
    const model = node.value as DeepPartialArray<Model[]>;
    const value = opts.value ??= (opts.useDefaultValues !== false ? model : []);

    const type = node.typeObject as ConstructableNode<Model>;

    const controls = type ?
      value.map(e => new FormGroupModel(type, {
        ...opts as FormModelOptions<Model>,
        value: e as DeepPartialArray<Model & object>
      })) :
      value.map(e => new FormControlModel<Model>(node.sibling(e as Model, {
        type: node.type as FieldType
      }), { ...opts as FormModelOptions<Model>, value: e }));

    return controls as any;
  }

  insertNode(
    value?: DeepPartialArray<Model> | (DeepPartialArray<Model> | undefined)[],
    options: { index?: number, emitEvent?: boolean; } = {}
  ): this {
    const valueArr = toArray<Model | undefined>(value);
    const index = options.index;

    const forms = valueArr.map(e => FormArrayModel.build(this.node, {
      ...this.opts, value: [ e ] as DeepPartialArray<Model[]>
    })[0]);

    if (isNumber(index)) forms.forEach(e => this.insert(index, e, options));
    else forms.forEach(e => this.push(e, options));

    forms.forEach(e => e.initValidators());
    return this;
  }

  initValidators(): this {
    this.controls.forEach(e => e.initValidators());
    return this;
  }

  removeFromArray(): boolean {
    if (this.parent instanceof FormArrayModel) return this.parent.remove(this);
    return false;
  }

  remove(control: AbstractControl, options?: { emitEvent?: boolean; destroy: boolean }): boolean {
    const index = this.controls.indexOf(control as any);
    if (index >= 0) return (this.removeAt(index, options), true);
    else return false;
  }

  async getBulk(): Promise<Bulk<Model & object> | undefined> {
    return this.node.typeObject ? await new Bulk(
      this.node.typeObject as ConstructableNode<Model & object>
    ).import(this.value as any) : void 0;
  }

  watch(cb: (value: Model[]) => any): Subscription
  watch<T extends string | (string | number)[]>(field: T, cb: (value: any) => any): Subscription
  watch(
    fieldOrCb: string | (string | number)[] | ((value: any) => any),
    cb?: (value: any) => any
  ): Subscription | undefined
  {
    if (typeof fieldOrCb !== "function") return cb && this.get(fieldOrCb)?.watch(cb);

    cb = fieldOrCb;
    const subs = this.valueChanges?.subscribe(cb);
    this.subscription.add(subs);
    return subs;
  }

  destroy(): void {
    if (this.parent instanceof FormArrayModel) this.parent.destroy();
    this.subscription.unsubscribe();
  }

  getMask(path: string | (string | number)[]): string {
    const form = this.get(path);
    return form instanceof FormControlModel && form.getMask() || "";
  }

  hasMask(path: string | (string | number)[]): boolean {
    const form = this.get(path);
    return form instanceof FormControlModel && form.hasMask();
  }

  override removeAt(index: number, options?: { emitEvent?: boolean; destroy: boolean }): void {
    if (options?.destroy !== false) this.at(index)?.destroy();
    super.removeAt(index, options);
  }

  override at(index: number): FormNodeModel<Model, FC> {
    return super.at(index) as FormNodeModel<Model, FC>;
  }

  override get(path: string | (string | number)[]): FormModel | null {
    return super.get(path) as FormModel | null;
  }

  override getError(path?: string | (string | number)[]): string | undefined {
    return super.getError("message", path);
  }
}
