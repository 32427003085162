import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfirmDialogComponent } from "./confirm-dialog.component";
import { MatButtonModule } from "@angular/material/button";
import { NgI18nModule } from "src/@prisma/ng-i18n";


@NgModule({
  declarations: [
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    NgI18nModule,
    MatButtonModule
  ]
})
export class ConfirmDialogModule { }
