import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { __ } from "src/@prisma/ng-i18n";
import { GroupService } from "src/app/services/group.service";
import { PoolingEventService } from "src/app/services/pooling-event.service";
import { Group } from "src/models/group.model";
import { TableField } from "src/utils/interfaces";

@Component({
  selector: "group-list",
  templateUrl: "./group-list.component.html",
  styleUrls: ["./group-list.component.scss"]
})
export class GroupListComponent implements OnDestroy {
  private subscription = new Subscription();

  fields: TableField<Group>[] = [
    {
      key: "name",
      label: __("Name"),
      get: (group: Group): string => group.name.toUpperCase() || "-"
    },
    {
      key: "obs",
      label: __("Observation"),
      get: (group: Group): string => group.obs || "-"
    }
  ];

  constructor(
    public groupService:  GroupService,
    poolingEventService:  PoolingEventService
  ) {
    poolingEventService.connect([ Group ])
      .also(it => this.subscription.add(it));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
