import { FieldOptions, FieldType, Constructable, ExtractType, ConstructableNode } from "../interfaces/index";
import { ModelMetadata } from "../metadata";
import { MetadataManager } from "./manager";
import { Proto } from "./proto";


export class ModelNode<T = any, C extends ExtractType<T> = ExtractType<T>> {
  /**
   * First node that was created.
   */
  public readonly root:       ModelNode = this;
  /**
   * Node that created this node using: `node.grow`.
   */
  public readonly parent?:    ModelNode;
  /**
   * Field name referenced from it's parent.
   */
  public readonly parentRef?: string;

  public readonly metadata?:      ModelMetadata<C>;
  public readonly fieldOptions?:  FieldOptions<T>;
  public readonly type?:          FieldType | ConstructableNode<T>;

  public readonly alias?:    string;
  public readonly nullable:  boolean = false;
  public readonly array:     boolean = false;
  public readonly json:      boolean = false;
  public readonly exclude:   boolean = false;
  /**
   * Value was originally undefined when node was created.
   * If value is not `undefined` then it's using a dummy value.
   */
  public readonly undefined: boolean = false;

  /**
   * A dirty `node` means that it's `type` was forced.
   */
  public readonly dirty: boolean  = false;

  /**
   * Return node type if it's a `Constructable`.
   */
  get typeObject(): ConstructableNode<T> | undefined {
    return typeof this.type === "function" ? this.type : void 0;
  }

  /**
   * Create ModelNode from the specified value and set a specific type. Should be used when working with
   * arrays.
   * @example
   * ```
   * class ValidModel {}
   *
   * const models: ValidModel[] = [ new ValidModel() ];
   *
   * new ModelNode(models).typeObject;                  // undefined
   * ModelNode.fromType(ValidModel, models).typeObject; // typeof ValidModel
   * ```
   */
  static fromType<T>(type: ConstructableNode<T>, value: T): ModelNode<T> {
    return new ModelNode(value, { type });
  }

  constructor(public readonly value: T, opts?: {
    root?:      ModelNode,
    type?:      ConstructableNode<T> | FieldType,
    parent?:    { node: ModelNode, ref?: string },
    /**
     * Specify that the current value is a dummy value.
     */
    undefined?: boolean
  }) {
    if (opts) {
      if (opts.root) this.root = opts.root;
      if (opts.undefined !== void 0) this.undefined = opts.undefined;
      if (opts.parent) {
        this.parent    = opts.parent.node;
        this.parentRef = opts.parent.ref;
      }
    }

    const type = opts?.type;

    this.fieldOptions = this.getFieldOptions();
    this.type         = type || this.getTypeFromParent();
    this.metadata     = this.getMetadata();

    if (!this.type) this.type = this.getTypeFromMetadata();

    if (typeof this.type !== "function")
      this.undefined = value === void 0;

    const fieldOptions = this.fieldOptions;

    if (fieldOptions) {
      if (fieldOptions.nullable || (Array.isArray(this.type) && this.type.includes(null)))
        this.nullable = true;

      this.array   = !!fieldOptions.array;
      this.json    = !!fieldOptions.json;
      this.exclude = !!fieldOptions.exclude;
      this.alias   = fieldOptions.alias;
    }

    if (type) {
      this.array = Array.isArray(value);
      this.dirty = true;
    }
  }

  /**
   * Get field options for this node based of it's parent metadata and reference field name.
   * Root nodes usually don't have field options.
   */
  private getFieldOptions(): FieldOptions<T> | undefined {
    const ref      = this.parentRef;        if (!ref)      return;
    const metadata = this.parent?.metadata; if (!metadata) return;

    return metadata.fields?.[ref];
  }

  /**
   * Get current node metadata.
   */
  private getMetadata(): ModelMetadata<C> | undefined {
    return MetadataManager.get(this.value || this.type, ModelMetadata);
  }

  /**
   * Get field type from it's parent metadata. Root nodes usually don't have types.
   */
  private getTypeFromParent(): FieldType | Constructable<C> | undefined {
    const fieldOptions = this.fieldOptions;
    const type         = fieldOptions?.type as Function | FieldType | undefined;

    return typeof type === "function" ? type() : type;
  }

  /**
   * Get field type from it's metadata. Root nodes usually don't have types.
   */
  private getTypeFromMetadata(): Constructable<C> | undefined {
    const constructor = this.metadata
      ? Proto.getConstructor<C>(this.value) : void 0;
    if (constructor) return constructor;
  }


  /**
   * Get all fields from this node metadata as child `Node`s. Uses the `grow` function.
   */
  getChildren(): ModelNode[] {
    if (typeof this.value !== "object" || this.value === null) return [];

    return this.getAbstractChildren();
  }

  /**
   * Get all fields from this node metadata as if it's has value.
   */
  getAbstractChildren(): ModelNode[] {
    const fields = this.metadata?.fields;

    if (!fields) return [];

    const children: ModelNode[] = [];

    if (this.value)
      for (const childKey in fields) {
        children.push(this.grow(this.value[childKey as Extract<keyof T, string>], childKey));
      }
    else {
      for (const childKey in fields) {
        children.push(this.grow(void 0, childKey));
      }
    }

    return children;
  }

  /**
   * Get a single field from this node metadata as child `Node`. Uses the `grow` function.
   */
  getChild<K extends keyof C, C = T extends any[] ? T[number] : T>(
    ref: Extract<K, string>): ModelNode<C[K]> | undefined

  getChild(ref: string): ModelNode | undefined {
    if (
      typeof this.value !== "object" ||
      this.value === null ||
      !this.metadata?.fields?.[ref as keyof T]
    ) return;

    return this.grow(this.value[ref as keyof T], ref);
  }

  /**
   * Create a new `Node` passing the current node as parent and it's root.
   * @param value Value of the new node.
   * @param ref Name of the field in the parent node.
   */
  grow<T>(value: T, ref?: string, opts?: { undefined?: boolean }): ModelNode<T> {
    return new ModelNode(value, {
      root: this.root,
      parent: { node: this, ref },
      undefined: opts?.undefined
    });
  }

  /**
   * Create a new `Node` from the same parent as the current node.
   * @param value Value of the new node.
   *
   *  * If `ref` option is not specified the same reference of the current node will be used.
   */
  sibling<T>(value: T, opts?: {
    ref?: string, type?: FieldType | ConstructableNode<T>,
    undefined?: boolean
  }): ModelNode<T> {
    return new ModelNode(value, {
      root: this.root,
      type: opts?.type,
      parent: this.parent ? { node: this.parent, ref: opts?.ref || this.parentRef } : void 0,
      undefined: opts?.undefined
    });
  }

  /**
   * Check if current node is a root node.
   */
  isRoot(): boolean {
    return this === this.root;
  }
}
