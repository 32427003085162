import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, timeout } from "rxjs";
import { factory } from "src/@prisma/js-utils";
import { ApiConfig } from "../api-config.service";
import { NgDefaultTask } from "./default.task";

@Injectable()
export class RequestTask extends NgDefaultTask {
  static STAGE = "request";
  MAP = { [RequestTask.STAGE]: this };

  constructor(
    http:      HttpClient,
    apiConfig: ApiConfig
  ) {
    super(async context => {
      const options = context.requireOptions();
      if (options.hasCache) return;
      if (!options.http) throw new Error("HttpConfig not informed");

      const request = http.request(options.http.method, options.http.path, {
        body:            options.body,
        context:         options.context,
        headers:         options.headers as Record<string, string | string[]>,
        observe:         options.observe,
        reportProgress:  options.reportProgress,
        responseType:    options.responseType,
        withCredentials: options.withCredentials
      });
      const time = options.timeout ?? await factory(apiConfig.timeout, context);
      context.resolve(await firstValueFrom(time !== void 0 ? request.pipe(timeout(time)) : request));
    });
  }
}
