import { Field, Primary, Validate, pattern } from "src/@prisma/js-proto";
import { UserModel } from "./user.model";
import { AuthorField, DateField, DateFieldValidation } from "../decorators";
import { GroupModel } from "./group.model";

export class GroupUserModel {
  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid group user id"), "GPU-0x001" ]) ])
  @Validate("update", [ pattern("required", [ /*@i18n*/("Group user id not informed"), "GPU-U-0x001" ]) ])
  @Primary()
  public id?: string;

  @Validate([
    pattern("required", [ /*@i18n*/("Group id not informed"), "GPU-0x002" ]),
    pattern("uuid", [ /*@i18n*/("Invalid group id"), "GPU-0x003" ])
  ])
  @Field()
  public groupId?: string;

  @Validate([
    pattern("required", [ /*@i18n*/("User id not informed"), "GPU-0x004" ]),
    pattern("uuid", [ /*@i18n*/("Invalid user id"), "GPU-0x005" ])
  ])
  @Field()
  public userId?: string;

  @Field({ type: () => UserModel })
  public user?: UserModel;

  @Field({ type: () => GroupModel })
  public group?: GroupModel;

  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid group user created_by id"), "GPU-0x006" ]) ])
  @Field()
  public createdBy?: string;

  @DateFieldValidation({ code: "GPU-0x007" })
  @DateField()
  public createdAt?: Date;

  @AuthorField()
  public createdByUser?: UserModel;
}
