import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { FormComponent } from "./form/form.component";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"]
})
export class AuthComponent implements OnDestroy, AfterViewInit {
  @ViewChild("authForm", { read: ElementRef }) authForm!: ElementRef<HTMLElement>;
  @ViewChild("authForm", { read: FormComponent }) authFormComponent?: FormComponent;
  @ViewChild("keyboard") keyboardRef!: ElementRef<HTMLDivElement>;

  private subscription = new Subscription();

  showBanner = true;
  showForm = true;

  constructor(
    private router:       Router,
    private feed:         MatFeed
  ) {}

  ngAfterViewInit(): void {
    void 0;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
