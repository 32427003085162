import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { __ } from "src/@prisma/ng-i18n";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { NgEventPool } from "src/@prisma/ng-runner";
import { Device } from "src/models/device.model";
import { DefaultService } from "./default.service";

@Injectable({
  providedIn: "root"
})
export class DeviceService extends DefaultService<Device> {
  constructor(
    pool:   NgEventPool,
    feed:   MatFeed,
    dialog: MatDialog
  ) {
    super(Device, pool, feed, dialog, {
      name:        /*@i18n*/("Device"),
      pluralName:  /*@i18n*/("Devices"),
      deleteError: /*@i18n*/("No Device selected"),
      deleteName:  (item) => __("the device \"%s\"", item.name.toUpperCase())
    });
  }
}
