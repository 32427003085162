import "reflect-metadata";
import { Constructable, Serializable, SerializableArray, SerializableObject, SerializableValue } from "../interfaces/index";

export enum ProtoDebugLevel {
  WARNING = 1 << 0,
  ERROR   = 1 << 1
}

export class Proto {
  /**
   * Debug level for Proto Logs. (Default `0`)
   *
   * @example
   * ```
   * // Disable logs
   * Proto.debugLevel = 0
   *
   * // Only warnings
   * Proto.debugLevel = ProtoDebugLevel.WARNING
   *
   * // Both errors and warnings
   * Proto.debugLevel = ProtoDebugLevel.WARNING | ProtoDebugLevel.ERROR
   * ```
   */
  static debugLevel = 0;

  static isSerializableValue(value: any): value is SerializableValue {
    return (
      typeof value === "boolean" ||
      typeof value === "string"  ||
      typeof value === "number"  ||
      value === null ||
      value === void 0
    );
  }

  static isSerializableObject(value: any): value is SerializableObject {
    return (
      typeof value === "object" &&
      ({} as any)["__proto__"] === value?.__proto__ &&
      Object.keys(value).every(key => this.isSerializable(value[key]))
    );
  }

  static isSerializableArray(value: any[]): value is SerializableArray {
    return (
      Array.isArray(value) &&
      value.every(e => this.isSerializable(e))
    );
  }

  static isSerializable(value: any): value is Serializable {
    return (
      this.isSerializableValue(value) ||
      this.isSerializableObject(value) ||
      this.isSerializableArray(value)
    );
  }

  static merge<T extends {}, S>(target: T, source: S, opts?: { overwriteArrays?: boolean }): T & S {
    const targetAny: any = target;

    if (source)
      for (const key in source) {
        const value = source[key];

        if (value === void 0) continue;

        if (Array.isArray(targetAny[key])) {
          if (Array.isArray(value) && !opts?.overwriteArrays)
            targetAny[key].push(...value);
          else
            targetAny[key] = value;
        } else if (
          typeof targetAny[key] === "object" &&
          !Array.isArray(targetAny[key]) &&
          !Array.isArray(value)
        ) {
          targetAny[key] = this.merge(targetAny[key], value);
        } else {
          targetAny[key] = value;
        }
      }

    return target as T & S;
  }

  static getConstructor<T>(target: any): Constructable<T> | undefined {
    const type = typeof target === "function" ? target : target?.constructor;
    return type !== Object ? type : void 0;
  }
}
