import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { Bulk } from "src/@prisma/js-proto";
import { isEmpty } from "src/@prisma/js-utils";
import { ApiHeader, ContextFactory, ContextOptFactory, IApiConfig } from "./interface";

export const API_CONFIG = new InjectionToken<Partial<IApiConfig>>("");

@Injectable()
export class ApiConfig implements IApiConfig {
  timeout: ContextOptFactory<number | undefined> = void 0;
  baseUrl: ContextOptFactory<string> = "/api";
  tokenKey = "alghorit@token";
  cacheDuration = 3e5;
  defaultHeader: ContextFactory<ApiHeader> = {};

  constructor(
    @Optional()
    @Inject(API_CONFIG) apiConfig?: Partial<IApiConfig>
  ) {
    if (!apiConfig) return;

    if (!isEmpty(apiConfig.baseUrl))       this.baseUrl       = apiConfig.baseUrl;
    if (!isEmpty(apiConfig.timeout))       this.timeout       = apiConfig.timeout;
    if (!isEmpty(apiConfig.tokenKey))      this.tokenKey      = apiConfig.tokenKey;
    if (!isEmpty(apiConfig.cacheDuration)) this.cacheDuration = apiConfig.cacheDuration;
    if (!isEmpty(apiConfig.defaultHeader)) this.defaultHeader = apiConfig.defaultHeader;
    if (!isEmpty(apiConfig.buildBody))     this.buildBody     = apiConfig.buildBody;
    if (!isEmpty(apiConfig.getToken))      this.getToken      = apiConfig.getToken;
    if (!isEmpty(apiConfig.setToken))      this.setToken      = apiConfig.setToken;
    if (!isEmpty(apiConfig.removeToken))   this.removeToken   = apiConfig.removeToken;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getToken(..._: Parameters<IApiConfig["getToken"]>): ReturnType<IApiConfig["getToken"]> {
    return localStorage.getItem(this.tokenKey);
  }

  setToken(...[ response ]: Parameters<IApiConfig["setToken"]>): ReturnType<IApiConfig["setToken"]> {
    localStorage.setItem(this.tokenKey, response.token);
  }

  removeToken(
    ...[ opts = this.tokenKey ]: Parameters<IApiConfig["removeToken"]>
  ): ReturnType<IApiConfig["removeToken"]>
  {
    localStorage.removeItem(opts);
  }

  buildBody(...[ context ]: Parameters<IApiConfig["buildBody"]>): ReturnType<IApiConfig["buildBody"]> {
    return context.payload instanceof Bulk ? context.payload.export() : context.payload;
  }
}
