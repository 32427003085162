import { FormatTaskTemplate } from "./task-template";

export class FormatUppercaseTask extends FormatTaskTemplate {
  static readonly singleton = new FormatUppercaseTask();

  override configure(): this {
    return FormatUppercaseTask.singleton as this;
  }

  override format(value: any): any {
    return typeof value === "string" ? value.toUpperCase() : value;
  }
}
