import { Injectable } from "@angular/core";
import { Bulk } from "src/@prisma/js-proto";
import { BulkFormatTask, BulkValidateTask, HttpTask, BodyBuilderTask, ParseParamsTask, GetCacheTask, PreRequestTask, RequestTask, EmitEventTask, ClearCacheTask, BulkImportTask } from "../tasks";
import { NgTaskContext } from "../tasks/task.context";
import { NgDefaultRunner } from "./default.runner";

@Injectable()
export class NgEventRunner extends NgDefaultRunner {
  protected override onInit(): void {
    this.reorder([
      BulkFormatTask.STAGE,
      BulkValidateTask.STAGE,
      HttpTask.STAGE,
      BodyBuilderTask.STAGE,
      ParseParamsTask.STAGE,
      GetCacheTask.STAGE,
      PreRequestTask.STAGE,
      RequestTask.STAGE,
      BulkImportTask.STAGE,
      ClearCacheTask.STAGE,
      EmitEventTask.STAGE
    ]);
  }

  override executeContext(
    context: NgTaskContext<Bulk>,
    stages: string[]
  ): Promise<NgTaskContext<Bulk>>
  {
    if (!context.options) context.options = {};
    if (this.RUNNER) context.options.event = this.RUNNER;
    return super.executeContext(context, stages);
  }
}
