import { Bulk } from "../core";
import { FormatTaskTemplate } from "../formatter";

export type ConstructableFormatTask<T extends FormatTaskTemplate = FormatTaskTemplate> =
  (new () => T) & typeof FormatTaskTemplate

export interface FormatContextOptions<T extends object = any> {
  event?: string | string[]
  bulk?:  Bulk<T>
  model?: T
}

export interface FormatOptions<Stage> {
  stages?: Stage[]
  event?:  string | string[]
}
