import { Field, HttpPath, Primary, Validate, pattern } from "src/@prisma/js-proto";
import { DateField } from "../decorators";

@HttpPath("auth")
export class AuthTokenModel {
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid UUID"), "ATK-0x001" ]))
  @Primary()
  public id?: string;

  @Field()
  public name?: string;

  @Field()
  public key?: string;

  @Field()
  public sha1?: string;

  @Field()
  public accessLevel?: number;

  @DateField()
  public createdAt?: Date;

  @DateField()
  public expiresAt?: Date;
}
