<mat-card class="form" [formGroup]="form" (keyup.enter)="login()">
  <mat-card-header>
    <mat-card-title><!--@i18n-->Access the platform<!----></mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-form-field>
      <mat-label><!--@i18n-->Identification:<!----></mat-label>
      <input matInput type="text" formControlName="identification" #id>
      <mat-error>{{ form.getError("identification") }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label><!--@i18n-->Password:<!----></mat-label>

      <input matInput formControlName="password" class="case-sensitive" #passwordInput
        [type]="hidePass ? 'password': 'text'" [mask]="form.getMask('password')">
      <mat-error>{{ form.getError("password") }}</mat-error>

      <button mat-icon-button matSuffix (click)="hidePass = !hidePass">
        <mat-icon>{{ hidePass ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    </mat-form-field>

    <button mat-raised-button color="primary" class="btn-enter" (click)="login()" [disabled]="inProcess">
      <!--@i18n-->Enter<!---->
    </button>
  </mat-card-content>
</mat-card>