import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { permissions } from "src/@prisma/js-common";
import { __ } from "src/@prisma/ng-i18n";
import { PoolingEventService } from "src/app/services/pooling-event.service";
import { UserService } from "src/app/services/user.service";
import { User } from "src/models/user.model";
import { TableField } from "src/utils/interfaces";

@Component({
  selector: "user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"]
})
export class UserListComponent implements OnDestroy {
  private subscription = new Subscription();

  fields: TableField<User>[] = [
    {
      key: "name",
      label: __("Name"),
      get: (user: User): string => user.name?.toUpperCase() || "-"
    },
    {
      key: "identification",
      label: __("Identification"),
      get: (user: User): string => user.identification?.toUpperCase() || "-"
    },
    {
      key: "deviceSerial",
      label: __("Device Serial"),
      get: (user: User): string => user.deviceSerial?.toUpperCase() || "-"
    },
    {
      key: "role",
      label: __("Permission"),
      get: (user: User): string => __(permissions.getFromCode(user.role)?.description || "")
    }
  ];

  constructor(
    public userService: UserService,
    poolingService:     PoolingEventService
  ) {
    poolingService.connect([ User ])
      .also(it => this.subscription.add(it));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
