import { SerializableContext, UnserializableContext } from "../core";

export type SerializableValue = string | boolean | number | null | undefined
export type SerializableArray = Array<Serializable>
export type Serializable      = SerializableValue | SerializableObject | SerializableArray

export type SerializableObject = {
  [ key: string ]: Serializable
}

export interface BeforeSerialize    { beforeSerialize():   void | Promise<void> }
export interface AfterSerialize     { afterSerialize():    void | Promise<void> }
export interface AfterUnserialize   { afterUnserialize():  void | Promise<void> }

export type Constructable<T> = new () => T
export type ConstructableAny<T = any> = new (...args: any[]) => T

export type ExtractType<T> = T extends any[] ?
  T[number] extends object ?
    (T[number] extends any[] ? never : T[number]) : never : T extends object ? T : never

export type ConstructableNode<T> = Constructable<ExtractType<T>>;

export interface UnserializeOptions { nullable?: boolean }

export type SimpleFieldType = "string" | "number" | "boolean"
export type MultiFieldTypes = (SimpleFieldType | null)[]

export type FieldType = SimpleFieldType | MultiFieldTypes

export interface FieldOptions<T> {
  type?:        FieldType | (() => ConstructableNode<T>)
  array?:       boolean
  /**
   * Accept a JSON value. It must be `Serializable`.
   *
   * @example
   * ```typescript
   * class HelloWorld {
   *   json: any = { hello: "world", foo: { bar: "baz" } }
   * }
   * ```
   */
  json?:        boolean
  serialize?:   (context: SerializableContext<T>) => Serializable | Promise<Serializable>
  unserialize?: (context: UnserializableContext<T>) => T | Promise<T>
  /** Name to be used when serialized. */
  alias?:       string;
  /** Accept `null` value when serializing. Default `false` */
  nullable?:    boolean;
  /**
   * Exclude field from serialize/unserialize.
   */
  exclude?:     boolean
}
