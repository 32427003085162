<div class="dialog-body-content">
  <div class="item">
    <div class="label">
      <!--@i18n-->Backend software version:<!---->
    </div>
    <div class="value">
      {{ backendVersion }}
    </div>
  </div>

  <div class="item">
    <div class="label">
      <!--@i18n-->Frontend software version:<!---->
    </div>
    <div class="value">
      {{ frontendVersion }}
    </div>
  </div>
  <hr>

  <div class="item">
    <div class="label">
      <!--@i18n-->User Name:<!---->
    </div>
    <div class="value">
      {{ user?.name || "..." }}
    </div>
  </div>

  <div class="item">
    <div class="label">
      <!--@i18n-->User Identification:<!---->
    </div>
    <div class="value">
      {{ user?.identification || "..." }}
    </div>
  </div>

  <div class="buttons">
    <button mat-raised-button color="accent" (click)="password()">
      <mat-icon class="material-icons-outlined">key</mat-icon>
      <span> <!--@i18n-->Password<!----> </span>
    </button>

    <button mat-raised-button color="warn" (click)="logout()">
      <mat-icon class="material-icons-outlined">logout</mat-icon>
      <span> <!--@i18n-->Logout<!----> </span>
    </button>
  </div>
</div>
