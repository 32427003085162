import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UserFormComponent } from "./form/user-form.component";
import { UserListComponent } from "./list/user-list.component";
import { UsersComponent } from "./users.component";

const routes: Routes = [
  {
    path: "",
    component: UsersComponent,
    children: [
      { path: "list", component: UserListComponent },
      { path: "form", component: UserFormComponent },

      { path: "", redirectTo: "list", pathMatch: "full" },
      { path: "**", redirectTo: "list" }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [ RouterModule ]
})
export class UsersRoutingModule {}
