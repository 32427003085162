import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GroupFormComponent } from "./form/group-form.component";
import { GroupComponent } from "./group.component";
import { GroupListComponent } from "./list/group-list.component";

const routes: Routes = [
  {
    path: "", component: GroupComponent,
    children: [
      { path: "list", component: GroupListComponent },
      { path: "form", component: GroupFormComponent },

      { path: "", redirectTo: "list", pathMatch: "full" },
      { path: "**", redirectTo: "list" }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [ RouterModule ]
})
export class GroupRoutingModule {}
