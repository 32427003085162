import { Injectable } from "@angular/core";
import { Bulk, RunnerPool } from "../../js-proto";
import { NgEventPool } from "../pools/event.pool";
import { NgDefaultTask } from "./default.task";

@Injectable()
export class EmitEventTask extends NgDefaultTask {
  static STAGE = "emit-event";
  MAP = { [EmitEventTask.STAGE]: this };

  constructor() {
    super(context => {
      context.beforeOutput(resp => {
        const options = context.requireOptions();
        const payload = context.payload;
        if (options.emit !== false && !options.hasCache && payload instanceof Bulk)
          NgEventPool.emit(payload.type, options.event || RunnerPool.DEFAULT, resp, payload);
      });
    });
  }
}
