import { Field, HttpPath, Primary, Validate, pattern } from "src/@prisma/js-proto";
import { DateField } from "../decorators";

@HttpPath("connection")
export class ConnectionModel {
  @Primary()
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid UUID"), "CON-0x001" ]))
  public id?: string;

  @Field()
  public deviceSerial?: string;

  @DateField()
  public connectedAt?: Date;

  @DateField()
  public disconnectedAt?: Date;

  @DateField()
  public createdAt?: Date;
}
