import { Field } from "../../js-proto";
import { UserModel } from "../models";

export function AuthorField(): PropertyDecorator {
  return Field<UserModel | void>({
    serialize: context => context.value ? {
      id:             context.value.id,
      name:           context.value.name,
      identification: context.value.identification,
      deviceSerial:   context.value.deviceSerial
    } : void 0,
    type: () => UserModel
  });
}
