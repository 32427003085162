<div class="form-container">
  <div class="form">
    <ng-container [ngTemplateOutlet]="templateRef" *ngIf="component.form"></ng-container>
  </div>

  <div class="buttons">
    <button mat-raised-button class="btn-back" (click)="backButtonClick()" [disabled]="inProcess">
      <!--@i18n-->Back<!---->
    </button>

    <div class="btn-del" [matTooltip]="disableDeleteMessage || ''"
      [matTooltipDisabled]="!disableDelete || !disableDeleteMessage">
      <button mat-raised-button  *ngIf="component.editing" (click)="deleteButtonClick()"
        [disabled]="inProcess || disableDelete" [color]="inProcess || disableDelete ? '': 'warn'">
        <!--@i18n-->Delete<!---->
      </button>
    </div>


    <!--__(Save)-->
    <!--__(Edit)-->
    <button mat-raised-button color="success" class="btn-save"
      (click)="saveButtonClick()"  [disabled]="inProcess">{{ btnText }}</button>
  </div>
</div>

