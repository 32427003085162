import { Injectable, NgModule } from "@angular/core";
import { DateAdapter, MatNativeDateModule, NativeDateAdapter } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgI18n } from "src/@prisma/ng-i18n";

@Injectable()
export class I18nDateAdapter extends NativeDateAdapter {
  constructor(
    ngi18n: NgI18n
  ) {
    super(ngi18n.getCurrent() || ngi18n.getDefault() || "en-US");
    ngi18n.setOnSwitch(lang => {
      this.setLocale(lang);
    });
  }
}

@NgModule({
  imports: [
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    { provide: DateAdapter, useClass: I18nDateAdapter }
  ],
  exports: [
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class I18nDatePickerModule {}
