import { Field, Format, HttpPath, Primary, Validate, format, pattern } from "src/@prisma/js-proto";
import { DateField } from "../../decorators";
import { PoolGroupDeviceModel } from "./group-device.model";

@HttpPath("plugin/pool/group")
export class PoolGroupModel {
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid UUID"), "POL-GRP-0x001" ]))
  @Primary()
  public id?: string;

  @Validate("create", [
    pattern("required", [ /*@i18n*/("Group name not informed"), "POL-GRP-C-0x001" ])
  ])
  @Validate([
    pattern("min", [ /*@i18n*/("Group name too short"), "POL-GRP-0x002" ], 1),
    pattern("max", [ /*@i18n*/("Group name too long"), "POL-GRP-0x003" ], 60)
  ])
  @Format([
    format("trim"),
    format("lowercase")
  ])
  @Field()
  public name?: string;

  @Validate([
    pattern("min", [ /*@i18n*/("Group TAG too short"), "POL-GRP-0x004" ], 1),
    pattern("max", [ /*@i18n*/("Group TAG too long"), "POL-GRP-0x005" ], 30)
  ])
  @Format([
    format("trim"),
    format("lowercase")
  ])
  @Field()
  public tag?: string;

  @Field()
  public enabled?: boolean;

  @Field({ array: true, type: () => PoolGroupDeviceModel })
  public devices?: PoolGroupDeviceModel[];

  @DateField()
  public createdAt?: Date;
}
