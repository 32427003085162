import { AfterViewInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgI18n } from "src/@prisma/ng-i18n";
import { NgEventPool } from "src/@prisma/ng-runner";
import { User } from "src/models/user.model";
import { AuthGuard } from "./guard/app.guard";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements AfterViewInit {
  constructor(
    private ngi18n: NgI18n,
    router: Router
  ) {
    AuthGuard.setGuardedPaths([
      { path: /^\/pages(\/.*|\?.*|)/i, permissions: [ "OPERATOR" ] },
      { path: /^\/pages\/reports(\/.*|\?.*|)/i, permissions: [ "ADMIN" ], navigate: "/" },
      { path: /^\/pages\/group(\/.*|\?.*|)/i, permissions: [ "ADMIN" ], navigate: "/" },
      { path: /^\/pages\/users(\/.*|\?.*|)/i, permissions: [ "ADMIN" ], navigate: "/" }
    ]);
    NgEventPool.on(User, "auth", () => {
      router.navigate([ "/" ]);
    });
  }

  ngAfterViewInit(): void {
    document.querySelector("body")?.also(e => this.ngi18n.startAtElement(e));
  }
}
