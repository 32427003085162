import { ContextOptions } from "../interfaces";
import { Task } from "./task";
import { TaskContext } from "./task-context";

export class TaskCallback<
  T = any,
  O = void,
  TOptions extends ContextOptions = {},
  Context extends TaskContext<T, O, TOptions> = TaskContext<T, O, TOptions>>
  extends Task<T, O, TOptions, Context>
{
  constructor(protected cb: (context: Context) => any) {
    super();

    this.onInit?.();
  }

  protected onInit?(): void;

  async executeContext(context: Context): Promise<Context> {
    await this.cb(context);
    return context;
  }
}
