import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild, ViewContainerRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DefaultDialogOptions } from "src/utils/interfaces";

@Component({
  selector: "app-default-dialog",
  templateUrl: "./default-dialog.component.html",
  styleUrls: ["./default-dialog.component.scss"]
})
export class DefaultDialogComponent<T = any> implements AfterViewInit {
  @ViewChild("content", { read: ViewContainerRef })
  private content!: ViewContainerRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) private opts: DefaultDialogOptions<T>,
    private dialog: MatDialogRef<DefaultDialogComponent>,
    private cd: ChangeDetectorRef
  ) {
    this.opts.cd = cd;
  }

  get title(): string {
    return this.opts.title || "";
  }

  get icon(): string {
    return this.opts.icon || "";
  }

  get button(): boolean {
    return !!this.opts.btnClose;
  }

  btnClick(): void {
    this.opts.btnClose?.();
  }

  ngAfterViewInit(): void {
    this.content.createComponent(this.opts.component);
    this.cd.detectChanges();
  }

  close(): void {
    this.dialog.close();
  }

  getData(): T | undefined {
    return this.opts.data;
  }
}
