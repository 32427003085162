import { Runner } from "./runner";
import { ContextOptions, RunnerExecutionRule } from "../interfaces";
import { TaskContext } from "./task-context";

const VALID_EXECUTION_RULES: ReadonlyArray<RunnerExecutionRule> = [ "concurrent", "queue", "single" ];

export class RunnerQueue<
  T = any,
  O = any,
  TOptions extends ContextOptions = {},
  Stage extends string = string,
  Context extends TaskContext<T, O, TOptions> = TaskContext<T, O, TOptions>>

  extends Runner<T, O, TOptions, Stage, Context>
{
  private executionRule: RunnerExecutionRule = "concurrent";

  private queue: (() => Promise<any>)[] = [];
  private busyCount = 0;

  private assertExecutionRule(rule: RunnerExecutionRule): RunnerExecutionRule {
    if (!VALID_EXECUTION_RULES.includes(rule))
      throw new Error(`Execution rule '${rule}' is not a valid rule`);

    return rule;
  }

  /**
   * Increase number of active tasks.
   */
  protected block(): void { this.busyCount++; }

  /**
   * Decrease number of active tasks.
   */
  protected release(): void { this.busyCount--; }

  /**
   * Run the next context in the queue.
   */
  async next(): Promise<void> {
    if (this.queue.length === 0 || (this.executionRule === "queue" && this.isBusy())) return;
    this.block();

    try {
      const fun = this.queue.shift(); if (!fun) return;
      if (this.executionRule === "concurrent") fun().catch(console.error);
      else await fun();
    } catch (e) {
      console.error(e);
    } finally {
      this.release();
      this.next();
    }
  }

  /**
   * Execute runner with a specified context. Avoid using the same context for multiples executions
   * unless you know what are you doing.
   */
  override executeContext(context: Context, stages?: Stage[]): Promise<Context> {
    const rule = this.executionRule;

    if (rule === "single" && this.isBusy())
      return new Promise(resolve => (context.stop(), resolve(context)));

    this.block();

    const promise = new Promise<Context>(async (resolve, reject) => {
      this.queue.push(async () => {
        try {
          resolve(await super.executeContext(context, stages));
        } catch (e) {
          reject(e);
        } finally {
          if (rule === "single") this.release();
        }
      });

      if (rule !== "single") this.release();
    });

    return (this.next(), promise);
  }

  /**
   * Check if runner has active tasks.
   */
  isBusy(): boolean { return this.busyCount > 0; }

  /**
   * Set how the runner will execute the tasks.
   *  * `concurrent` - All tasks will be executed at the moment `execute` is called. (Default)
   *  * `queue` - Only one `execute` call will be executed at time. Successive calls will be added to the
   * queue.
   *  * `single` - Only one `execute` call will be executed at time. Successive calls will be ignored and
   * set context as skipped.
   */
  setExecutionRule(rule: RunnerExecutionRule): this {
    this.executionRule = this.assertExecutionRule(rule);
    return (this.next(), this);
  }
  getExecutionRule(): RunnerExecutionRule { return this.executionRule; }
}
