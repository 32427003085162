import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { permissions } from "src/@prisma/js-common";
import { DeviceService } from "src/app/services/device.service";
import { PoolingEventService } from "src/app/services/pooling-event.service";
import { UserService } from "src/app/services/user.service";
import { DefaultCreateComponent } from "src/app/utils/default-create.component";
import { Device } from "src/models/device.model";
import { User } from "src/models/user.model";

@Component({
  selector: "user-form",
  templateUrl: "./user-form.component.html",
  styleUrls: ["./user-form.component.scss"]
})
export class UserFormComponent extends DefaultCreateComponent<User, UserService> {
  private subscription = new Subscription();
  private allDevices: Device[] = [];

  roles = permissions.list();
  inputConnected = false;
  socketConnected = false;
  disableDeleteMessage?: string;
  inputTimeout?: ReturnType<typeof setTimeout>;
  devices: Device[] = [];

  private async updateDevices(): Promise<void> {
    const users = await this.userService.get();
    const id = this.form.controls.id.value;

    this.devices = this.allDevices.filter(
      e => !users.filter(u => u.id !== id).find(u => u.deviceSerial === e.serial)
    );
  }

  constructor(
    private userService:  UserService,
    deviceService:        DeviceService,
    router:               Router,
    route:                ActivatedRoute,
    poolingService:       PoolingEventService
  ) {
    super(userService, router, route, {
      createText: /*@i18n*/("User created successfully!"),
      deleteText: /*@i18n*/("User deleted successfully!"),
      updateText: /*@i18n*/("User updated successfully!"),
      formConfig: {}
    });

    poolingService.connect([ Device ])
      .also(it => this.subscription.add(it));

    deviceService.observe().subscribe(devices => {
      this.allDevices = devices;
      this.updateDevices();
    }).also(it => this.subscription.add(it));
  }

  override async formInit(itemId?: string): Promise<void> {
    const user = await this.service.self();
    this.disableDeleteMessage = user.id === itemId ? /*@i18n*/("You cannot delete yourself") : void 0;
    await super.formInit(itemId);
    this.updateDevices();
    if (this.disableDeleteMessage) this.form.controls.role.disable();
  }

  togglePass(input: HTMLInputElement): void {
    input.type = input.type === "password" ? "text" : "password";
  }

  passVisibility(input: HTMLInputElement): string {
    return input.type === "password" ? "visibility_off" : "visibility";
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  override async save(): Promise<boolean> {
    this.form.controls.role.enable();
    return super.save();
  }

  hasDevice(): boolean {
    return !!this.form.controls.deviceSerial.value;
  }

  removeDevice(event: Event): void {
    event.stopImmediatePropagation();
    this.form.controls.deviceSerial.setValue(null);
  }
}
