import { Inject, InjectionToken, Optional, Pipe, PipeTransform } from "@angular/core";

export const DEFAULT_TIME_FORMAT = new InjectionToken<string>("DefaultTimeFormat");

export enum TimeUnits {
  milliseconds = "milli", seconds = "sec", minutes = "min"
}

export type TimeUnitsValues = `${ TimeUnits }`;

@Pipe({
  name: "time"
})
export class TimePipe implements PipeTransform {
  constructor(
    @Optional() @Inject(DEFAULT_TIME_FORMAT)
    private defaultFormat = ""
  ) {}

  transform(
    value: number,
    unit: TimeUnits | TimeUnitsValues = TimeUnits.milliseconds,
    format = this.defaultFormat
  ): string {
    let milliseconds = value;

    if (unit === TimeUnits.seconds) {
      milliseconds *= 1000;
    }
    if (unit === TimeUnits.minutes) {
      milliseconds *= 60;
    }
    const hours = Math.floor(milliseconds / (3600 * 1000));
    milliseconds -= hours * 3600 * 1000;

    const minutes = Math.floor(milliseconds / (60 * 1000));
    milliseconds -= minutes * 60 * 1000;

    const seconds = Math.floor(milliseconds / 1000);
    milliseconds -= seconds * 1000;

    return format
      .replace(/HH/g,  hours.toString().padStart(2, "0"))
      .replace(/H/g,   `${ hours }`)
      .replace(/mm/g,  minutes.toString().padStart(2, "0"))
      .replace(/m/g,   `${ minutes }`)
      .replace(/ss/g,  seconds.toString().padStart(2, "0"))
      .replace(/s/g,   `${ seconds }`)
      .replace(/SSS/g, milliseconds.toString().padStart(3, "0"))
      .replace(/SS/g,  Math.round(milliseconds / 10).toString().padStart(2, "0"))
      .replace(/S/g,   `${ Math.round(milliseconds / 100) }`);
  }
}
