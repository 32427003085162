import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[ngLet]",
})
export class NgLetDirective<T extends Record<string, any>> {
  @Input()
  set ngLet(context: T) {
    const _context: any = context;
    if (!this.context) this.context = {};

    this.context.$implicit = this.context.ngLet = _context;

    if (context) {
      delete _context.$implicit;
      delete _context.ngLet;

      for (const key in context) {
        this.context[key] = _context[key];
      }
    }

    if (!this.hasView) {
      this.vcRef.createEmbeddedView(this.templateRef, this.context);
      this.hasView = true;
    }
  }

  private context?: Partial<{ $implicit: T, ngLet: T } & T> = {
  };

  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef
  ) {}
}
