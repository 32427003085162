import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "src/app/services/user.service";
import { ConfirmDialogComponent } from "src/app/utils/confirm-dialog/confirm-dialog.component";
import { DefaultDialogComponent } from "src/app/utils/default-dialog/default-dialog.component";
import { User } from "src/models/user.model";
import { ConfirmDialogData, DefaultDialogOptions } from "src/utils/interfaces";
import { PasswordComponent } from "./password/password.component";
import { SYS_VERSION } from "src/app/version";
import { AboutService } from "src/app/services/about.service";

@Component({
  selector: "app-options",
  templateUrl: "./options.component.html",
  styleUrls: ["./options.component.scss"]
})
export class OptionsComponent implements OnInit, AfterViewInit {
  cd: ChangeDetectorRef;
  user?: User;
  backendVersion = "";
  frontendVersion = SYS_VERSION;

  constructor(
    @Inject(MAT_DIALOG_DATA) private opts: DefaultDialogOptions<OptionsComponent>,
    private dialogRef:      MatDialogRef<DefaultDialogComponent>,
    private dialog:         MatDialog,
    private userService:    UserService,
    private about:          AboutService,
    cd:                     ChangeDetectorRef
  ) {
    this.cd = opts.cd || cd;
    userService.self().then(e => this.user = e);
  }

  async ngOnInit(): Promise<void> {
    this.backendVersion = (await this.about.get()).version;
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  close(): void {
    this.dialogRef.close();
  }

  password(): void {
    this.dialog.open(DefaultDialogComponent, {
      width: "300px",
      maxWidth: "90%",
      data: <DefaultDialogOptions>{
        component: PasswordComponent,
        title: /*@i18n*/("Change password"),
        icon: "password"
      }
    });
  }

  logout(): void {
    this.dialog.open(DefaultDialogComponent, {
      data: <DefaultDialogOptions<ConfirmDialogData>>{
        title: /*@i18n*/("System Logout"),
        icon: "logout",
        component: ConfirmDialogComponent,
        data: {
          text: /*@i18n*/("Do you really want to logout the system?"),
          btnText: {
            yes: /*@i18n*/("Logout"),
            no: /*@i18n*/("Cancel")
          },
          confirm: async () => {
            await this.userService.logout();
          }
        }
      }
    });
  }
}
