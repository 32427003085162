import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { I18n, I18nConfiguration, I18nGetter } from "../../js-i18n";
import { NgI18nConfiguration } from "../interface";
import { NgI18nTranslator } from "../ngi18n-translator.class";

export const I18N_CONFIG = new InjectionToken<I18nConfiguration<string>>("");

@Injectable()
export class NgI18n<L extends string = string> extends I18n<L> {
  private static readonly LANG_KEY = "alghorit@lang";

  static get: I18nGetter = text => text;

  static globalGetter() {
    return (text: string, ...args: (string | number)[]): string => NgI18n.get(text, ...args);
  }

  private switchListeners: (((lang?: string) => any) | undefined)[] = [];

  constructor(@Optional() @Inject(I18N_CONFIG) config: NgI18nConfiguration<L> = {}) {
    super(config);

    const current = this.getLangFromStorage();
    if (current) this.switch(current);

    const get = this.getter();
    const simpleGet = this.simpleGetter();

    NgI18n.get = (text, ...args): string => {
      return args.length ? get(text, ...args) : simpleGet(text);
    };
  }

  startAtElement(element: HTMLElement): NgI18nTranslator {
    return new NgI18nTranslator(element, this).init();
  }

  private getLangFromStorage(): L | undefined {
    const locale = localStorage.getItem(NgI18n.LANG_KEY);

    return locale && this.hasLocale(locale) ? locale : void 0;
  }

  override switch(locale: L, opts?: { emit: boolean }): this {
    super.switch(locale);

    const current = this.getCurrent();

    if (current) localStorage.setItem(NgI18n.LANG_KEY, current);
    else localStorage.removeItem(NgI18n.LANG_KEY);

    if (opts?.emit !== false && this.switchListeners) this.emitSwitch();

    const html = document.querySelector("html");

    if (html) html.lang = this.getCurrent() || this.getDefault() || "en";

    return this;
  }

  getHeader(): string {
    const langs = [ this.getCurrent(), this.getDefault(), this.getRetry() ].filter(e => e) as L[];
    return langs.reduce((acc: Record<string, boolean>[], e) => {
      const [ code, region ] = e.split("-");
      const save = acc.find(o => o[code]);
      if (save && region) {
        delete save[code];
        save[e] = true;
        save[code] = true;
      } else {
        if (region) acc.push({
          [e]: true,
          [code]: true
        });
        else {
          acc.push({ [code]: true });
        }
      }
      return acc;
    }, []).map((e, i) => `${Object.keys(e).join(",")};q=0.${9 - i}`).join(",");
  }

  emitSwitch(): void {
    this.switchListeners.forEach((cb) => cb?.(this.getCurrent() || this.getDefault()));
  }

  removeSwitchListener(index: number): void {
    delete this.switchListeners[index];
  }

  setOnSwitch(cb: (lang?: string) => any): number {
    for (let i = 0; i <= this.switchListeners.length; i++) {
      if (!this.switchListeners[i]) {
        this.switchListeners[i] = cb;
        return i;
      }
    }
    return -1;
  }
}

export const __ = NgI18n.globalGetter();
