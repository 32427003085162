import { ValidationContext } from "../interfaces";
import { ValidationTaskTemplate } from "./task-template";

export class ValidateDateTask extends ValidationTaskTemplate {
  override configure(): this {
    return this;
  }

  override async validate(context: ValidationContext): Promise<void> {
    const value = context.payload;

    if (value !== void 0 && value !== null && (!(value instanceof Date) || isNaN(value.getTime())))
      throw this.getFieldError();
  }
}
