import { MetadataManager, Proto } from "../core";
import { HttpConfig, HttpMetadata, HttpMethods } from "../metadata/http";

/**
 * @deprecated Use `HttpMetadata.getHttpConfig` instead.
 */
export function getHttpConfig(target: Function | object): HttpMetadata | undefined
/**
 * @deprecated Use `HttpMetadata.getHttpConfig` instead.
 */
export function getHttpConfig(target: Function | object, event: string): HttpConfig | undefined
/**
 * @deprecated Use `HttpMetadata.getHttpConfig` instead.
 */
export function getHttpConfig(
  target: Function | object, event?: string): HttpMetadata | HttpConfig | undefined
export function getHttpConfig(
  target: Function | object, event?: string
): HttpMetadata | HttpConfig | undefined
{
  return HttpMetadata.getHttpConfig(target, event);
}

/**
 * @deprecated Use `HttpMetadata.forceHttpConfig` instead.
 */
export function forceHttpConfig(target: Function | object): HttpMetadata
/**
 * @deprecated Use `HttpMetadata.forceHttpConfig` instead.
 */
export function forceHttpConfig(target: Function | object, event: string): HttpConfig
/**
 * @deprecated Use `HttpMetadata.forceHttpConfig` instead.
 */
export function forceHttpConfig(target: Function | object, event?: string): HttpMetadata | HttpConfig
export function forceHttpConfig(target: Function | object, event?: string): HttpMetadata | HttpConfig {
  return HttpMetadata.forceHttpConfig(target, event);
}

export function HttpPath(path: string): ClassDecorator {
  return (target): void => {
    const metadata = HttpMetadata.forceHttpConfig(target);
    metadata.path = path;

    MetadataManager.set(target, metadata);
  };
}

export function HttpEvent(event: string, path?: string, method?: HttpMethods): ClassDecorator
export function HttpEvent(event: string, options?: Partial<HttpConfig>): ClassDecorator
export function HttpEvent(
  event: string, pathOrOptions?: string | Partial<HttpConfig>, method?: HttpMethods
): ClassDecorator
export function HttpEvent(
  event: string, pathOrOptions?: string | Partial<HttpConfig>, method?: HttpMethods
): ClassDecorator
{
  return (target): void => {
    const metadata = HttpMetadata.forceHttpConfig(target);
    const config: Partial<HttpConfig> = typeof pathOrOptions !== "object" ? {
      path:   pathOrOptions,
      method: method
    } : pathOrOptions;

    metadata.events[event] = Proto.merge(
      metadata.events[event] || HttpMetadata.defaultHttpConfig(), config, { overwriteArrays: true });

    MetadataManager.set(target, metadata);
  };
}

export function HttpAuth(
  pathOrOptions?: string | Partial<HttpConfig>, method?: HttpMethods
): ClassDecorator {
  if (!pathOrOptions) pathOrOptions = "/auth";
  else if (typeof pathOrOptions === "object") pathOrOptions.path ??= "/auth";
  return HttpEvent("auth", pathOrOptions, method);
}
