import { NgModule } from "@angular/core";
import { NgI18nDirective } from "./ng-i18n.directive";

@NgModule({
  declarations: [
    NgI18nDirective
  ],
  exports: [
    NgI18nDirective
  ]
})
export class NgI18nModule {}
