import { Injectable } from "@angular/core";
import { MessageService } from "./message.service";


export enum ReportType {
  MESSAGE = "message",
}

@Injectable({
  providedIn: "root"
})
export class ReportService {
  constructor(
    private messageService: MessageService,
  ) {}

  async get(type: ReportType, options: { low: number, high: number }): Promise<any[]> {
    const { low, high } = options;

    switch (type) {
      case ReportType.MESSAGE:
        return await this.messageService.get({
          filterOptions: {
            relations: ["createdByUser", "group", "user"]
          },
          filter: {
            createdAt: { $bt: [ low, high ] }
          },
          force: true
        });
    }
  }
}
