import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { __ } from "src/@prisma/ng-i18n";
import * as common from "src/@prisma/js-common";
import { environment } from "src/environments/environment";
import { LOCAL_STORAGE_KEYS } from "./storage-keys";
import moment = require("moment-timezone");
import { Exception, isDateSeed } from "src/@prisma/js-utils";

export const companyLogoSrc = `${environment.baseUrl}/company/logo`;

/**
 * Get CSV sep config from storage
 */
export function getCSVSep(): string {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.CSV_SEP) || ";";
}

/**
 * Set CSV sep config on storage
 */
export function setCSVSep(sep: string): void {
  localStorage.setItem(LOCAL_STORAGE_KEYS.CSV_SEP, sep);
}

/**
 * Returns default value of table length
 */
export function getTablePageLength(): number {
  return parseInt(localStorage.getItem(LOCAL_STORAGE_KEYS.PAG_SIZE) || "10");
}

/**
 * Set default length of table page
 * @param size
 */
export function setTablePageLength(size: number): void {
  localStorage.setItem(LOCAL_STORAGE_KEYS.PAG_SIZE, size.toString());
}

export function downloadCSV(
  data: string, filename: string
): void {
  const blob = new Blob([ data ], { type: "csv" });

  const link = document.createElement("a");

  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename.replace(/\//g, "-") + ".csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 6e4);
}

type KeyValuePair<T, R, K = keyof T> = {
  key?: K
  keys?: K[]
  value: R
};

/**
 * Check if any key exists in the record, and return an specific value
 * @param src Record to check if have key/keys
 * @param keysToCheck key-value pair array to check if exists on src
 * @returns selected value if exists
 */
export function getValueWhenHaveKey<R, T = Record<string, any>>(
  src: T, keysToCheck: KeyValuePair<T, R>[]
): R | undefined {
  for (const item of keysToCheck) {
    if (!item.keys) item.keys = [];
    if (item.key) item.keys.push(item.key);
    if (item.keys?.find(k => (src as any)?.[k])) return item.value;
  }
}

export function getFileUrl(id: string): string {
  return `${environment.baseUrl}/file/${id}`;
}

export function getAssetsImage(filename: string): string {
  return `assets/images/${ filename }`;
}

export function formatDate(seed?: moment.Moment | Date | string | number): string {
  return moment(seed).format(__("MM/DD/YYYY"));
}

export function formatTime(seed?: moment.Moment | Date | string | number, sec = true): string {
  return moment(seed).format(sec ? __("HH:mm:ss") : __("HH:mm"));
}

export function formatDateTime(seed?: moment.Moment | Date | string | number, sec = true): string {
  return moment(seed).format(sec ? __("MM/DD/YYYY HH:mm:ss") : __("MM/DD/YYYY HH:mm"));
}

export function validatorMessage(validator: ValidatorFn, message: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (validator(control)) {
      return { message };
    }
    return null;
  };
}

export function getErrorMessage(control: AbstractControl, field = "message"): string {
  return control.errors?.[field] || "";
}

export function getTimeFrom(seed: unknown): number {
  if (moment.isMoment(seed)) {
    return seed.valueOf();
  }
  if (seed instanceof Date) {
    return seed.valueOf();
  }
  if (isDateSeed(seed)) {
    return moment(seed).valueOf();
  }
  if (typeof seed === "number") {
    return seed;
  }
  return 0;
}

export function secondsToTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  return [ hours, minutes, seconds ].map(e => `${ e < 10 ? "0" : "" }${ e }`).join(":");
}

export function handleFileSelect(
  input: HTMLInputElement,
  opts?: {
    maxSize?: number
    accept?:  string[]
  }
): Promise<string | undefined> {
  const file = input.files?.item(0);
  const maxSize = opts?.maxSize ?? 2048;
  const types = opts?.accept;

  return new Promise((resolve, reject) => {
    if (file) {
      // File limit test
      if (file.size / 1024 > maxSize) {
        reject(new Exception(__/*@i18n*/("File size too large (Max %s%s)", ...(maxSize > 1024
          ? [ (maxSize / 1024).toPrecision(2), __("mb")  ]
          : [ (maxSize).toString(), __("kb") ]
        )), "E_FILE_SIZE"));
      } else {
        if (types) {
          if (!types.includes(file.type)) {
            return reject(new Exception(/*@i18n*/("Invalid file type"), "E_FILE_TYPE"));
          }
        }
        const reader = new FileReader();

        reader.onload = (readerEvt): void => {
          const result = readerEvt.target?.result;
          if (typeof result === "string")
            resolve(`data:${file.type};base64,${window.btoa(result)}`);
        };

        reader.readAsBinaryString(file);
      }
    }

    input.value = "";
  });
}

export function getCommon(key: string, ...replaces: any[]): keyof typeof common | undefined {
  replaces.forEach(r => {
    const replace = typeof r === "string" ? r : `${ r }`;
    key = key.replace("%%%", replace.padStart(3, "0"));
    key = key.replace("%%", replace.padStart(2, "0"));
    key = key.replace("%", replace);
  });
  return (common as any)[key];
}

export function parseObject<T extends object>(value: string): T {
  return JSON.parse(value);
}

export function timeStringToDate(base: moment.MomentInput, time: string): number {
  const parts = time.split(":").map(e => +e);
  const day = moment(base).startOf("day");

  parts.forEach((value, index) => {
    const key = (<moment.unitOfTime.All[]>[ "s", "m", "h" ])[2 - index];
    day.set(key, value);
  });
  return day.valueOf();
}

export function dateBetween<T>(date: Date | number, intervals: {
  start:  Date | string,
  end?:   Date | string,
  value:  T
}[]): T | undefined {
  const dt = date instanceof Date ? date.valueOf() : date;
  return intervals
    .map(e => ({
      start: e.start instanceof Date ? e.start.valueOf() : timeStringToDate(dt, e.start),
      end: typeof e.end === "string" ? timeStringToDate(dt, e.end) : e.end?.valueOf() ?? Date.now(),
      value: e.value
    }))
    .find(e => dt >= e.start && dt <= e.end)?.value;
}
