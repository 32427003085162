import { Injectable } from "@angular/core";
import { Bulk, Constructable, ConstructableNode } from "src/@prisma/js-proto";
import { RunnerPool } from "src/@prisma/js-proto/runner/pool";
import { NgPoolEventFN, NgPoolEventIndex, NgPoolListeners, NgRunnerOptions } from "../interface";
import { NgEventCreateRunner } from "../runners/event-create.runner";
import { NgEventRunner } from "../runners/event.runner";
import { NgEventDeleteRunner } from "../runners/event-delete.runner";
import { NgEventReadRunner } from "../runners/event-read.runner";
import { NgEventUpdateRunner } from "../runners/event-update.runner";
import { NgTaskContext } from "../tasks/task.context";
import { NgEventAuthRunner } from "../runners";

@Injectable()
export class NgEventPool
  extends RunnerPool<string, Bulk, Promise<any>, NgRunnerOptions, NgTaskContext>
{
  private static listeners: NgPoolListeners = new NgPoolListeners();

  constructor(
    protected defaultRunner: NgEventRunner,
    protected createRunner: NgEventCreateRunner,
    protected updateRunner: NgEventUpdateRunner,
    protected deleteRunner: NgEventDeleteRunner,
    protected readRunner: NgEventReadRunner,
    protected authRunner: NgEventAuthRunner
  ) {
    super({
      ...defaultRunner.MAP,
      ...createRunner.MAP,
      ...updateRunner.MAP,
      ...deleteRunner.MAP,
      ...readRunner.MAP,
      ...authRunner.MAP
    });

    this.setDefaultContext(NgTaskContext);
  }

  async run<Resp = any, Model extends object = any>(
    event: string, value: Constructable<Model> | Bulk<Model>, opts: NgRunnerOptions<Model> = {}
  ): Promise<Resp> {
    if (!this.get(event)) {
      opts.event ??= event;
      event = RunnerPool.DEFAULT;
    }
    if (typeof value === "function") value = new Bulk(value as ConstructableNode<Model>);
    return await (await this.execute(event, value, opts)).output;
  }

  async create<Resp = any, Model extends object = any>(
    value: Bulk<Model>, options: NgRunnerOptions = {}): Promise<Resp> {
    if (options.clearCache === void 0) options.clearCache = true;
    return await this.run("create", value, options);
  }

  async update<Resp = any, Model extends object = any>(
    value: Bulk<Model>, options: NgRunnerOptions = {}): Promise<Resp> {
    if (options.clearCache === void 0) options.clearCache = true;
    return await this.run("update", value, options);
  }
  async delete<Resp = any, Model extends object = any>(
    value: Bulk<Model>, options: NgRunnerOptions = {}): Promise<Resp> {
    if (options.clearCache === void 0) options.clearCache = true;
    return await this.run("delete", value, options);
  }

  async read<Resp = any, Model extends object = any>(
    value: Bulk<Model> | Constructable<Model>, options: NgRunnerOptions<Model> = {}): Promise<Resp> {
    if (typeof value === "function") value = new Bulk(value as ConstructableNode<Model>);
    if (options.import === void 0) options.import = true;
    return await this.run("read", value, options);
  }

  async auth<Resp = any, Model extends object = any>(
    value: Bulk<Model>, options: NgRunnerOptions<Model> = {}): Promise<Resp> {
    if (options.singleBodyData !== false) options.singleBodyData = true;
    return await this.run("auth", value, options);
  }

  static async emit<T extends object>(
    clazz:     Constructable<T> | ConstructableNode<T>,
    event:     string,
    payload:   Bulk<T>,
    response?: any
  ): Promise<void> {
    const listeners = this.listeners.get(clazz)?.[event] || [];
    for (const listener of listeners) await listener(payload, response, event);
  }

  static on<T extends object>(
    clazz: Constructable<T> | ConstructableNode<T>, event: string, fn: NgPoolEventFN<T>): NgPoolEventIndex
  static on<T extends object>(
    clazz: Constructable<T> | ConstructableNode<T>, event: string[], fn: NgPoolEventFN<T>): NgPoolEventIndex[]
  static on<T extends object>(
    clazz: Constructable<T> | ConstructableNode<T>, event: string | string[], fn: NgPoolEventFN<T>
  ): NgPoolEventIndex | NgPoolEventIndex[] {
    if (Array.isArray(event)) {
      return event.map(e => this.on(clazz, e, fn));
    } else {
      let listGroup = this.listeners.get(clazz);
      if (!listGroup) this.listeners.set(clazz, listGroup = {});

      let listeners = listGroup[event];
      if (!listeners) listeners = listGroup[event] = [];

      listeners.push(fn);
      return { group: clazz, event, fn };
    }
  }

  static removeListener(index: NgPoolEventIndex | NgPoolEventIndex[]): void {
    if (Array.isArray(index)) {
      index.forEach(i => this.removeListener(i));
    } else {
      const listeners = this.listeners.get(index.group)?.[index.event];
      if (listeners?.length) {
        listeners.splice(listeners.indexOf(index.fn), 1);
      }
    }
  }

  static removeAllListeners(): void {
    this.listeners.clear();
  }
}
