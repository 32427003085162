import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { __ } from "src/@prisma/ng-i18n";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { NgEventPool, NgRunnerOptions } from "src/@prisma/ng-runner";
import { Message } from "src/models/message.model";
import { DefaultService } from "./default.service";

@Injectable({
  providedIn: "root"
})
export class MessageService extends DefaultService<Message> {
  constructor(
    pool:   NgEventPool,
    feed:   MatFeed,
    dialog: MatDialog
  ) {
    super(Message, pool, feed, dialog, {
      name:        /*@i18n*/("Message"),
      pluralName:  /*@i18n*/("Messages"),
      deleteError: /*@i18n*/("No Message selected"),
      deleteName:  (item) => __("the message \"%s\"", item.id)
    });
    this.eventsToListen.push("confirm");
  }

  async confirm(id: string, opts?: NgRunnerOptions<Message>): Promise<void> {
    return await this.pool.run("confirm", Message, {
      emit: true,
      clearCache: true,
      queryParams: { id },
      ...opts
    });
  }
}
