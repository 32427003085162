import { ConstructableValidationTask, ValidationError, ValidationErrorTuple } from "src/@prisma/js-proto";
import { NgValidateDefaultTask } from "./default.task";
import { NgValidateEqualTask } from "./equal.task";

const NG_NATIVE_VALIDATION_TASKS = {
  equal: NgValidateEqualTask
};

export type NgNativeValidationTask = keyof typeof NG_NATIVE_VALIDATION_TASKS;

export function ngPatterns<T extends NgValidateDefaultTask>(
  task: ConstructableValidationTask<T>,
  errorOrMessage: ValidationError | ValidationErrorTuple | string,
  ...args: Parameters<T["configure"]>
): T
export function ngPatterns<K extends NgNativeValidationTask>(
  nativeKey: K,
  errorOrMessage: ValidationError | ValidationErrorTuple | string,
  ...args: Parameters<InstanceType<typeof NG_NATIVE_VALIDATION_TASKS[K]>["configure"] >
): InstanceType<typeof NG_NATIVE_VALIDATION_TASKS[K]>

export function ngPatterns(
  taskOrNativeKey: ConstructableValidationTask | NgNativeValidationTask,
  errorOrMessage: ValidationError | ValidationErrorTuple | string,
  ...args: any[]
): NgValidateDefaultTask {
  if (typeof taskOrNativeKey === "function")
    return new taskOrNativeKey(errorOrMessage).configure(...args);
  else
    return (
      new NG_NATIVE_VALIDATION_TASKS[taskOrNativeKey](errorOrMessage) as NgValidateDefaultTask
    )
      .configure(...args);
}
