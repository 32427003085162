import { Field, format, Format, HttpEvent, HttpPath, pattern, Primary, Validate } from "src/@prisma/js-proto";
import { AuthorField } from "../decorators/author-field";
import { DateField, DateFieldValidation } from "../decorators/date-field";
import { permissions } from "../permissions";

@HttpPath("user")
@HttpEvent("auth", "/auth", "post")
@HttpEvent("self", "/self", "get")
@HttpEvent("password", "/password", "patch")
export class UserModel {
  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid user id"), "USR-0x001" ]) ])
  @Validate("update", [ pattern("required", [ /*@i18n*/("User id not informed"), "USR-U-0x001" ]) ])
  @Primary()
  public id?: string;

  @Validate([
    pattern("min", [ /*@i18n*/("User name too short"), "USR-0x002" ], 2),
    pattern("max", [ /*@i18n*/("User name too long"), "USR-0x003" ], 60)
  ])
  @Validate("create", pattern("required", [ /*@i18n*/("User name not informed"), "USR-C-0x001" ]))
  @Format([ format("trim"), format("lowercase") ])
  @Field()
  public name?: string;

  @Validate([
    pattern("min", [ /*@i18n*/("User identification too short"), "USR-0x004" ], 2),
    pattern("max", [ /*@i18n*/("User identification too long"), "USR-0x005" ], 30)
  ])
  @Format([ format("trim"), format("lowercase") ])
  @Validate("create", pattern("required", [ /*@i18n*/("User identification not informed"), "USR-C-0x002" ]))
  @Field()
  public identification?: string;

  @Validate([
    pattern("min", [ /*@i18n*/("User password too short"), "USR-0x006" ], 2)
  ])
  @Field({ type: "string", nullable: true })
  public password?: string | null;

  @Validate([
    pattern("min", [ /*@i18n*/("User device serial too short"), "USR-0x007" ], 5),
    pattern("max", [ /*@i18n*/("User device serial too long"), "USR-0x008" ], 30)
  ])
  @Format([
    format("trim"),
    format("lowercase")
  ])
  @Field({ type: "string", nullable: true })
  public deviceSerial?: string | null;

  @Validate([
    pattern("enum", [ /*@i18n*/("Invalid user role"), "USR-0x009" ], permissions.getAllCodes())
  ])
  @Validate("create", [ pattern("required", [ /*@i18n*/("User role not informed"), "USR-C-0x003" ]) ])
  @Field()
  public role?: string;

  @Field()
  public enabled?: boolean;

  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid user created_by id"), "USR-0x00A" ]) ])
  @Field()
  public createdBy?: string;

  @DateFieldValidation({ code: "USR-0x00B" })
  @DateField()
  public createdAt?: Date;

  @Validate([ pattern("uuid", [ /*@i18n*/("Invalid user updated_by id"), "USR-0x00C" ]) ])
  @Field()
  public updatedBy?: string;

  @DateFieldValidation({ code: "USR-0x00D" })
  @DateField()
  public updatedAt?: Date;

  @AuthorField()
  public createdByUser?: UserModel;

  @AuthorField()
  public updatedByUser?: UserModel;
}
