import { UserModel, permissions, PasswordChange } from "src/@prisma/js-common";
import { Field, format, Format, pattern, Validate, ValidateMany, ValidationContext } from "src/@prisma/js-proto";
import { Mask, NgMask } from "src/@prisma/ng-forms";
import { ngPatterns } from "src/@prisma/ng-forms/validators/pattern";

export class User extends UserModel implements PasswordChange {
  override id!:       string;

  @Format("password", format("empty"))
  override enabled    = true;

  @Format("password", format("empty"))
  override role       = permissions.get("OPERATOR").code;

  override createdAt!: Date;
  override createdBy!: string;

  @Mask(NgMask.maxLength(60, "A"))
  @Validate("update", pattern("required", /*@i18n*/("User name not informed")))
  override name!: string;

  @Mask(NgMask.maxLength(60))
  @Validate("update", pattern("required", /*@i18n*/("User identification not informed")))
  @Validate("auth", pattern(
    "generic",
    /*@i18n*/("User identification not informed"),
    async (context: ValidationContext<User>) => {
      return !!context.payload;
    }
  ))
  override identification!: string;

  @Mask(NgMask.maxLength(30))
  @Format("password", format("empty"))
  override deviceSerial!: string | null;

  @Validate("auth", pattern(
    "generic",
    /*@i18n*/("User password not informed"),
    async (context: ValidationContext<User>) => {
      return !!context.payload;
    }
  ))
  @Validate("password", pattern(
    "generic",
    [ /*@i18n*/("User password not informed"), "USR-P-0x002" ],
    async (context: ValidationContext<User>) => {
      return !!context.payload;
    }
  ))
  @Format([ format("generic", (password): string | undefined => password || void 0) ])
  @Mask(NgMask.maxLength(60))
  override password?: string | null;

  @Mask(NgMask.maxLength(60))
  @Field({ type: "string", nullable: true })
  @Validate("password", [
    pattern("required", [ /*@i18n*/("New password not informed"), "USR-P-0x003" ])
  ])
  @Format([ format("generic", (oldPassword): string | undefined => oldPassword || void 0) ])
  oldPassword?: string | null;

  @Field({ type: "string", nullable: true, serialize: () => void 0 })
  @Mask(NgMask.maxLength(60))
  @ValidateMany(["create", "update"], [
    ngPatterns("equal", /*@i18n*/("Confirm password not equal to password"), "password")
  ])
  @Validate("password", [
    pattern("required", [ /*@i18n*/("Confirm password not informed"), "USR-P-0x004" ]),
    ngPatterns("equal", [ /*@i18n*/("Confirm password not equal to password"), "USR-P-0x005" ], "password")
  ])
  @Format([
    format("generic", (passwordConfirm): string | undefined => passwordConfirm || void 0)
  ])
  passwordConfirm?: string | null;

  @Field({ type: () => User })
  @Format(format("generic", () => void 0))
  override createdByUser?: User;
  @Field({ type: () => User })
  @Format(format("generic", () => void 0))
  override updatedByUser?: User;
}
