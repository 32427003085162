import { TaskContext } from "./task-context";

export class ContextBuilder<
  T = any,
  O = any,
  TOptions extends object = {},
  Context extends TaskContext = TaskContext<T, O>>
{
  private defaultContext?: new (payload: T, opts?: TOptions) => Context;

  /**
   * Set the default context class to be used when calling `execute`.
   */
  setDefaultContext(defaultContext: new (payload: T, opts?: TOptions) => Context): this {
    this.defaultContext = defaultContext;
    return this;
  }

  /**
   * Create a new context with the payload and option specified. Throws `Error` if no default context is set.
   */
  buildContext(payload: T, opts?: TOptions): Context {
    if (!this.defaultContext) throw new Error("Default context was not defined");
    return new this.defaultContext(payload, opts);
  }
}
