import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { Bulk } from "src/@prisma/js-proto";
import { Exception } from "src/@prisma/js-utils";
import { FormGroupModel } from "src/@prisma/ng-forms";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { NgEventPool } from "src/@prisma/ng-runner";
import { User } from "src/models/user.model";

@Component({
  selector: "auth-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"]
})
export class FormComponent implements OnDestroy, AfterViewInit {
  private subscription = new Subscription();

  form;
  source$;

  inProcess = false;
  hidePass = true;

  private bulk(): Bulk<User> {
    return new Bulk(User);
  }

  constructor(
    private feed: MatFeed,
    private pool: NgEventPool,
  ) {
    this.form = FormGroupModel.from(User, {
      event: "auth"
    });

    this.source$ = new BehaviorSubject<string | null | undefined>(null);
  }

  async login(code?: string): Promise<void> {
    if (this.inProcess) return;

    try {
      this.inProcess = true;

      if (code) {
        await this.pool.auth(this.bulk().create({ code }));
      } else {
        this.form.updateValueAndValidity();
        this.form.markAllAsTouched();

        if (this.form.invalid) {
          throw new Exception/*@i18n*/("Invalid fields", "");
        }
        await this.pool.auth(this.bulk().create(this.form.value));
      }
    } catch (e) {
      this.feed.fromError(e);
    } finally {
      this.inProcess = false;
    }
  }

  ngAfterViewInit(): void {
    void 0;
  }

  ngOnDestroy(): void {
    this.form.destroy();
    this.subscription.unsubscribe();
  }
}
