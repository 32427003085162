import { MetadataManager } from "../core";
import { FieldOptions } from "../interfaces";
import { ModelMetadata } from "../metadata";
import { Field } from "./field";

export function Primary<T>(options: FieldOptions<T> = {}): PropertyDecorator {
  return (target, key) => {
    const meta = MetadataManager.getOrSet(target, ModelMetadata, () => new ModelMetadata());
    meta.primaryColumn = key.toString();

    Field(options)(target, key);
  };
}
