import { MessageRegistryModel } from "src/@prisma/js-common";
import { Field, format, Format } from "src/@prisma/js-proto";
import { PartialRecord } from "src/@prisma/js-utils";
import { Mask, NgMask } from "src/@prisma/ng-forms";
import { ParamKeys } from "src/enums/param-keys.enum";
import { Group } from "./group.model";
import { User } from "./user.model";

export class Message extends MessageRegistryModel {
  override id!: string;
  override type!: number;

  @Mask(NgMask.maxLength(60))
  override title!: string;

  @Mask(NgMask.maxLength(200))
  override body!: string;

  @Field({ type: () => User })
  override user?: User;

  @Field({ type: () => Group })
  override group?: Group;

  override templateParams: PartialRecord<ParamKeys, string> = {};

  override expiresIn!: number;
  override expiresAt!: Date;

  override createdAt!: Date;
  override createdBy!: string;

  @Field({ type: () => User })
  @Format(format("empty"))
  override createdByUser?: User;
}
