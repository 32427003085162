import { Field, Format, HttpPath, Primary, Validate, format, pattern } from "src/@prisma/js-proto";
import { DateField } from "../decorators";

@HttpPath("message")
export class MessageModel {
  @Primary()
  @Validate([
    pattern("uuid", [ /*@i18n*/("Invalid message ID"),  "MSG-0x001" ])
  ])
  public id?: string;

  @Field()
  @Validate("create", [
    pattern("required", [ /*@i18n*/("Device serial not informed"), "MSG-C-0x001" ])
  ])
  @Format([
    format("trim"),
    format("lowercase")
  ])
  public deviceSerial?: string;

  @Field()
  @Validate("create", [
    pattern("required", [ /*@i18n*/("Message type not informed"), "MSG-C-0x002" ])
  ])
  @Validate([
    pattern("enum", [ /*@i18n*/("Invalid message type"), "MSG-0x002" ], [ 1, 2, 3 ])
  ])
  public type?: number;

  @Field()
  @Validate([
    pattern("min", [ /*@i18n*/("Message title too short"), "MSG-0x003" ], 1),
    pattern("max", [ /*@i18n*/("Message title too long"), "MSG-0x004" ], 60)
  ])
  @Format([
    format("trim")
  ])
  public title?: string;

  @Field()
  @Validate("create", [
    pattern("required", [ /*@i18n*/("Message body not informed"), "MSG-C-0x003" ])
  ])
  @Validate([
    pattern("min", [ /*@i18n*/("Message body too short"), "MSG-0x005" ], 1),
    pattern("max", [ /*@i18n*/("Message body too long"), "MSG-0x006" ], 200)
  ])
  @Format([
    format("trim")
  ])
  public body?: string;

  @Field()
  @Validate([
    pattern("min", [ /*@i18n*/("Expiration time too short"), "MSG-0x007" ], 1000),
    pattern("max", [ /*@i18n*/("Expiration time too long"), "MSG-0x008" ], 2 ** 31)
  ])
  public expiresIn?: number;

  @DateField()
  public expiresAt?: Date;

  @DateField()
  public receivedAt?: Date;

  @DateField()
  public confirmedAt?: Date;

  @DateField()
  public createdAt?: Date;
}
