import { ContextOptions } from "../interfaces";
import { ContextBuilder } from "./context-builder";
import { Runner } from "./runner";
import { TaskContext } from "./task-context";

type Key<K extends string> = K | "@default"

export class RunnerPool<
  K extends string,
  T = any,
  O = any,
  TOptions extends ContextOptions = {},
  Context extends TaskContext<T, O, TOptions> = TaskContext<T, O, TOptions>,
  R extends Runner<T, O, TOptions, string, Context> = Runner<T, O, TOptions, string, Context>>

  extends ContextBuilder<T, O, TOptions, Context>
{
  static readonly DEFAULT = "@default";

  private runners: Partial<Record<Key<K>, R>> = {};

  constructor(runners?: Partial<Record<Key<K>, R>>) {
    super();
    if (runners) this.add(runners);
  }

  /**
   * Set the default runner. Can be called with `execute(RunnerPool.DEFAULT)` or `executeDefault`.
   */
  setDefault(runner: R): this {
    return this.add(RunnerPool.DEFAULT, runner);
  }

  /**
   * Add a new runner to the pool.
   * @param name Name of the runner to be used in `execute(name)`.
   */
  add(name: Key<K>, runner: R): this
  add(runners: Partial<Record<Key<K>, R>>): this
  add(
    nameOrRunners: Key<K> | Partial<Record<Key<K>, R>>,
    runner?: R): this
  {
    if (typeof nameOrRunners === "object") {
      for (const key in nameOrRunners) {
        const runner = nameOrRunners[key as Key<K>];
        if (runner) this.add(key as Key<K>, runner as R);
      }

      return this;
    }

    return (this.runners[nameOrRunners] = runner, this);
  }

  /**
   * Remove runner from the pool.
   */
  remove(name: Key<K>): this {
    return (delete this.runners[name], this);
  }

  /**
   * Get the default runner. Returns `undefined` if not exists.
   */
  getDefault(): R | undefined {
    return this.runners[RunnerPool.DEFAULT];
  }

  /**
   * Get the default runner. Throws `Error` if not exists.
   */
  requireDefault(): R {
    const runner = this.getDefault();

    if (!runner)
      throw new Error("Default runner was not defined");

    return runner;
  }

  /**
   * Get the runner with the specified name. Returns `undefined` if not exists.
   */
  get(name: Key<K>): R | undefined {
    if (!this.runners) throw new Error("Runners is not defined. Check for circular dependencies");
    return this.runners[name];
  }

  /**
   * Get the runner with the specified name. Throws `Error` if not exists.
   */
  require(name: Key<K>): R {
    const runner = this.get(name);

    if (!runner)
      throw new Error(`Runner '${name}' was not defined`);

    return runner;
  }

  /**
   * Execute the default runner with a generated context. Uses `buildContext()`.
   */
  executeDefault(value: T, opts?: TOptions): Promise<Context> {
    return this.executeContext(RunnerPool.DEFAULT, this.buildContext(value, opts));
  }

  /**
   * Execute the default runner with a specified context.
   * Avoid using the same context for multiples executions unless you know what are you doing.
   */
  executeContextDefault(context: Context): Promise<Context> {
    return this.require(RunnerPool.DEFAULT).executeContext(context);
  }

  /**
   * Execute the specified runner with a generated context. Uses `buildContext()`.
   */
  execute(name: Key<K>, value: T, opts?: TOptions): Promise<Context> {
    return this.executeContext(name, this.buildContext(value, opts));
  }

  /**
   * Execute the runner with a specified context. Avoid using the same context for multiples executions
   * unless you know what are you doing.
   */
  executeContext(name: Key<K>, context: Context): Promise<Context> {
    return this.require(name).executeContext(context);
  }
}
