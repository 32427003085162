import { FormatterPool } from "../formatter";
import { Constructable, ProtoDeepPartial, SerializableArray, Serializable, BulkImportOptions, ConstructableNode } from "../interfaces";
import { ModelMetadata } from "../metadata";
import { ValidatorPool } from "../validator";
import { BulkIterable } from "./iterable";
import { MetadataManager } from "./manager";
import { ModelNode } from "./node";
import { SerializableContext } from "./serializable";
import { UnserializableContext } from "./unserializable";

export class Bulk<T extends object = any> {
  public readonly type:    ConstructableNode<T>;
  public readonly parent?: Bulk;

  private data:  T[] = [];
  private node?: ModelNode<T[]>;

  static async batch<T, A extends T[] = T[]>(
    array: A,
    cb: (slice: T[], currentBatch: number, last: boolean) => any,
    batchSize?: number): Promise<void>
  {
    if (typeof batchSize !== "number" || isNaN(batchSize) || batchSize <= 0) return cb(array, 1, true);

    const numOfBatches = Math.ceil(array.length / batchSize);

    if (numOfBatches <= 1) return cb(array, 1, true);

    for (let i = 1; i <= numOfBatches; i++) {
      const start = (i - 1) * batchSize;
      const slice = array.slice(start, start + batchSize);

      await cb(slice, i, i >= numOfBatches);
    }
  }

  /**
   * Create a bulk and set the default value.
   */
  static from<T extends object>(
    value: T,
    type?: ConstructableNode<T>,
    opts?: { parent?: Bulk }): Bulk<T>
  static from<T extends object>(
    value: undefined | T | T[],
    type: ConstructableNode<T>,
    opts?: { parent?: Bulk }): Bulk<T>
  static from<T extends object>(
    value: undefined | T | T[],
    type?: ConstructableNode<T>,
    opts?: { parent?: Bulk }): Bulk<T>
  {
    if (!type) {
      if (!value || Array.isArray(value) || value.constructor === {}.constructor)
        throw new Error("Bulk type not provided");

      type = value.constructor as ConstructableNode<T>;
    }
    const bulk = new Bulk(type, opts);

    if (value)
      bulk.setData(Array.isArray(value) ? value : [ value ]);

    return bulk;
  }

  /**
   * Create a bulk and create the default value.
   */
  static fromCreate<T extends object>(
    value: undefined | ProtoDeepPartial<T> | ProtoDeepPartial<T>[],
    type: ConstructableNode<T>,
    opts?: { parent?: Bulk }): Bulk<T>
  static fromCreate<T extends object>(
    value: any,
    type: ConstructableNode<T>,
    opts?: { parent?: Bulk }): Bulk<T>
  static fromCreate<T extends object>(
    value: any,
    type: ConstructableNode<T>,
    opts?: { parent?: Bulk }): Bulk<T>
  {
    const bulk = new Bulk(type, opts);

    if (value)
      bulk.create(value);

    return bulk;
  }

  /**
   * Create a bulk and import the default value.
   */
  static async fromImport<T extends object>(
    value: Serializable,
    type: ConstructableNode<T>,
    opts?: { parent?: Bulk }): Promise<Bulk<T>>
  {
    const bulk = new Bulk(type, opts);

    if (value)
      await bulk.import(value);

    return bulk;
  }

  /**
   * Populate target object with the data from source object. If target has metadata it will respect it's
   * nested types.
   * @experimental
   */
  static populate<T extends object>(target: T, source: ProtoDeepPartial<T>): T
  static populate<T extends object>(target: T, source: any): T
  static populate<T extends object>(target: T, source: any): T {
    if (!target || !source) return target;

    const node = new ModelNode(target);
    const keys = Object.keys(source) as Extract<keyof T, string>[];

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = source[key];
      if (value === void 0) continue;

      const child = node.getChild(key);
      const type  = child?.typeObject;

      if (value === null) {
        if (child?.nullable) target[key] = value;
        continue;
      }

      if (type) {
        if (typeof value !== "object") continue;

        if (child.array || Array.isArray(value)) {
          if (!Array.isArray(value) || (!child.array && Array.isArray(value))) continue;

          const array: any[] = [];

          for (let i = 0; i < value.length; i++) {
            const item = value[i];
            if (item instanceof type) array.push(item);
            else if (item) array.push(this.populate(new type() as any, item));
          }

          target[key] = array as any;
        }
        else {
          const targetValue = typeof target[key] === "object" ? target[key] : null;

          target[key] = value instanceof type ? value :
            this.populate((targetValue || new type()) as any, value);
        }
        continue;
      }

      target[key] = value;
    }

    return target;
  }

  /**
   * Return this bulk data reference. Use `toArray` to avoid messing with the reference.
   *
   * @example
   * ```
   * // Not recommended
   * const mapped = bulk.toArray().map(e => e.mappedValue);
   *
   * // Recommended
   * const mapped = bulk.value.map(e => e.mappedValue);
   *
   * // Not recommended
   * const sorted = bulk.value.sort();
   *
   * // Recommended
   * const sorted = bulk.toArray().sort();
   * ```
   */
  get value(): T[] { return this.data; }

  /**
   * Get the size of the bulk data.
   */
  get length(): number { return this.data.length; }

  constructor(
    typeOrNode: ConstructableNode<T> | ModelNode<T | T[]>,
    opts?: { parent?: Bulk })
  {
    if (typeOrNode instanceof ModelNode) {
      if (typeof typeOrNode.type !== "function")
        throw new Error(`Invalid type '${typeOrNode.type?.toString()}' for Bulk`);

      if (typeOrNode.value)
        this.data = Array.isArray(typeOrNode.value) ? typeOrNode.value : [ typeOrNode.value ];

      typeOrNode.type;

      this.type = typeOrNode.type as ConstructableNode<T>;

      if (Array.isArray(typeOrNode.value)) {
        this.node = typeOrNode as ModelNode<T[]>;
      } else {
        this.node = typeOrNode.sibling(this.data, {
          type: typeOrNode.dirty ? typeOrNode.type as Constructable<any> : void 0
        });
      }
    } else {
      this.type = typeOrNode;
    }

    if (opts) {
      if (opts.parent) this.parent = opts.parent;
    }
  }

  /**
   * Check if item is a valid bulk element.
   */
  assert(data: any[]): T[]
  /**
   * Check if item is a valid bulk element.
   */
  assert<O extends object>(data: O): T
  /**
   * Check if item is a valid bulk element.
   */
  assert(data: any): T | T[]
  assert(data: T | T[]): T | T[] {
    if (Array.isArray(data))
      for (let i = 0; i < data.length; i++) this.assert(data[i]);
    else if (!(data instanceof this.type))
      throw new Error(`Invalid value for "${this.type.name}"`);

    return data;
  }

  at(index: number): BulkIterable<T> | undefined {
    if (index < 0) index = this.length + index;
    return this.data[index] ? new BulkIterable(this.data[index], this) : void 0;
  }

  async batch(
    cb: (bulk: Bulk<T>, currentBatch: number, last: boolean) => any, batchSize?: number): Promise<void>
  {
    return Bulk
      .batch(this.value, (slice, current, last) =>
        cb(new Bulk(this.type).setData(slice as T[], { unsafe: true }), current, last), batchSize);
  }

  /**
   * Create a new array for this bulk and replace the old one.
   */
  clear(): this {
    this.setData([]);
    return this;
  }

  /**
   * Remove all elements of the array and keep the reference.
   */
  empty(): this {
    this.data.splice(0, this.data.length);
    return this;
  }

  /**
   * Add a item to bulk with the following conditions:
   *  * `item` is a valid bulk element: Will push the item to the bulk. (Same as `insert`)
   *  * `item` is empty: Will create a new instance of bulk element and insert it.
   *  * `item` is object: Will create a new instance of bulk element and populate it with the specified
   *    object using `Bulk.populate`.
   */
  create(item?: ProtoDeepPartial<T> | ProtoDeepPartial<T>[]): this
  create(item?: any | any[]): this
  create(item?: any | any[]): this {
    if (!item)
      this.insert(new this.type());
    else if (Array.isArray(item))
      for (let i = 0; i < item.length; i++) this.create(item[i]);
    else if (item instanceof this.type)
      this.insert(item);
    else if (typeof item === "object") {
      this.insert(Bulk.populate(new this.type(), item));
    }

    return this;
  }

  /**
   * Get serialized bulk data. Will use `serialize` callbacks.
   */
  async export(): Promise<SerializableArray> {
    const serialized = await SerializableContext.fromType<any>(this.type, this.data).digest();
    return Array.isArray(serialized) ? serialized : [];
  }

  /**
   * Import serialized data. Will use `unserialize` callbacks.
   */
  async import(serialized: Serializable, opts?: BulkImportOptions): Promise<this> {
    if (opts?.clear !== false) this.clear();

    const data = await new UnserializableContext(serialized, this.type).digest();
    this.data  = this.assert(Array.isArray(data) ? data : [ data ]);

    return this;
  }

  /**
   * Add new element to bulk.
   * @param opts Use `unsafe` options to skip type check.
   */
  insert(data: T | T[], opts?: { unsafe?: boolean }): this {
    const unsafe  = opts?.unsafe;
    const pointer = this.data;

    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) this.insert(data[i]);
    } else {
      const element = unsafe ? data : this.assert(data);

      if (Array.isArray(element))
        pointer.push(...element);
      else
        pointer.push(element);
    }

    return this;
  }

  isEmpty(): boolean {
    return this.data.length === 0;
  }

  isNotEmpty(): boolean {
    return this.data.length > 0;
  }

  /**
   * Creates a new bulk with the filtered objects.
   */
  async filter(cb: (element: T, index: number, arr: T[]) => Promise<boolean>): Promise<Bulk<T>> {
    const data  = this.data;
    const child = new Bulk<T>(this.type);

    for (let i = 0; i < data.length; i++) {
      if (await cb(data[i], i, data)) child.data.push(data[i]);
    }

    return child;
  }

  /**
   * Creates a new bulk with the filtered objects.
   */
  filterSync(cb: (element: T, index: number, arr: T[]) => boolean): Bulk<T> {
    const data  = this.data;
    const child = new Bulk<T>(this.type);

    for (let i = 0; i < data.length; i++) {
      if (cb(data[i], i, data)) child.data.push(data[i]);
    }

    return child;
  }

  /**
   * Get the `ModelNode` from this bulk. Will only be created once if `setData` is not used.
   * @example
   * ```
   *  const node = bulk.getNode();
   *
   *  node === bulk.getNode(); // true
   *  bulk.setData([ new Data() ]);
   *  node === bulk.getNode(); // false;
   * ```
   */
  getNode(): ModelNode<T[]> {
    return this.node || (this.node = ModelNode.fromType<any>(this.type, this.data));
  }

  /**
   * Get model metadata from ModelNode.
   */
  getModelMetadata(): ModelMetadata<T> {
    const metadata = MetadataManager.get(this.type, ModelMetadata);
    if (!metadata) throw new Error(`ModelMetadata for ${this.type.name} not defined`);

    return metadata;
  }

  /**
   * Set the data to work with. Use `unsafe` option to skip type check.
   */
  setData(data: T[] | T, opts?: { unsafe?: boolean }): this {
    data      = Array.isArray(data) ? data : [ data ];
    this.data = opts?.unsafe ? data : this.assert(data);
    this.node = void 0;

    return this;
  }

  getValidator(): ValidatorPool<T> {
    return this.getModelMetadata().validator;
  }

  getFormatter(): FormatterPool<T> {
    return this.getModelMetadata().formatter;
  }

  /**
   * Create a *NEW* array from this bulk. Use `value` to get the original array to have a
   * better performance in some cases.
   *
   * @example
   * ```
   * // Not recommended
   * const mapped = bulk.toArray().map(e => e.mappedValue);
   *
   * // Recommended
   * const mapped = bulk.value.map(e => e.mappedValue);
   *
   * // Not recommended
   * const sorted = bulk.value.sort();
   *
   * // Recommended
   * const sorted = bulk.toArray().sort();
   * ```
   */
  toArray(): T[] {
    return this.data.concat();
  }
}
