import { Bulk } from "../core";
import { FieldError } from "../exceptions";
import { ValidationContext, ValidationContextOptions, ValidationOptions } from "../interfaces";
import { RunnerPool } from "../runner";
import { ValidatorRunner } from "./validator";

export class ValidatorPool<T extends object>
  extends RunnerPool<string, any, any, ValidationContextOptions,
  ValidationContext, ValidatorRunner<T>>
{
  async validateDefault(
    bulkOrModel: Bulk<T> | T | T[],
    optsOrStages?: ValidationOptions<Extract<keyof T, string>> | Extract<keyof T, string>[]
  ): Promise<FieldError | null> {
    return this.getDefault()?.validate(bulkOrModel, optsOrStages) || null;
  }

  async validate(
    name: string | string[],
    bulkOrModel: Bulk<T> | T | T[],
    optsOrStages?: ValidationOptions<Extract<keyof T, string>> | Extract<keyof T, string>[]
  ): Promise<FieldError | null> {
    if (typeof name === "string") {
      return this.get(name)?.validate(bulkOrModel, optsOrStages) || null;
    }

    for (let i = 0; i < name.length; i++) {
      const result = await this.get(name[i])?.validate(bulkOrModel, optsOrStages);
      if (result) return result;
    }

    return null;
  }

  async assertDefault(
    bulkOrModel: Bulk<T> | T | T[],
    optsOrStages?: ValidationOptions<Extract<keyof T, string>> | Extract<keyof T, string>[]
  ): Promise<void> {
    return this.requireDefault().assert(bulkOrModel, optsOrStages);
  }

  async assert(
    name: string | string[],
    bulkOrModel: Bulk<T> | T | T[],
    optsOrStages?: ValidationOptions<Extract<keyof T, string>> | Extract<keyof T, string>[]
  ): Promise<void> {
    if (typeof name === "string")
      return this.get(name)?.assert(bulkOrModel, optsOrStages);

    for (let i = 0; i < name.length; i++)
      await this.get(name[i])?.assert(bulkOrModel, optsOrStages);
  }
}
