import { Field, HttpPath, Primary, Validate, pattern } from "src/@prisma/js-proto";
import { DateField } from "../../decorators";
import { MessageModel } from "../../models";
import { PoolMessageModel } from "./message.model";

@HttpPath("plugin/pool/message/core-message")
export class PoolMessageCoreMessageModel {
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid UUID"), "POL-MCM-0x001" ]))
  @Primary()
  public id?: string;

  @Validate("create", [
    pattern("required", [ /*@i18n*/("Message ID not informed"), "POL-MCM-C-0x001" ])
  ])
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid message UUID"), "POL-MCM-0x002" ]))
  @Field()
  public messageId?: string;

  @Validate("create", [
    pattern("required", [ /*@i18n*/("Core Message ID not informed"), "POL-MCM-C-0x002" ])
  ])
  @Validate(pattern("uuid", [ /*@i18n*/("Invalid core message UUID"), "POL-MCM-0x003" ]))
  @Field()
  public coreMessageId?: string;

  @DateField()
  public createdAt?: Date;

  @Field({ type: () => PoolMessageModel })
  public message?: PoolMessageModel;

  @Field({ type: () => MessageModel })
  public coreMessage?: MessageModel;
}
