import { TaskContext } from "src/@prisma/js-proto";
import { NgRunnerOptions } from "../interface";

export class NgTaskContext<T = any> extends TaskContext<T, Promise<any>, NgRunnerOptions> {
  resolve: (resp: any) => any = () => void 0;
  reject:  (e: unknown) => any = () => void 0;

  override output: Promise<any> = new Promise((resolve, reject) => {
    this.resolve = resolve;
    this.reject = reject;
  });

  constructor(...args: ConstructorParameters<typeof TaskContext<T, Promise<any>, NgRunnerOptions>>) {
    super(...args);

    this.on("error", e => {
      this.reject(e);
    });
  }

  beforeOutput(cb: (output: any) => any): void {
    this.output = this.output.then(async output => (await cb(output), output));
  }

  changeOutput(cb: (output: any) => any): void {
    this.output = this.output.then(async output => await cb(output));
  }
}
