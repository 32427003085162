<div class="row report-container">
  <div class="col report-search" [formGroup]="form">
    <mat-form-field>
      <mat-label><!--@i18n-->Select the report type: *<!----></mat-label>
      <mat-select formControlName="reportType">
        <mat-option *ngFor="let rt of reportTypes" [value]="rt">{{ rt.label }}</mat-option>
      </mat-select>
      <mat-error>{{ reportType.errors?.message }}</mat-error>
    </mat-form-field>

    <div class="time" *ngIf="showStart()">
      <mat-form-field keyboard-ignore (click)="dpStart.open()">
        <mat-label><!--@i18n-->Filter Start Date: *<!----></mat-label>
        <input matInput [matDatepicker]="dpStart" formControlName="start" readonly>
        <mat-hint><!--@i18n-->MM/DD/YYYY<!----></mat-hint>
        <mat-datepicker-toggle matSuffix [for]="dpStart"></mat-datepicker-toggle>
        <mat-datepicker #dpStart></mat-datepicker>
        <mat-error>{{ start.errors?.message }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label><!--@i18n-->Time: *<!----></mat-label>
        <mat-select formControlName="startTime">
          <mat-option *ngFor="let opt of timeOptions" [value]="opt.value">{{ opt.label + ":00" }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="time" *ngIf="showEnd()">
      <mat-form-field keyboard-ignore (click)="dpEnd.open()">
        <mat-label><!--@i18n-->Filter End Date: *<!----></mat-label>
        <input matInput [matDatepicker]="dpEnd" formControlName="end" readonly>
        <mat-hint><!--@i18n-->MM/DD/YYYY<!----></mat-hint>
        <mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
        <mat-datepicker #dpEnd></mat-datepicker>
        <mat-error>{{ end.errors?.message }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label><!--@i18n-->Time: *<!----></mat-label>
        <mat-select formControlName="endTime">
          <mat-option *ngFor="let opt of timeOptions" [value]="opt.value">{{ opt.label + ":59" }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row mb">
      <div class="col-5">
        <button mat-raised-button (click)="clear()" [disabled]="inProcess">
        <!--@i18n-->Clear<!---->
        </button>
      </div>

      <div class="col-7">
        <button mat-raised-button [color]="!disableGenerate ? 'accent' : ''"
          (click)="generate()" [disabled]="disableGenerate">
        <!--@i18n-->Generate<!---->
        </button>
      </div>
    </div>

    <button class="export-button" mat-raised-button [color]="report ? 'success' : ''"
      (click)="export()" [disabled]="!report">
    <!--@i18n-->Export<!---->
    </button>
  </div>

  <div class="col report-preview">
    <div class="table">
      <div class="table-header">
        <h3 class="text-primary"><!--@i18n-->Report Preview<!----></h3>
        <h4 *ngIf="report">
          <span>{{ report.type }}</span>
          -
          <span ngi18nIgnore>
            {{ (report.start | date) + (report.end ? " - " + (report.end | date) : "") }}
          </span>
        </h4>
      </div>

      <div class="table-content" ngi18nIgnore>
        <table mat-table [dataSource]="dataSource">
          <ng-container *ngFor="let column of header" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef>{{ mapHeader[column] }}</th>
            <td mat-cell *matCellDef="let data">{{ data[column] }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="header; sticky: true"
            [style.display]="!header.length ? 'none' : 'table-row'"></tr>
          <tr mat-row *matRowDef="let row; columns: header;"></tr>
          <tr class="mat-row" *matNoDataRow class="box">
            <td class="mat-cell" colspan="100" ngi18n><!--@i18n-->No report<!----></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
