import { isNumeric } from "./string";

/**
 * Check if variable is a valid `number`.
 */
export function isNumber(value: any): value is number {
  return typeof value === "number" && !isNaN(value);
}

/**
 * Check max length of a decimal number.
 * @param length - max length before fraction.
 * @param p - max length after fraction.
 */
export function limitDecimal(length: number, p: number, value: number | string): boolean {
  value = typeof value === "string" ? value : value?.toString();

  if (!isNumeric(value, { symbols: true }) || length <= 0 || p <= 0) {
    return false;
  }

  const
    format = value.replace(/(-|\+)/, ""),
    index = format.indexOf("."),
    len = format.length;

  if (index === -1) {
    return len <= length;
  } else {
    return index <= length && (len - index - 1) <= p;
  }
}

/**
 * Check if variable is a unsigned integer `number`.
 */
export function isUInt(value: any): value is number {
  return typeof value === "number" && !isNaN(value) && value >= 0 && value <= 2 ** 31 && !(value % 1);
}

/**
 * Check if variable is a float `number`.
 */
export function isFloat(value: any): value is number {
  return typeof value === "number" && !isNaN(value) && !!(value % 1);
}

/**
 * Round number to a specific precision.
 * @param precision - Default `2`
 */
export function round(n: number | string, precision = 2): number {
  if (typeof n === "string") {
    n = parseFloat(n);
  }

  const p = 10 ** precision;

  return Math.round(n * p) / p;
}

/**
 * Round number to a specific precision if it's different from `null` and `undefined`.
 * @param precision - Default `2`
 */
export function roundIfExists(n: number | string, precision?: number): number
export function roundIfExists(
  n: number | string | null | undefined, precision?: number): number | null | undefined
export function roundIfExists(
  n: number | string | null | undefined, precision = 2): number | null | undefined
{
  return n === null || n === void 0 ? n : round(n, precision);
}
