import { MetadataManager, Proto } from "../core";
import { ProtoPartialRecord } from "../interfaces";
import { RunnerPool } from "../runner/pool";

export type HttpMethods = "post" | "get" | "put" | "patch" | "delete" | "options";

export interface HttpConfig {
  path:   string
  method: HttpMethods
}

export class HttpMetadata {
  /**
   * Default `HttpConfig` for new events.
   */
  static defaultHttpConfig(): HttpConfig {
    return { path: "", method: "post" };
  }

  /**
   * Get the config for a `event` from the `HttpMetadata` of the target.
   * If a `event` is not specified it will return the metadata instead. In case the target doesn't have
   * a `HttMetadata` or the specified `event` it will return `undefined`.
   */
  static getHttpConfig(target: Function | object): HttpMetadata | undefined
  static getHttpConfig(target: Function | object, event: string): HttpConfig | undefined
  static getHttpConfig(
    target: Function | object, event?: string): HttpMetadata | HttpConfig | undefined
  static getHttpConfig(
    target: Function | object, event?: string
  ): HttpMetadata | HttpConfig | undefined
  {
    const metadata = MetadataManager.get(target, HttpMetadata);
    if (!metadata) return;
    if (event === void 0) return metadata;
    return metadata.events[event];
  }

  /**
   * Get the config for a `event` from the `HttpMetadata` of the target.
   * If a `event` is not specified it will return the metadata instead. In case the target doesn't have
   * a `HttMetadata` it will create a new metadata and return it, if the specified `event` doesn't exists
   * it will return the `defaultHttpConfig`.
   */
  static forceHttpConfig(target: Function | object): HttpMetadata
  static forceHttpConfig(target: Function | object, event: string): HttpConfig
  static forceHttpConfig(target: Function | object, event?: string): HttpMetadata | HttpConfig
  static forceHttpConfig(target: Function | object, event?: string): HttpMetadata | HttpConfig {
    const metadata = MetadataManager.get(target, HttpMetadata) || new HttpMetadata();
    if (event === void 0) return metadata;
    return metadata.events[event] || this.defaultHttpConfig();
  }

  /**
   * Get the HttpPath from the target metadata. Throw an error in case the target doesn't have a metadata.
   * If a `event` is specified it will return the path of the event.
   */
  static path(target: any, event?: string): string {
    const metadata = this.getHttpConfig(target);
    if (!metadata)
      throw new Error(
        `HttpMetadata not defined in '${Proto.getConstructor(target)?.name || typeof target}'`);

    return event ? metadata.events[event]?.path || this.defaultHttpConfig().path : metadata.path;
  }

  public path = "";
  public events: ProtoPartialRecord<string, HttpConfig> = {
    create: { path: "", method: "post"   },
    update: { path: "", method: "put"    },
    delete: { path: "", method: "delete" },
    read:   { path: "", method: "get"    },

    [RunnerPool.DEFAULT]: HttpMetadata.defaultHttpConfig()
  };
}
