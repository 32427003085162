import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { MessageTypes } from "src/@prisma/js-common";
import { Bulk } from "src/@prisma/js-proto";
import { __ } from "src/@prisma/ng-i18n";
import { Cache, NgEventPool, NgPoolEventIndex } from "src/@prisma/ng-runner";
import { CacheManager } from "src/@prisma/ng-utils";

import { DeviceService } from "src/app/services/device.service";
import { GroupService } from "src/app/services/group.service";
import { MessagePayloadService } from "src/app/services/message-payload.service";
import { MessageTemplateService } from "src/app/services/message-template.service";
import { UserService } from "src/app/services/user.service";
import { DefaultCreateComponent } from "src/app/utils/default-create.component";
import { ParamKeys } from "src/enums/param-keys.enum";
import { Device } from "src/models/device.model";
import { Group } from "src/models/group.model";
import { MessagePayload } from "src/models/message-payload.model";
import { MessageTemplate } from "src/models/message-template.model";
import { Message } from "src/models/message.model";
import { User } from "src/models/user.model";
import { MESSAGE_TYPE_RECORD } from "src/utils/records";
import { LOCAL_STORAGE_KEYS } from "src/utils/storage-keys";
import { parseObject } from "src/utils/util";

type KeyName<T extends number> = {
  key:  T
  name: string
};

export enum MessageTargetTypes {
  DEVICE  = 1,
  GROUP   = 2
}

type MessageTarget = {
  value:  string
  name:   string
};

type MessageTargetSection<T> = {
  name:   string
  key:    keyof T
  items:  MessageTarget[]
};

@Component({
  selector: "message-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"]
})
export class FormComponent extends DefaultCreateComponent<MessagePayload, MessagePayloadService> {

  private subscription = new Subscription();
  private poolIndex: NgPoolEventIndex;
  private cache = new CacheManager();

  users: User[] = [];
  devices: Device[] = [];
  groups: Group[] = [];
  templates?: MessageTemplate[];
  targets: MessageTargetSection<MessagePayload>[] = [];
  lastItems: string[] = [];

  filter = new FormControl("");

  selectedType = this.types[0].key;

  get types(): KeyName<MessageTypes>[] {
    return this.cache.getCacheOrExecute(MESSAGE_TYPE_RECORD, e => this.getKeyNameFrom(e)
      .map((k, i) => ({ ...k, name: `${ i + 1 } - ${ __(k.name) }`.toUpperCase() })));
  }

  private getKeyNameFrom<T extends number>(from: Record<T, string>): KeyName<T>[] {
    return Object.keys(from).map(_key => {
      const key = (+_key) as T;
      return { key, name: from[key] };
    });
  }

  get filteredLastItems(): string[] {
    return this.cache.getCacheOrExecute(this.form.controls.itemCode.value, filter => {
      if (filter) {
        return this.lastItems.filter(i => i.toLowerCase().includes(filter.toLowerCase()));
      }
      return this.lastItems;
    });
  }

  private changeArray<T extends { id: string }>(src: T[], from: T[]): boolean {
    if (src.length !== from.length) {
      from.splice(0);
      src.forEach(e => from.push(e));
      return true;
    }
    let ret = false;

    src.forEach((e, i) => {
      const index = from.findIndex(f => f.id === e.id);

      if (index === -1) {
        from[i] = e;
        ret = true;
      } else {
        from[index] = e;
      }
    });
    return ret;
  }

  constructor(
    override service: MessagePayloadService,
    router:           Router,
    route:            ActivatedRoute,
    userService:      UserService,
    groupService:     GroupService,
    templateService:  MessageTemplateService,
    deviceService:    DeviceService,
    cache:            Cache
  ) {
    super(service, router, route, {
      createText: /*@i18n*/("Message created successfully!"),
      deleteText: /*@i18n*/("Message deleted successfully!"),
      updateText: /*@i18n*/("Message updated successfully!")
    });

    this.poolIndex = NgEventPool.on(MessagePayload, "create", () => {
      cache.clear(Message);
      NgEventPool.emit(Message, "create", new Bulk(Message));
    });

    userService.observe().subscribe(users => {
      if (this.changeArray(users, this.users)) this.updateTargets();
    }).also(it => this.subscription.add(it));

    deviceService.observe().subscribe(devices => {
      if (this.changeArray(devices, this.devices)) this.updateTargets();
    }).also(it => this.subscription.add(it));

    groupService.observe({
      filterOptions: {
        relations: [ "users" ]
      }
    }).subscribe(groups => {
      if (this.changeArray(groups, this.groups)) this.updateTargets();
    }).also(it => this.subscription.add(it));

    templateService.observe().subscribe(templates => {
      this.templates = templates;

      if (templates.length === 1) {
        this.form.controls.templateId.setValue(templates[0].id);
      }
    }).also(it => this.subscription.add(it));

    this.lastItems = parseObject<string[]>(localStorage.getItem(LOCAL_STORAGE_KEYS.FORM_ITEMS) || "[]")
      .map(e => e.toUpperCase());
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
    NgEventPool.removeListener(this.poolIndex);
  }

  private updateTargets(): void {
    this.targets = [
      {
        name:   __("Groups"),
        key:    "groupId",
        items:  this.groups.filter(e => e.users.length).map(e => ({
          name:   e.name.toUpperCase(),
          value:  e.id
        }))
      },
      {
        name:   __("Devices"),
        key:    "userId",
        items:  this.users
          .filter(e => e.deviceSerial && this.devices.find(d => d.serial === e.deviceSerial))
          .map(e => ({
            name:   `(${e.identification.toUpperCase()}) ${e.name.toUpperCase()}`,
            value:  e.id
          }))
      }
    ];
  }

  override async formInit(itemId?: string): Promise<void> {
    await super.formInit(itemId);

    this.form.watch(payload => {
      const params: Record<string, string> = payload.templateParams || {};
      let update = false;

      if (payload.itemCode && payload.itemCode !== payload.templateParams?.[ParamKeys.CODE]) {
        params[ParamKeys.CODE] = payload.itemCode;
        update = true;
      }
      if (payload.amount && payload.amount !== payload.templateParams?.[ParamKeys.AMOUNT]) {
        params[ParamKeys.AMOUNT] = payload.amount;
        update = true;
      }
      if (payload.station && payload.station !== payload.templateParams?.[ParamKeys.STATION]) {
        params[ParamKeys.STATION] = payload.station;
        update = true;
      }
      if (update) this.form.controls.templateParams.setValue(params);
    });

    this.form.controls.target.watch(target => {
      this.form.controls[target.key].setValue(target.value);
    });

    if (this.templates?.length === 1) {
      this.form.controls.templateId.setValue(this.templates[0].id);
    }
    const workStation = localStorage.getItem(LOCAL_STORAGE_KEYS.FORM_STATION);

    if (workStation) {
      this.form.controls.station.setValue(workStation);
    }

    this.form.controls.type.setValue(this.selectedType);
  }

  override async save(): Promise<boolean> {
    const workStation = this.form.controls.station.value;
    const item = this.form.controls.itemCode.value;

    if (workStation) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.FORM_STATION, workStation);
    }
    if (item) {
      const lastItems = this.lastItems.filter(e => e !== item);

      lastItems.unshift(item);
      this.lastItems = lastItems.slice(0, 5);
      localStorage.setItem(LOCAL_STORAGE_KEYS.FORM_ITEMS, JSON.stringify(this.lastItems));
      this.cache.clear();
    }
    return await super.save();
  }

  showTarget({ name }: { name: string }): boolean {
    const filter = this.filter.value;
    return !filter || name.toLowerCase().includes(filter.toLowerCase());
  }

  hasCode(): boolean {
    return !!this.form.controls.itemCode.value;
  }

  clear(key: keyof MessagePayload): void {
    const field = this.form.controls[key];
    field.setValue(null as never);
  }

  removeItem(item: string, event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.lastItems = this.lastItems.filter(e => e !== item);
    localStorage.setItem(LOCAL_STORAGE_KEYS.FORM_ITEMS, JSON.stringify(this.lastItems));
    this.cache.clear();
  }
}
