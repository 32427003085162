export class ProtoEventEmitter {
  private events: Record<string, ((...args: any[]) => any | Promise<any>)[]> = {};

  on(event: string, listener: (...args: any[]) => any | Promise<any>): this {
    (this.events[event] || (this.events[event] = [])).push(listener);
    return this;
  }

  emit(event: string, ...args: any[]): this {
    const events = this.events[event];
    if (!events) return this;

    for (let i = 0, len = events.length; i < len; i++)
      events[i](...args);

    return this;
  }

  removeListener(event: string, listener?: Function): this {
    const events = this.events[event];
    if (!events) return this;

    if (!listener) {
      events.splice(0, events.length);
      return this;
    }

    for (let i = 0; i < events.length; i++)
      if (listener === events[i]) events.splice(i--, 1);

    return this;
  }
}
