import { Bulk } from "../core";
import { TaskContext } from "../runner";
import { ValidationTaskTemplate } from "../validator";

export type ValidationOutput = ValidationError | ValidationError[] | null

export interface ValidationError {
  message: string,
  code?: string
  [ key: string ]: any
}

export interface ValidationResult<T> {
  name: keyof T
  errors?: ValidationError[]
  result?: ValidationResult<T[keyof T]>[]
}

export type ValidationErrorTuple = [ string, string? ]

export type ConstructableValidationTask<T extends ValidationTaskTemplate = ValidationTaskTemplate> =
  new (e: ValidationError | ValidationErrorTuple | string) => T

export type ValidationChildStages = { match: object | object[], stages: string | string[] }

export interface ValidationContextOptions<T extends object = any> {
  bulk?:        Bulk<T>
  model?:       T
  event?:       string | string[]
  childStages?: ValidationChildStages[]
  extras?:      any
}

export type ValidationContext<T extends object = any> =
  TaskContext<any, any, ValidationContextOptions<T>, ValidationError>

export interface ValidationOptions<Stage> {
  stages?: Stage[]
  event?:  string | string[]
  childStages?: ValidationChildStages[]
}

export interface ValidationSetupOptions {
  override?: boolean
}
