import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { DatePipe, DEFAULT_DATE_FORMAT } from "./date.pipe";
import { GenericPipe } from "./generic.pipe";
import { DEFAULT_TIME_FORMAT, TimePipe } from "./time.pipe";

@NgModule({
  declarations: [
    DatePipe,
    TimePipe,
    GenericPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DatePipe,
    TimePipe,
    GenericPipe
  ]
})
export class NgpPipesModule {
  static forRoot(options?: {
    dateFormat?: string,
    timeFormat?: string
  }): ModuleWithProviders<NgpPipesModule> {
    return {
      ngModule: NgpPipesModule,
      providers: [
        { provide: DEFAULT_DATE_FORMAT, useValue: options?.dateFormat || "YYYY/MM/DD HH:mm:ss" },
        { provide: DEFAULT_TIME_FORMAT, useValue: options?.timeFormat || "HH:mm:ss" }
      ]
    };
  }
}
