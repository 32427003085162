import { FormatTaskTemplate } from "./task-template";

export class FormatLowercaseTask extends FormatTaskTemplate {
  static readonly singleton = new FormatLowercaseTask();

  override configure(): this {
    return FormatLowercaseTask.singleton as this;
  }

  override format(value: any): any {
    return typeof value === "string" ? value.toLowerCase() : value;
  }
}
