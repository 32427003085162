import { Injectable } from "@angular/core";
import { factory } from "src/@prisma/js-utils";
import { ApiConfig } from "../api-config.service";
import { NgDefaultTask } from "./default.task";

@Injectable()
export class PreRequestTask extends NgDefaultTask {
  static STAGE = "pre-request";
  MAP = { [PreRequestTask.STAGE]: this };

  constructor(
    apiConfig: ApiConfig
  ) {
    super(async context => {
      const options = context.requireOptions();
      if (options.hasCache) return;

      options.headers = { ...await factory(apiConfig.defaultHeader, context), ...options.headers };

      if (options.headers.authorization === void 0) {
        options.headers.authorization = await apiConfig.getToken(context) || "";
      }

      if (options.headers.type === void 0) {
        options.headers.type = "application/json";
      }
    });
  }
}
