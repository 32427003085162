import { Injectable } from "@angular/core";
import { HttpTask, GetCacheTask, BodyBuilderTask, ParseParamsTask, PreRequestTask, RequestTask, EmitEventTask, BulkImportTask, ClearCacheTask } from "../tasks";
import { NgEventRunner } from "./event.runner";

@Injectable()
export class NgEventReadRunner extends NgEventRunner {
  override readonly RUNNER = "read";

  protected override onInit(): void {
    this.reorder([
      HttpTask.STAGE,
      ParseParamsTask.STAGE,
      BodyBuilderTask.STAGE,
      PreRequestTask.STAGE,
      GetCacheTask.STAGE,
      RequestTask.STAGE,
      BulkImportTask.STAGE,
      ClearCacheTask.STAGE,
      EmitEventTask.STAGE
    ]);
  }
}
