import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FilterDirective } from "./filter.directive";

@NgModule({
  declarations: [
    FilterDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FilterDirective
  ]
})
export class NgpFilterModule {}
