import { FormArray, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { Constructable, ModelNode, TaskContext, ValidationContextOptions, ValidationError } from "../../js-proto";
import { DeepPartialArray } from "../../js-utils";
import { FormArrayModel } from "./form-array-model";
import { FormControlModel } from "./form-control-model";
import { FormGroupModel } from "./form-group-model";

export type NonArray<Model> = Model extends any[] ? Model[number] : Model;

export interface ForcedControls<Model> {
  controls?: (keyof Model)[],
  children?: Partial<{
    [P in keyof Model]: ForcedControls<NonArray<Required<Model>[P]>>
  }>
}

export type CascadeOption<Model> = {
  [P in keyof NonArray<Model>]?: CascadeOption<NonArray<NonArray<Model>[P]>>
} | boolean

export interface FormModelOptions<Model = any, FC extends ForcedControls<NonArray<Model>> = {}> {
  event?:            string | string[]
  useDefaultValues?: boolean
  ignore?:           Constructable<any> | Constructable<any>[]
  value?:            DeepPartialArray<Model>
  forceControls?:    FC,
  cascade?:          CascadeOption<Model>,
  _root?:            boolean
}

export interface FormManagerSubscriptionIndex { [path: string]: { [key: string]: Subscription } }

export type NgValidationContext = TaskContext<any, any, NgValidationContextOptions, ValidationError>
export interface NgValidationContextOptions extends ValidationContextOptions {
  extras: FormControlModel
}

export type NonGroupType = Date | RegExp | string | boolean | number;

type FA<F> = F extends any[] ? F : never[];
type IP<I, P> = P extends keyof I ? I[P] : never
type ToBool<T> = T extends true | false ? boolean : T;

export type FormComponents<
  Model, FC extends ForcedControls<any>,
  NAT = Required<NonNullable<NonArray<Model>>>> =
{
  [P in keyof NAT]: P extends FA<FC["controls"]>[number] ?
    FormControlModel<NAT[P]> :
    FormNode<NAT[P], IP<FC["children"], P> extends ForcedControls<any> ? IP<FC["children"], P> : {}>
};

export type FormNode<
  Model,
  FC extends ForcedControls<any>,
  NAT = Required<NonNullable<NonArray<Model>>>> =
  Model extends any[] ?
    FormArray<FormNode<NAT, FC>> : NAT extends NonGroupType ?
      FormControlModel<ToBool<NAT>> : FormGroup<FormComponents<Model, FC>>;

export type FormModelComponents<
  Model, FC extends ForcedControls<any>,
  NAT = Required<NonNullable<NonArray<Model>>>> =
{
  [P in keyof NAT]: P extends FA<FC["controls"]>[number] ?
    FormControlModel<NAT[P]> :
    FormNodeModel<NAT[P], IP<FC["children"], P> extends ForcedControls<any> ? IP<FC["children"], P> : {}>
};

export type FormNodeModel<
  Model,
  FC extends ForcedControls<any>,
  NAT = Required<NonNullable<NonArray<Model>>>> =
  Model extends any[] ?
    FormArrayModel<Model[number], FC> : NAT extends NonGroupType ?
      FormControlModel<ToBool<NAT>> : FormGroupModel<NAT & object, FC>;

export interface IFormModel {
  node:      ModelNode
  parent:    FormGroupModel | FormArrayModel | null
  root:      FormGroupModel | FormArrayModel | null

  parentArray: FormArrayModel<any, {
    controls: [];
    children: {};
  }> | null
  parentGroup: FormGroupModel<any, {
    controls: [];
    children: Partial<{
      [x: string]: ForcedControls<any>;
    }> | undefined;
  }> | null

  destroy(): void
  getMask(path: string | (string | number)[]): string
  hasMask(path: string | (string | number)[]): boolean
  removeFromArray(): boolean
  watch(cb?: (value: any) => any): Subscription | undefined
}

export type FormModel = FormControlModel| FormArrayModel<any, any> | FormGroupModel<any, any>;

export type FCDefaultGroup<Model> = {
  controls: [], children: ForcedControls<NonArray<Model>>["children"]
}

export type FCDefaultArray = {
  controls: [], children: {}
}

