import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guard/app.guard";

const routes: Routes = [
  {
    path: "pages",
    loadChildren: () => import("./pages/pages.module")
      .then(e => e.PagesModule),
    canActivateChild: [AuthGuard]
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module")
      .then(e => e.AuthModule)
  },
  { path: "**", redirectTo: "pages" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
