import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialogData } from "src/utils/interfaces";
import { DefaultDialogComponent } from "../default-dialog/default-dialog.component";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent {
  data?: ConfirmDialogData;
  inProcess = false;

  text = /*@i18n*/("Do you really want to delete %s?");
  btnYesText = /*@i18n*/("Delete");
  btnNoText = /*@i18n*/("Back");
  btnYesColor = "warn";
  btnNoColor = "";

  constructor(
    private dialog: MatDialogRef<DefaultDialogComponent<ConfirmDialogData>>
  ) {
    this.data = dialog.componentInstance.getData();

    if (this.data?.text) {
      this.text = this.data.text;
    }
    if (this.data?.btnText?.yes) {
      this.btnYesText = this.data.btnText.yes;
    }
    if (this.data?.btnText?.no) {
      this.btnNoText = this.data.btnText.no;
    }
    if (this.data?.btnColor?.yes) {
      this.btnYesColor = this.data.btnColor.yes;
    }
    if (this.data?.btnColor?.no) {
      this.btnNoColor = this.data.btnColor.no;
    }
  }

  close(): void {
    this.dialog.close();
  }

  async chooseYes(): Promise<void> {
    try {
      this.inProcess = true;
      await this.data?.confirm();
      this.close();
    } finally {
      this.inProcess = false;
    }
  }

  async chooseNo(): Promise<void> {
    try {
      await this.data?.cancel?.();
    } finally {
      this.close();
    }
  }
}
