import { FormatterPool } from "../formatter";
import { FieldOptions } from "../interfaces";
import { ValidatorPool } from "../validator";

export class ModelMetadata<T extends object = any> {
  public readonly fields: { [U in keyof T]?: FieldOptions<T[U]> } = {};
  public readonly validator = new ValidatorPool<T>();
  public readonly formatter = new FormatterPool<T>();

  public primaryColumn?: string;
}
