import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { __ } from "src/@prisma/ng-i18n";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { NgEventPool, NgRunnerOptions } from "src/@prisma/ng-runner";
import { Group } from "src/models/group.model";
import { DefaultService } from "./default.service";

@Injectable({
  providedIn: "root",
})
export class GroupService extends DefaultService<Group> {
  constructor(
    pool:   NgEventPool,
    feed:   MatFeed,
    dialog: MatDialog
  ) {
    super(Group, pool, feed, dialog, {
      name:        /*@i18n*/("Group"),
      pluralName:  /*@i18n*/("Groups"),
      deleteError: /*@i18n*/("No Group selected"),
      deleteName:  group => __/*@i18n*/("the group \"%s\"", group.name?.toUpperCase() || "")
    });
  }

  override get(opts?: NgRunnerOptions<Group> | undefined): Promise<Group[]> {
    if (opts?.filter && !Array.isArray(opts.filter) && opts.filter.id && !opts.filterOptions)
      opts.filterOptions = {
        relations: [ "users", "users.user" ]
      };
    return super.get(opts);
  }
}
