export type PartialRecord<K extends number | string | symbol, T> = Partial<Record<K, T>>

export type DeepPartial<T> = T | (
  T extends Array<infer U> ?
    DeepPartial<U>[] : T extends Map<infer K, infer V> ?
      Map<DeepPartial<K>, DeepPartial<V>> : T extends Set<infer M> ?
        Set<DeepPartial<M>> : T extends object ? {
          [K in keyof T]?: DeepPartial<T[K]>;
        } : T
);

export type DeepPartialArray<T> = NonNullable<T> extends infer NNT ? (
  NNT extends any[] ? DeepPartialArray<NNT[number]>[] : NNT extends object ? {
    [U in keyof NNT]?: DeepPartialArray<NNT[U]>
  } : T | undefined
) : never

export type Keyof<T> = Extract<keyof T, string>;

export type CSVMapHeader<Map extends string> = PartialRecord<Map, string>;
export type CSVMapData<
  Model extends Record<string | number | symbol, any>, Map extends string
> = Partial<{
  /**
   * Function for transform table cell
   * @param cell cell value
   * @param row row data
   * @param data table data
   */
  [P in Map]: (
    cell: Model[P], row: Model, data: Model[]
  ) => string | number;
}> | ((row: Model, data: Model[]) => Record<Map, string | number>);

export interface CSVOptions<Model extends Record<string | number | symbol, any>, Map extends string> {
  /**
   * columns of table
   */
  header?:    Map[]

  /**
   * data of table body
   */
  data?:      Model[]

  /**
   * delimiter between cells
   * default: `;`
   */
  delims?:    string

  /**
   * object for map header of table
   */
  mapHeader?: CSVMapHeader<Map>

  /**
   * object for map data of table body
   */
  mapData?:   CSVMapData<Model, Map>
}
