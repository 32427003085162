import { FormatTaskTemplate } from "./task-template";

interface FormatNullifyTaskConfig {
  undefined: boolean,
  string: boolean
}

export class FormatNullifyTask extends FormatTaskTemplate {
  protected config: FormatNullifyTaskConfig = {
    undefined: true,
    string: true
  };
  private values: Record<keyof FormatNullifyTaskConfig, any> = {
    undefined: void 0,
    string: ""
  };

  override configure(config?: Partial<FormatNullifyTaskConfig>): this {
    return (this.config = { ...config, ...this.config }, this);
  }

  override format(value: any): any {
    Object.keys(this.config)
      .filter(key => this.config[key as keyof typeof this.config])
      .forEach(key => {
        value = value === this.values[key as keyof typeof this.config] ? null : value;
      });
    return value;
  }
}
