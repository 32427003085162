import { Bulk, ConstructableNode } from "src/@prisma/js-proto";
import { DeepPartial, Exception, factory } from "src/@prisma/js-utils";
import { Observable, RetryConfig, retry } from "rxjs";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogData, DefaultDialogOptions } from "src/utils/interfaces";
import { ConfirmDialogComponent } from "../utils/confirm-dialog/confirm-dialog.component";
import { DefaultDialogComponent } from "../utils/default-dialog/default-dialog.component";
import { __ } from "src/@prisma/ng-i18n";
import { NgEventPool, NgRunnerOptions } from "src/@prisma/ng-runner";

export type ItemsParameters<Model extends object> = DeepPartial<Model> | DeepPartial<Model>[] | Bulk<Model>;
export abstract class DefaultService<Model extends object> {
  protected eventsToListen = [ "create", "update", "delete" ];

  constructor(
    readonly model:  ConstructableNode<Model>,
    readonly pool:   NgEventPool,
    readonly feed:   MatFeed,
    readonly dialog: MatDialog,
    readonly opts: {
      deleteError: string,
      deleteName:  string | ((model: Model) => string),
      name:        string | (() => string),
      pluralName:  string | (() => string)
    }
  ) {}

  bulk(): Bulk<Model> {
    return new Bulk(this.model);
  }

  async get(opts?: NgRunnerOptions<Model>): Promise<Model[]> {
    return await this.pool.read(this.model, opts);
  }

  async getSingle(opts?: NgRunnerOptions<Model> & { index?: number, noTake?: boolean }):
  Promise<Model | undefined> {
    if (!opts?.noTake) {
      opts = { ...opts, filterOptions: { take: 1, ...opts?.filterOptions } };
    }
    return await this.get(opts).then(e => e.at(opts?.index ?? -1));
  }

  protected baseObserve<T>(
    callback: () => Promise<T>,
    countOrConfig?: number | RetryConfig
  ): Observable<T> {
    return new Observable<T>(subscribe => {
      const check = (): Promise<any> => callback()
        .then(value => subscribe.next(value))
        .catch(err => subscribe.error(err));
      check();
      const indexes = NgEventPool.on(this.model, this.eventsToListen, check);
      return () => NgEventPool.removeListener(indexes);
    }).pipe(typeof countOrConfig === "object" ? retry(countOrConfig) : retry(countOrConfig));
  }

  observe(
    opts?: NgRunnerOptions<Model> | (() => NgRunnerOptions<Model>),
    countOrConfig?: number | RetryConfig
  ): Observable<Model[]> {
    return this.baseObserve(() => this.get(factory(opts)), countOrConfig ?? { delay: 2000 });
  }

  deleteDialog(item: Model | Model[], onSuccess?: () => any, onError?: () => any): void {
    if (Array.isArray(item) && item.length > 100) {
      this.feed.warn/*@i18n*/("Maximum 100 %s to delete", "", { args: factory(this.opts.pluralName) });
      return;
    }

    const data: DefaultDialogOptions<ConfirmDialogData> = {
      component: ConfirmDialogComponent,
      title: /*@i18n*/("Delete confirm"),
      icon: "delete",
      data: {
        name: Array.isArray(item) ?
          __("the selected %s", __(factory(this.opts.pluralName))) : factory(this.opts.deleteName, item),
        confirm: async () => {
          try {
            await this.delete(item);
            if (Array.isArray(item) && item.length > 1)
              this.feed.success/*@i18n*/("%s deleted successfully! (plural)", "", {
                args: __(factory(this.opts.pluralName))
              });
            else this.feed.success/*@i18n*/("%s deleted successfully!", "", {
              args: __(factory(this.opts.name))
            });
            onSuccess?.();
          } catch (e) {
            this.feed.fromError(e);
            onError?.();
          }
        }
      }
    };

    this.dialog.open(DefaultDialogComponent, {
      width: "300px",
      data
    });
  }

  async delete<T = any>(items: ItemsParameters<Model>, opts?: NgRunnerOptions): Promise<T> {
    return await this.pool.delete(this.toBulk(items), opts);
  }

  async update<T = any>(items: ItemsParameters<Model>, opts?: NgRunnerOptions): Promise<T> {
    return await this.pool.update(this.toBulk(items), opts);
  }

  async create<T = any>(items: ItemsParameters<Model>, opts?: NgRunnerOptions): Promise<T> {
    return await this.pool.create(this.toBulk(items), opts);
  }

  protected toBulk(items: ItemsParameters<Model>): Bulk<Model> {
    items = items instanceof Bulk ? items : Bulk.fromCreate(items, this.model);
    if (!items.length) throw new Exception(this.opts.deleteError, "");
    return items;
  }
}
