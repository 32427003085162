import { ModuleWithProviders, NgModule } from "@angular/core";
import { NgI18nConfiguration } from "../interface";
import { NgI18nTranslator } from "../ngi18n-translator.class";
import { I18N_CONFIG, NgI18n } from "./ng-i18n.service";

@NgModule({
  providers: [
    NgI18n
  ]
})
export class NgI18nConfigModule {
  static forRoot<L extends string>(
    config: NgI18nConfiguration<L> = {}
  ): ModuleWithProviders<NgI18nConfigModule> {
    if (!config.defaultLocale) config.defaultLocale = (navigator.languages as L[])
      .find(lang => config.locales?.find(e => e.name === lang)) || (Array.isArray(config.locales?.[0].name)
      ? config.locales?.[0].name[0] : config.locales?.[0].name);

    // Set default to false
    config.switchToDefault = !!config.switchToDefault;

    if (config.ignore) NgI18nTranslator.NG_I18N_IGNORE = config.ignore?.slice();

    return {
      ngModule: NgI18nConfigModule,
      providers: [
        { provide: I18N_CONFIG, useValue: config }
      ]
    };
  }
}
