import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DeepPartial } from "src/@prisma/js-utils";
import { FormGroupModel } from "src/@prisma/ng-forms";
import { UserService } from "src/app/services/user.service";
import { GroupService } from "src/app/services/group.service";
import { DefaultCreateComponent } from "src/app/utils/default-create.component";
import { User } from "src/models/user.model";
import { GroupUser } from "src/models/group-user.model";
import { Group } from "src/models/group.model";
import { AppBreakpoints } from "src/utils/breakpoints";
import { PoolingEventService } from "src/app/services/pooling-event.service";

@Component({
  selector: "group-form",
  templateUrl: "./group-form.component.html",
  styleUrls: ["./group-form.component.scss"]
})
export class GroupFormComponent extends DefaultCreateComponent<Group> {
  @ViewChild("searchInput") searchInput!: ElementRef<HTMLInputElement>;
  @ViewChild("listScroll") listScroll!: ElementRef<HTMLElement>;

  private subscription = new Subscription();
  private allUsers: User[] = [];

  small = true;
  searchUserOpts: { user: User, label: string }[] = [];

  dataSource = new MatTableDataSource<FormGroupModel<Required<GroupUser>>>();
  get users(): FormGroupModel<Required<GroupUser>>[] {
    return this.dataSource.data;
  }
  set users(value) {
    this.dataSource.data = value;
  }

  constructor(
    groupService:        GroupService,
    router:              Router,
    route:               ActivatedRoute,
    poolingEventService: PoolingEventService,
    private userService: UserService,
    private breakpoints: BreakpointObserver
  ) {
    super(groupService, router,  route, {
      createText: /*@i18n*/("Group created successfully"),
      deleteText: /*@i18n*/("Group deleted successfully"),
      updateText: /*@i18n*/("Group updated successfully"),
      formConfig: {
        cascade: {
          users: {
            user: {}
          }
        }
      }
    });

    poolingEventService.connect([ User ])
      .also(it => this.subscription.add(it));
  }

  async ngOnInit(): Promise<void> {
    this.subscription.add(
      this.breakpoints.observe(AppBreakpoints.SmallOrLess)
        .subscribe(({ matches }) => {
          this.small = matches;
        })
    );

    this.subscription.add(
      this.userService.observe().subscribe(users => {
        this.allUsers = users;

        this.updateUsers();
      })
    );
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  override async formInit(userId?: string | undefined): Promise<void> {
    await super.formInit(userId);

    const array: FormGroupModel<Required<GroupUser> & object, never>[] = [];

    this.form.controls.users.controls.forEach(e => {
      if (!e.value.user?.enabled) array.push(e);
    });
    array.forEach(e => e.removeFromArray());

    this.updateUsers();
    this.form.watch("users", () => {
      this.updateUsers();
    });
  }

  override reset(): void {
    this.users = [];
    super.reset();
  }

  filterSearch(search = ""): void {
    this.searchUserOpts = this.allUsers
      .filter(e => !this.users.find(i => i.value.userId === e.id))
      .filter(e => e.name.toLowerCase().includes(search.toLowerCase()))
      .sort((a, b) => a.name > b.name ? 1 : -1)
      .map(user => ({
        user,
        label: `(${user.identification.toUpperCase()}) ${user.name.toUpperCase()}`
      }));
  }

  updateUsers(): void {
    this.users = this.form.controls.users.controls;
    this.filterSearch();
  }

  addUser(value: DeepPartial<GroupUser>): void {
    this.form.controls.users.insertNode(value);
  }

  removeUser(index: number): void {
    this.form.controls.users.removeAt(index);
  }

  selectUser(user: User, input: HTMLInputElement): void {
    const id = this.form.get("id")?.value;

    input.value = "";

    this.addUser({
      user,
      // group: this.form.value,
      userId: user.id,
      groupId: id
    });

    this.listScroll.nativeElement.also(it => it.scrollTo(0, it.scrollHeight));

    this.searchInput.nativeElement.value = "";
    this.searchInput.nativeElement.dispatchEvent(new Event("keydown", {
      bubbles: true,
      cancelable: true,
    }));
  }
}
