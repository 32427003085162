import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessagesComponent } from "./messages.component";
import { ListComponent } from "./list/list.component";
import { FormComponent } from "./form/form.component";
import { MessagesRoutingModule } from "./messages-routing.module";
import { DataTableModule } from "src/app/utils/data-table/data-table.module";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { DefaultFormContainerModule } from "src/app/utils/default-form-container/default-form-container.module";
import { ConfirmDialogModule } from "src/app/utils/confirm-dialog/confirm-dialog.module";
import { NgxMaskModule } from "ngx-mask";
import { DetailsComponent } from "./details/details.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";


@NgModule({
  declarations: [
    MessagesComponent,
    ListComponent,
    FormComponent,
    DetailsComponent
  ],
  imports: [
    CommonModule,
    DataTableModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MessagesRoutingModule,
    DefaultFormContainerModule,
    ConfirmDialogModule,
    NgxMaskModule
  ]
})
export class MessagesModule { }
