import { FormatTaskTemplate } from "./task-template";

export class FormatTrimTask extends FormatTaskTemplate {
  static readonly singleton = new FormatTrimTask();

  override configure(): this {
    return this;
  }

  override format(value: any): any {
    return typeof value === "string" ? value.trim() : value;
  }
}
