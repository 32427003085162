import { FieldError } from "../exceptions";
import { ValidationContext, ValidationContextOptions, ValidationError, ValidationErrorTuple } from "../interfaces";
import { Task, TaskContext } from "../runner";

export abstract class ValidationTaskTemplate
  extends Task<any, any, ValidationContextOptions, ValidationContext>
{
  protected error: ValidationError;
  protected parseArray = true;

  constructor(error: ValidationError)
  constructor(message: string, code?: string)
  constructor(
    errorOrMessage: ValidationError | ValidationErrorTuple | string,
    code?: string
  )
  constructor(
    errorOrMessage: ValidationError | ValidationErrorTuple | string,
    code?: string
  ) {
    super();

    if (typeof errorOrMessage === "string") {
      this.error = { message: errorOrMessage, code };
    } else if (Array.isArray(errorOrMessage)) {
      this.error = { message: errorOrMessage[0], code: errorOrMessage[1] };
    } else {
      this.error = errorOrMessage;
    }

    this.setDefaultContext(TaskContext);
  }

  abstract configure(...args: any[]): this

  protected abstract validate(context: ValidationContext): Promise<void>;

  protected getFieldError(error: ValidationError | FieldError = this.error): FieldError {
    if (FieldError.isFieldError(error)) return error;
    return new FieldError(this.error);
  }

  override async executeContext(context: ValidationContext): Promise<ValidationContext> {
    const payload = context;
    if (this.parseArray && Array.isArray(payload)) {
      for (let i = 0, len = payload.length; i < len; i++) {
        await this.validate(new TaskContext(payload[i], context.options));
      }
    } else await this.validate(context);
    return context;
  }
}
