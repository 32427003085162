import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportsComponent } from "./reports.component";
import { ReportsRoutingModule } from "./reports-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { NgI18nModule, __ } from "src/@prisma/ng-i18n";
import { I18nDatePickerModule } from "src/app/utils/i18n-date-picker.module";
import { NgpPipesModule } from "src/@prisma/ng-utils";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";


@NgModule({
  declarations: [
    ReportsComponent
  ],
  imports: [
    ReportsRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTableModule,
    MatDialogModule,
    MatIconModule,
    I18nDatePickerModule,
    NgI18nModule,
    NgpPipesModule.forRoot({
      dateFormat: __("MM/DD/YYYY")
    })
  ]
})
export class ReportsModule { }
