<div class="footer">
  <ng-container *ngIf="!withoutMenu else textFooter">
    <div *ngFor="let menu of menus; let i = index" class="m-auto">
      <button mat-button (click)="menu.select?.(menu, i)" [class]="menu.className || ''"
        [routerLink]="menu.path" routerLinkActive="active">
        <mat-icon>{{ menu.icon }}</mat-icon>
        <small *ngIf="menu.label && showText">{{ menu.label }}</small>
      </button>
    </div>
  </ng-container>

  <ng-template #textFooter>
    <span class="footer-text" *ngIf="about?.email && about?.phone">
     <!--@i18n-->For questions and support contact with Alghorit<!---->
      <span class="pre"> | {{ about?.email }} | </span>
      <span class="pre">{{ about?.phone }}</span>
    </span>
  </ng-template>
</div>
