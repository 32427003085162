import * as xlsx from "xlsx";
import { CSVBuilder } from "src/@prisma/js-utils";
import { formatDate } from "./util";

export interface ExcelReportInfo {
  type:    string,
  start?:  number,
  end?:    number,
  builder: CSVBuilder<any, any>
}

export function exportExcel({ type, start, end, builder }: ExcelReportInfo): void {
  const book         = xlsx.utils.book_new();
  const header       = builder.getHeader();
  const mapHeader    = builder.getMapHeader();
  const mappedHeader = header.map(e => mapHeader[e] || e);
  const headerLen    = header.length;
  const data         = builder.buildData().map(e => {
    const row: Record<string, string | number> = {};
    for (let i = 0; i < headerLen; i++)
      row[mappedHeader[i]] = e[header[i]];
    return row;
  });

  xlsx.utils.book_append_sheet(book, xlsx.utils.json_to_sheet(data, {
    header: mappedHeader
  }));

  xlsx.writeFile(
    book, `${type.toLowerCase()}${
      start ? "__" + formatDate(start) : ""
    }${end ? "__" + formatDate(end) : ""}.xlsx`.replace(/(\/|:)/g, "-").replace(/\s/g, "_"));
}
