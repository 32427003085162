import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { __ } from "src/@prisma/ng-i18n";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { NgEventPool } from "src/@prisma/ng-runner";
import { MessageTemplate } from "src/models/message-template.model";
import { DefaultService } from "./default.service";

@Injectable({
  providedIn: "root"
})
export class MessageTemplateService extends DefaultService<MessageTemplate> {
  constructor(
    pool:   NgEventPool,
    feed:   MatFeed,
    dialog: MatDialog
  ) {
    super(MessageTemplate, pool, feed, dialog, {
      name:        /*@i18n*/("Message Template"),
      pluralName:  /*@i18n*/("Message Templates"),
      deleteError: /*@i18n*/("No Message Template selected"),
      deleteName:  (item) => __("the message template \"%s\"", item.id)
    });
  }
}
