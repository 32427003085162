import { animate, AUTO_STYLE, state, style, transition, trigger } from "@angular/animations";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, SortDirection } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { factory, toArray } from "src/@prisma/js-utils";
import { MatFeed } from "src/@prisma/ng-mat-feed";
import { DefaultService } from "src/app/services/default.service";
import { TableField } from "src/utils/interfaces";
import { getTablePageLength, setTablePageLength } from "src/utils/util";
import { BreakpointObserver } from "@angular/cdk/layout";
import { AppBreakpoints } from "src/utils/breakpoints";
import { Platform } from "@angular/cdk/platform";
import { fadeInOutAnimation } from "src/utils/fade-in-out-animation";
import { CacheManager } from "src/@prisma/ng-utils";
import { NgRunnerOptions } from "src/@prisma/ng-runner";

export interface DataTableOptions<Model> {
  deleteName?: string | ((model: Model) => string);
}

const animationTime = 300;

export type DataTableRow<Model> = {
  data:      Model,
  formatted: Record<string, string | number>
}
@Component({
  selector: "data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ visibility: "collapse" })),
      state("expanded", style({ height: AUTO_STYLE, visibility: "visible" })),
      transition("expanded => collapsed", animate(`${ animationTime }ms ease-out`,
        style({ display: "block", "max-height": "0", "height": "0" }))),
      transition("collapsed => expanded", animate(`${ animationTime }ms ease-in`))
    ]),
    fadeInOutAnimation()
  ]
})
export class DataTableComponent<Model extends { id: string }> implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  @ViewChild("divTable", { read: ElementRef })
  private divTable!: ElementRef<HTMLDivElement>;
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.dataSource.sort = value;
    this.dataSource.sort.sortChange
      .subscribe(() => this.updatePageItems())
      .also(it => this.subscription.add(it));
  }

  private subscription = new Subscription();
  private cache = new CacheManager();
  private expanded: Model[] = [];
  private _fields: TableField<Model>[] = [];
  private _blockNewItem = false;
  private _blockMessage?: string | string[];
  private _showEdit = true;
  private _showSelect = true;

  private resizeObserver = new ResizeObserver(() => {
    this.onResize();
  });

  private get data(): DataTableRow<Model>[] {
    return this.dataSource.data;
  }
  private set data(value: DataTableRow<Model>[]) {
    this.dataSource.data = value;
    this.updatePageItems();
  }

  form = new FormControl("");
  dataSource = new MatTableDataSource<DataTableRow<Model>>();
  columnsToDisplay: string[] = [];
  selection = new SelectionModel<Model>(true, []);
  pageSize = getTablePageLength();
  animation: boolean;
  xSmall = true;

  get details(): boolean {
    return !!this.detailsTemplate;
  }
  get width(): number {
    return this.divTable.nativeElement.clientWidth;
  }
  get blockMessage(): string | string[] | undefined { return this._blockMessage; }

  @Output("widthChanges") onResizeWidth = new EventEmitter<number>();

  @Input() service!: DefaultService<Model>;
  @Input() sortBy?: (a: Model, b: Model) => number;
  @Input() detailsTemplate?: TemplateRef<Model> | null;
  @Input() multipleDetails = false;
  @Input() showNew = true;
  @Input() showDelete = true;
  @Input("header-reverse") inverse = false;
  @Input() rowClassGetter?: (row: Model) => string[];
  @Input() headerTemplate?: TemplateRef<unknown>;
  @Input("runner-options") runnerOptions?: NgRunnerOptions<Model> | (() => NgRunnerOptions<Model>);
  @Input("sort-active") sortActive = "";
  @Input("sort-direction") sortDirection: SortDirection = "asc";

  @Input()
  get showEdit(): boolean {
    return this._showEdit;
  }
  set showEdit(value: boolean) {
    this._showEdit = value;
    this.columnsToDisplay = this.getColumnsToDisplay();
  }

  @Input()
  get showSelect(): boolean {
    return this._showSelect;
  }
  set showSelect(value: boolean) {
    this._showSelect = value;
    this.columnsToDisplay = this.getColumnsToDisplay();
  }

  @Input()
  get fields(): TableField<Model>[] { return this._fields; }
  set fields(value) {
    this._fields = value;
    this.columnsToDisplay = this.getColumnsToDisplay();
  }

  @Input()
  get blockNewItem(): boolean { return this._blockNewItem; }
  set blockNewItem(value: string | string[] | boolean) {
    this._blockNewItem = coerceBooleanProperty(value);
    if (!this.blockNewItem) this._blockMessage = void 0;
    else if (typeof value !== "boolean" && value !== "false" && value !== "true") this._blockMessage = value;
  }

  pageData: DataTableRow<Model>[] = [];

  private getColumnsToDisplay(): string[] {
    return [
      ...(this.showSelect ? ["select"] : []),
      ...this._fields.map(e => e.key),
      ...(this.showEdit ? ["action"] : [])
    ];
  }

  constructor(
    private router:     Router,
    private feed:       MatFeed,
    private breakpoint: BreakpointObserver,
    platform:           Platform
  ) {
    this.dataSource.filterPredicate = ({ formatted }: DataTableRow<Model>, filter: string): boolean => {
      return Object.keys(formatted).some(e => formatted[e].toString().toLowerCase().includes(filter));
    };
    this.dataSource.sortData = (data: DataTableRow<Model>[], sort: MatSort): DataTableRow<Model>[] => {
      let operator = 0;
      const active = sort.active as Extract<keyof Model, string>;

      if (sort.direction === "asc") {
        operator = 1;
      }
      if (sort.direction === "desc") {
        operator = -1;
      }
      return data.sort((a, b) => (a.data[active] > b.data[active] ? 1 : -1) * operator);
    };
    this.animation = !(platform.FIREFOX || platform.SAFARI);
  }

  factory = factory;

  ngOnInit(): void {
    this.subscription.add(
      this.form.valueChanges.subscribe(value => {
        this.dataSource.filter = value?.toString()?.trim().toLowerCase() || "";
        this.updatePageItems();
      })
    );

    this.subscription.add(
      this.breakpoint.observe(AppBreakpoints.XSmall).subscribe(({ breakpoints }) => {
        this.xSmall = breakpoints[AppBreakpoints.XSmall];
      })
    );

    this.subscription.add(
      this.service.observe(this.runnerOptions).subscribe({
        next: data => {
          const newData = (this.sortBy ? data.sort(this.sortBy) : data).map(model => {
            return {
              formatted: this.fields.reduce((acc: Record<string, string>, field) => {
                acc[field.key] = field.get(model);
                return acc;
              }, {}),
              data: model
            };
          });
          if (this.data.length !== newData.length) {
            this.data = newData;
          } else {
            newData.forEach((e, i) => {
              const initial = this.data.find(d => d.data.id === e.data.id);

              if (initial) {
                const selected = this.selection.isSelected(initial.data);

                if (selected) this.selection.deselect(initial.data);

                initial.data = e.data;
                initial.formatted = e.formatted;

                if (selected) this.selection.select(initial.data);
              } else {
                this.data[i].data = e.data;
                this.data[i].formatted = e.formatted;
              }
            });
          }
        },
        error: e => this.feed.fromError(e)
      })
    );
  }

  ngAfterViewInit(): void {
    this.onResize();
    this.resizeObserver.observe(this.divTable.nativeElement);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.resizeObserver.disconnect();
  }

  isTemplate(value: any): value is TemplateRef<unknown> {
    return (value instanceof TemplateRef);
  }

  setPageSize(pageSize: number): void {
    setTablePageLength(pageSize);
    this.updatePageItems();
  }

  updatePageItems(): void {
    this.pageData = this.dataSource._pageData(this.dataSource._orderData(this.dataSource.filteredData));
  }

  newItem(): void {
    if (this.blockMessage?.length) {
      const messages = toArray(this.blockMessage);
      this.feed.warn(messages[0], "", { args: messages.slice(1) });
      return;
    }
    this.router.navigate([ this.router.url.replace("/list", "/form") ]);
  }

  editItem(item: Model): void {
    this.router.navigate([ this.router.url.replace("/list", "/form") ], { queryParams: { id: item.id } });
  }

  async deleteItem(item: Model | typeof this.selection): Promise<void> {
    (item instanceof SelectionModel ? item.selected : item)
      .also(it => this.service.deleteDialog(it, () => {
        if (item instanceof SelectionModel) item.clear();
      }));
  }

  whenExpandFunction(): boolean {
    return this.details;
  }

  whenExpand(): () => boolean {
    return this.whenExpandFunction.bind(this);
  }

  toggleExpanded(row: Model): void {
    const index = this.expanded.indexOf(row);

    if (index === -1) {
      if (!this.multipleDetails) {
        this.expanded = [];
      }
      this.expanded.push(row);
    } else {
      this.expanded.splice(index, 1);
    }
  }

  isExpanded(row: Model): boolean {
    return this.expanded.includes(row);
  }

  isAllSelected(): boolean {
    return this.getSelectedInPage().length === this.pageData.length;
  }

  getSelectedInPage(): DataTableRow<Model>[] {
    return this.pageData.filter(e => this.selection.isSelected(e.data));
  }

  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.deselect(...this.pageData.map(e => e.data));
      return;
    }

    this.selection.select(...this.pageData.map(e => e.data));
  }

  private onResize(): void {
    this.onResizeWidth.emit(this.width);
  }

  getClass(row: Model, field: TableField<Model>): Record<string, boolean> {
    return this.cache.getCacheOrExecute(`${ row.id }-${ field.key }`, () => {
      let className = field.className;

      if (typeof className === "function") {
        className = className(row);
      }
      const classes = toArray(className || []);
      const ret: Record<string, true> = {};

      classes.forEach(e => {
        ret[e] = true;
      });
      return ret;
    });
  }

  rowClasses(row: Model): Record<string, boolean> {
    return this.cache.getCacheOrExecute(row, row => ({
      double: this.details,
      collapse: !this.isExpanded(row),
      ...((this.rowClassGetter?.(row) || []).reduce<Record<string, boolean>>((prev, current) => {
        prev[current] = true;
        return prev;
      }, {}))
    }));
  }
}
