import { DeviceModel } from "src/@prisma/agent-common";
import { HttpPath } from "src/@prisma/js-proto";
import { Mask, NgMask } from "src/@prisma/ng-forms";

@HttpPath("agent/device")
export class Device extends DeviceModel {
  override id!: string;

  @Mask(NgMask.maxLength(30))
  override serial!: string;

  @Mask(NgMask.maxLength(60))
  override name!: string;

  override createdAt!: Date;
}
