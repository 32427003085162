import { I18nLocales, I18nConfiguration, I18nDictionary, I18nSimpleGetter, I18nGetter } from "./interfaces";

export class I18n<L extends string = string> {
  private locales: Partial<I18nLocales<L>> = {};

  private currentLocale?: L;
  private defaultLocale?: L;
  private retryInLocale?: L;

  constructor(config?: I18nConfiguration<L>) {
    if (config) {
      if (config.locales) this.addLocales(config.locales);
      if (config.retryInLocale) this.retryInLocale = config.retryInLocale;
      if (config.defaultLocale) {
        if (config.switchToDefault !== false) this.switch(config.defaultLocale);
        this.defaultLocale = config.defaultLocale;
      }
    }
  }

  getCurrent(): L | undefined {
    return this.currentLocale;
  }

  getDefault(): L | undefined {
    return this.defaultLocale;
  }

  getRetry(): L | undefined {
    return this.retryInLocale;
  }

  getLocales(): L[] {
    return Object.keys(this.locales) as L[];
  }

  hasLocale(locale: string): locale is L {
    return this.getLocales().includes(locale as L);
  }

  /**
   * Change current locale. All text from getters will be update to the new locale.
   */
  switch(locale: L): this {
    if (this.hasLocale(locale)) {
      this.currentLocale = locale;
    } else {
      this.currentLocale = void 0;
    }
    return this;
  }

  /**
   * Add a new locale or override a existing locale. Can be used to change locale json dynamically.
   * @param name - Name of the locale that will be accessed through `switch` function.
   * @param dict - Dictionary object or a path to a JSON file with the dictionary.
   */
  addLocale(name: L | L[], dict: I18nDictionary): this {
    if (Array.isArray(name)) {
      name.forEach(e => this.addLocale(e, dict));
    } else {
      this.locales[name] = { ...dict };
    }

    return this;
  }

  /**
   * Add a multiple locales or override a existing locales. Can be used to change locale json dynamically.
   * @param locales.name - Name of the locale that will be accessed through `switch` function.
   * @param locales.dict - Dictionary object or a path to a JSON file with the dictionary.
   */
  addLocales(locales: { name: L | L[], dict: I18nDictionary }[]): this {
    locales.forEach(e => this.addLocale(e.name, e.dict));
    return this;
  }

  /**
   * Removes a existing locale. Can be used to change locale json dynamically.
   */
  removeLocale(name: L): this {
    delete this.locales[name];
    return this;
  }

  /**
   * Removes existing locales. Can be used to change locale json dynamically.
   */
  removeLocales(names: L[]): this {
    names.forEach(e => this.removeLocale(e));
    return this;
  }

  /**
   * Find some text in current dictionary.
   * @param retry - Use retryInLocale or defaultLocale if not found.
   */
  find(text: string, retry = true): string | undefined {
    /**
     * This is ugly but is 2x faster than:
     * ```
     * return this.locales[this.currentLocale]?.[text] ||
     *  (retry && (this.locales[this.retryInLocale || this.defaultLocale]?.[text])) || null;
     * ```
     */
    if (this.currentLocale) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.locales[this.currentLocale]![text];
    } else if (retry) {
      if (this.retryInLocale) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.locales[this.retryInLocale]![text];
      } else if (this.defaultLocale) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.locales[this.defaultLocale]![text];
      }
    }
  }

  /**
   * Creates a simple Getter. It can't be used with dynamic variables (Ex: %d dogs). Used
   * for better performance (~ **20%** faster than a normal *getter when used without variables*).
   */
  simpleGetter(): I18nSimpleGetter {
    return text => this.find(text) || text;
  }

  /**
   * Creates a Getter that can be used to search the dictionary of the current locale. Can be used
   * with %s and %d variables.
   */
  getter(): I18nGetter {
    return (text, ...args) => {
      let str = this.find(text) || text;

      if (args.length !== 0)
        for (const arg of args)
          if (typeof arg === "string") {
            str = str.replace("%s", arg);
          } else {
            str = str.replace("%d", arg as any);
          }

      return str;
    };
  }
}
