import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HTMLElementDirective } from "./html-element.directive";

@NgModule({
  declarations: [
    HTMLElementDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HTMLElementDirective
  ]
})
export class NgpHTMLElementModule {}
