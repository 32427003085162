import { Bulk } from "../core";

export interface BulkOptions {
  parent?: Bulk
}

export interface BulkImportOptions {
  /** Delete all items from current bulk and import the new items. default: `true`  */
  clear?: boolean
}
