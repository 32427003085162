<data-table [service]="service" [fields]="fields" [showEdit]="false" [rowClassGetter]="rowClassGetter"
[showNew]="false" [runner-options]="runnerOptions" sort-active="createdAt" sort-direction="desc"></data-table>

<ng-template #actions let-row="message">
  <div class="buttons">
    <button mat-raised-button (click)="confirmReceipt(row)" color="success">
      <mat-icon class="material-outlined-icons">check</mat-icon>
    </button>

    <button mat-raised-button (click)="showDetails(row)" color="accent">
      <mat-icon class="material-outlined-icons">open_in_new</mat-icon>
    </button>
  </div>
</ng-template>
