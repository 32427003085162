import validator from "validator";

/**
 * Generate a random string `/[a-z0-9]/i`.
 * @param size - string size.
 * @param lib - usable characters.
 */
export function randomString(size: number, lib?: string): string {
  if (!lib) {
    lib = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  }

  const len = lib.length;

  let str = "";

  for (let i = 0; i < size; i++) {
    str += lib.charAt(Math.floor(Math.random() * len));
  }

  return str;
}

/**
 * Pad character to start of a number/string.
 * @param n - string/number to pad.
 * @param size - number of characters in total.
 * @param fill - character to fill. Default `0`
 * @returns
 */
export function pad(n: number | string, size: number, fill = "0"): string {
  n = n.toString();
  while (n.length < size) {
    n = fill + n;
  }
  return n;
}

/**
 * Check if variable type is `string`.
 */
export function isString(value: any): value is string {
  return typeof value === "string";
}

/**
 * Check if variable is a numeric `string`.
 * @param opts.symbols - allow symbols. Ex: `+10`, `-3`, `1.3`. Default `false`
 */
export function isNumeric(value: any, opts?: { symbols?: boolean }): value is string {
  const regex = opts?.symbols ? /^(-|\+)?[0-9]+(.[0-9]+)?$/ : /^[0-9]+$/;
  return isString(value) && regex.test(value);
}

/**
 * Check if variable is a HEX `string`.
 */
export function isHex(value: any): value is string {
  return isString(value) && /^[0-9a-f]+$/i.test(value);
}

/**
 * Check if variable is a boolean `string`.
 */
export function isBoolString(value: any): value is "true" | "false"  {
  return isString(value) && ((value = value.toLowerCase()) === "true" || value === "false");
}

/**
 * Check if variable is a ISO date format `string`.
 */
export function isDate(value: any): value is string {
  return typeof value === "string" && validator.isISO8601(value);
}

/**
 * Check if variable is a time format `string`. `hh:mm[:ss]`
 *
 * Ex: `15:23:59`
 * @param seconds - Time has seconds. Default `false`
 */
export function isTime(value: any, seconds = false): value is string {
  const split = value.split(":");

  return (
    split.length > 1
    && !/[+-]/.test(value)
    && isNumeric(split[0])
    && isNumeric(split[1])
    && parseInt(split[0]) < 24
    && parseInt(split[1]) < 60
    && (
      seconds
      && isNumeric(split[2])
      && parseInt(split[2]) < 60
    )
  );
}

/**
 * Equivalent to `String.substr()`.
 */
export function substr(str: string, from: number, length?: number): string {
  return str.substring(from, typeof length === "number" ? from + length : void 0);
}

export function capitalize(str: string): string {
  return str.split(" ").map(e => e.length ? `${e[0]}${e.substring(1)}` : e).join(" ");
}
