import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { NgI18n, NgI18nTranslator } from "../ng-i18n";
import { FieldError } from "../js-proto";
import { Exception, factory } from "../js-utils";
import { MatFeedConfig, MatFeedProfileConfig, MatFeedProfiles } from "./interfaces";

export const MAT_FEED_CONFIG = new InjectionToken<MatFeedConfig>("");

@Injectable({
  providedIn: "root"
})
export class MatFeed {
  private static readonly SESSION_ERROR_LOG_KEY = "alghorit@session-error-log";
  private static pushErrorLog(error: any): void {
    const log = this.getErrorLog();

    log[new Date().toISOString()] = error;

    sessionStorage.setItem(MatFeed.SESSION_ERROR_LOG_KEY, JSON.stringify(log));
  }

  static getErrorLog(): Record<string, string> {
    return JSON.parse(sessionStorage.getItem(MatFeed.SESSION_ERROR_LOG_KEY) || "{}");
  }

  private defaultMessage: MatFeedConfig["defaultMessage"]
    = /*@i18n*/("Unable to communicate with the server");

  private profiles: MatFeedProfiles = {
    accent:  { panelClass: "mat-accent" },
    primary: { panelClass: "mat-primary" },
    success: { panelClass: "mat-success" },
    warn:    { panelClass: "mat-warn" }
  };

  httpErrorListener: Record<number, ((e: HttpErrorResponse) => void) | undefined> = {
    403: () => this.router?.navigate([ "/" ])
  };

  constructor(
    private snackbar: MatSnackBar,
    @Optional()
    private router?:  Router,
    @Optional()
    @Inject(MAT_FEED_CONFIG)
    config?:          MatFeedConfig
  ) {
    if (config?.defaultMessage) this.defaultMessage = config.defaultMessage;
    Object.assign(this.profiles, config?.profiles);
  }

  /**
   * Show `success` snackbar (toast)
   * @param message message body
   * @param action message title
   */
  success(
    message: string, action?: string, options?: MatFeedProfileConfig
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.open(
      message, action, options ? Object.assign({ ...this.profiles.success }, options) : "success"
    );  }

  /**
   * Show `primary` snackbar (toast)
   * @param message message body
   * @param action message title
   */
  primary(
    message: string, action?: string, options?: MatFeedProfileConfig
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.open(
      message, action, options ? Object.assign({ ...this.profiles.primary }, options) : "primary"
    );  }

  /**
   * Show `warning` snackbar (toast)
   * @param message message body
   * @param action message title
   */
  warn(
    message: string, action?: string, options?: MatFeedProfileConfig
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.open(
      message, action, options ? Object.assign({ ...this.profiles.warn }, options) : "warn"
    );  }

  /**
   * Show `accent` snackbar (toast)
   * @param message message body
   * @param action message title
   */
  accent(
    message: string, action?: string, options?: MatFeedProfileConfig
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.open(
      message, action, options ? Object.assign({ ...this.profiles.accent }, options) : "accent"
    );
  }

  /**
   * Show a custom profile snackbar (toast)
   * @param message message body
   * @param action  message title
   * @param profile MatFeedProfile name or MatFeedProfileConfig
   */
  open(
    message: string,
    action?: string,
    profile?: string | MatFeedProfileConfig
  ): MatSnackBarRef<TextOnlySnackBar> {
    const args = typeof profile === "object" ? profile.args || [] : [];
    return this.snackbar.open(
      NgI18n.get(message, ...(Array.isArray(args) ? args : [ args ])),
      NgI18n.get(action || ""),
      ((typeof profile === "string" ? this.profiles[profile] : profile) || {}).let(it => ({
        ...it,
        panelClass: [ NgI18nTranslator.NG_I18N_IGNORED ].concat(it.panelClass || [])
      }))
    );
  }

  /**
   *
   * @param e Error
   * @param snackbar trigger a snackbar (Default `true`)
   */
  fromError(
    e: unknown, snackbar?: true
  ): MatSnackBarRef<TextOnlySnackBar> | void
  fromError(
    e: unknown, snackbar: false
  ): void
  fromError(
    e: unknown, snackbar = true
  ): MatSnackBarRef<TextOnlySnackBar> | void {
    MatFeed.pushErrorLog(e);

    if (Exception.isException(e) || FieldError.isFieldError(e)) {
      return snackbar ? this.warn(e.message, "OK") : void 0;
    }

    console.error(e);
    if (e instanceof HttpErrorResponse) {
      this.httpErrorListener[e.status]?.(e);
      return snackbar ? this.warn(
        [ 400, 401, 403 ].includes(e.status) ? e.error.message : factory(this.defaultMessage), "OK"
      ) : void 0;
    }
  }

  /**
   * Set a function to trigger on HTTP Error
   * @param status Error status code
   * @param cb
   */
  setOnHttpError(status: number, cb?: typeof this.httpErrorListener[number]): void {
    this.httpErrorListener[status] = cb;
  }
}
