import { ConstructableValidationTask, ValidationError, ValidationErrorTuple } from "../interfaces";
import { ValidateDateTask } from "./date";
import { ValidateEnumTask } from "./enum";
import { ValidateGenericTask } from "./generic";
import { ValidateMaxTask } from "./max";
import { ValidateMinTask } from "./min";
import { ValidateRegexTask } from "./regex";
import { ValidateRequiredTask } from "./required";
import { ValidationTaskTemplate } from "./task-template";
import { ValidateUUIDTask } from "./uuid";

const NATIVE_VALIDATION_TASKS = {
  max:      ValidateMaxTask,
  min:      ValidateMinTask,
  enum:     ValidateEnumTask,
  required: ValidateRequiredTask,
  regex:    ValidateRegexTask,
  uuid:     ValidateUUIDTask,
  date:     ValidateDateTask,
  generic:  ValidateGenericTask
};

export type NativeValidationTask = keyof typeof NATIVE_VALIDATION_TASKS;

export function pattern<T extends ValidationTaskTemplate>(
  task: ConstructableValidationTask<T>,
  errorOrMessage: ValidationError | ValidationErrorTuple | string,
  ...args: Parameters<T["configure"]>
): T
export function pattern<K extends NativeValidationTask>(
  nativeKey: K,
  errorOrMessage: ValidationError | ValidationErrorTuple | string,
  ...args: Parameters<InstanceType<typeof NATIVE_VALIDATION_TASKS[K]>["configure"] >
): InstanceType<typeof NATIVE_VALIDATION_TASKS[K]>

export function pattern(
  taskOrNativeKey: ConstructableValidationTask | NativeValidationTask,
  errorOrMessage: ValidationError | ValidationErrorTuple | string,
  ...args: any[]
): ValidationTaskTemplate {
  if (typeof taskOrNativeKey === "function")
    return new taskOrNativeKey(errorOrMessage).configure(...args);
  else
    return (
      new NATIVE_VALIDATION_TASKS[taskOrNativeKey](errorOrMessage) as ValidationTaskTemplate
    )
      .configure(...args);
}
