import { isFloat } from "./number";
import { isDate, substr } from "./string";

/**
 * Format date to `YYYY-MM-DD` ignoring timezone.
 *
 * Ex: `2021-04-24` -> `2021-04-24T12:00:00.000Z`
 */
export function ignoreTZ(date: string): Date {
  return new Date(substr(date, 0, 10) + "T12:00:00.000Z");
}

/**
 * Get date string as `YYYY-MM-DD`.
 *
 * Ex.: `2021-04-20T13:23:10.000Z` -> `2021-04-20`
 */
export function toDateString(date: Date): string {
  return substr(date.toISOString(), 0, 10);
}

/**
 * Get PSQL date format string.
 */
export function formatPSQLDate(date = new Date()): string {
  const iso = date.toISOString();
  return `${substr(iso, 0, 10)} ${substr(iso, 11, 12)}+00`;
}

/**
 * Returns time in milliseconds
 * @param time time to parse
 */
export function time2Number(time: string, multiplier = 3600): number {
  const sign = time[0] === "-" ? -1 : 1;
  if (sign === -1) time = time.substring(1);
  return time.split(":").reduce((acc, e) => {
    const time = acc + parseInt(e) * multiplier;
    multiplier /= 60;
    return time;
  }, 0) * 1e3 * sign;
}

function parseTimeNode(num: number, round = false): string {
  return Math[round ? "round" : "floor"](num).toString().padStart(2, "0");
}

/**
 * Generate a formatted time with a number
 * @param num time in milliseconds
 */
export function number2Time(num: number, seconds = true, round = false): string {
  const prefix = num <= -((6e4 / (seconds ? 60 : 1)) / (round ? 2 : 1)) ? "-" : "";
  num = Math.abs(num);
  return `${prefix}${parseTimeNode(num / 36e5)}:${parseTimeNode(num % 36e5 / 6e4, !seconds && round)}` +
    (seconds ? `:${parseTimeNode(num % 6e4 / 1e3, round)}` : "");
}

/**
 * Check if the specified param is a valid JS millisecond.
 */
export function isMilliseconds(ms: number | string): boolean {
  ms = typeof ms === "number" ? ms : parseInt(ms);
  if (isNaN(ms) || isFloat(ms)) return false;

  return ms >= -864e13 && ms <= 864e13;
}

/**
 * Check if `seed` is a valid candidate for `dateFromSeed`.
 * @example
 * ```
 * isDateSeed("hello world");     // false
 * isDateSeed(1665750749793.10);  // false
 * isDateSeed(1665750749793);     // true
 * isDateSeed("1665750749793");   // true
 * isDateSeed("2022-10-14");      // true
 * isDateSeed("2022-10-14T12:33:05.975Z"); // true
 * ```
 */
export function isDateSeed(seed: string): seed is string;
export function isDateSeed(seed: number): seed is number;
export function isDateSeed(seed: any): seed is string | number;
export function isDateSeed(seed: any): seed is string | number {
  return isDate(seed) || isMilliseconds(seed);
}

/**
 * Create a `Date` object from a valid date seed or return `undefined`.
 */
export function dateFromSeed(seed: string | number): Date | undefined {
  if (!isDateSeed(seed)) return;

  seed = isDate(seed) ? seed : (isMilliseconds(seed) && typeof seed === "string" ? parseInt(seed) : seed);
  return new Date(seed);
}

/**
 * Verify is value is a Date instance
 */
export function isDateInstance(date: unknown): date is Date {
  return date instanceof Date;
}

/**
 * Verify is value is a valid Date instance
 */
export function isValidDate(date: unknown): date is Date {
  return isDateInstance(date) && !isNaN(date.valueOf());
}
