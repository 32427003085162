import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Moment } from "moment-timezone";
import moment = require("moment-timezone");
import { filter, interval, map, Subscription } from "rxjs";
import { __ } from "src/@prisma/ng-i18n";
import { AppBreakpoints } from "src/utils/breakpoints";
import { HeaderMenuItem } from "src/utils/interfaces";

@Component({
  selector: "theme-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  private _selectedIndex = 0;
  private subscription = new Subscription();

  @Input() menuItems: HeaderMenuItem[] = [];
  @Input("forceHideDate") _hideDate = false;
  @Input() showLogin = false;

  @Input()
  set selectedIndex(value: number) {
    this._selectedIndex = Math.max(Math.min(value, this.menuItems.length - 1), 0);
    this.executeSelectedItemActions();
  }
  get selectedIndex(): number {
    return this._selectedIndex;
  }

  @Output() selectedIndexChange = new EventEmitter<number>();

  dateHTML = "";
  hourHTML = "";
  temperature = 0;

  xSmall = false;

  get hideDate(): boolean {
    return this._hideDate || (this.xSmall && !!this.menuItems.length);
  }

  private get date(): Moment {
    return moment();
  }

  get selected(): HeaderMenuItem | undefined {
    return this.menuItems[this.selectedIndex];
  }

  get showButtons(): boolean {
    return this.menuItems.length > 1;
  }

  get disablePrevButton(): boolean {
    return this.selectedIndex === 0;
  }

  get disableNextButton(): boolean {
    return this.selectedIndex === this.menuItems.length - 1;
  }

  constructor(
    private router:     Router,
    private breakpoint: BreakpointObserver
  ) {
    this.dateHTML = this.getDateHTML();
    this.hourHTML = this.getHourHTML();

    interval(1000).subscribe(() => {
      this.dateHTML = this.getDateHTML();
      this.hourHTML = this.getHourHTML();
    }).also(it => this.subscription.add(it));

    this.breakpoint.observe(AppBreakpoints.XSmall).subscribe(({ breakpoints }) => {
      this.xSmall = breakpoints[AppBreakpoints.XSmall];
    }).also(it => this.subscription.add(it));

    router.events
      .pipe(filter(e => e instanceof NavigationEnd), map(e => e as NavigationEnd))
      .subscribe(ev => {
        this.updateIndex(ev.url);
      })
      .also(it => this.subscription.add(it));
  }

  private updateIndex(url: string): void {
    this.selectedIndex = this.menuItems.findIndex(e => e.path === url);
  }

  ngOnInit(): void {
    this.updateIndex(this.router.routerState.snapshot.url);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private executeSelectedItemActions(): void {
    if (this.selectedIndex > -1 && this.selectedIndex < this.menuItems.length) {
      this.selectedIndexChange.emit(this.selectedIndex);
    }
    this.selected?.also(it => {
      it.select?.(this.selected, this.selectedIndex);

      if (it.path) {
        this.router.navigate([ it.path ]);
      }
    });
  }

  moveNext(): void {
    if (this.selectedIndex < this.menuItems.length - 1) this.selectedIndex++;
  }

  movePrev(): void {
    if (this.selectedIndex) this.selectedIndex--;
  }

  private getDateHTML(): string {
    const sep = "[<span class=\"separator date\"></span>]";
    return this.date.format(__("MM%sDD%sYYYY", sep, sep));
  }

  private getHourHTML(): string {
    const sep = "[<span class=\"separator hour\"></span>]";
    return this.date.format(__("HH%smm%sss", sep, sep));
  }
}
