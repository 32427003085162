import { Injectable } from "@angular/core";
import { Bulk } from "src/@prisma/js-proto";
import { param, toArray } from "src/@prisma/js-utils";
import { NgDefaultTask } from "./default.task";

@Injectable()
export class ParseParamsTask extends NgDefaultTask {
  static STAGE = "parse-params";
  MAP = { [ParseParamsTask.STAGE]: this };

  constructor() {
    super(context => {
      const options = context.requireOptions();

      const http = options.http;
      if (!http) throw new Error("HttpConfig not informed");
      if (!options.queryParams) options.queryParams = {};

      const filters = toArray(options.filter || []);

      for (let i = 0, len = filters.length; i < len; i++) {
        const filter = filters[i];

        const filterKeys = Object.keys(filter);
        for (let i = 0, len = filterKeys.length; i < len; i++) {
          const filterKey = filterKeys[i];
          const filterValue = filter[filterKey];
          if (filterValue === null) filter[filterKey] = { $null: true };
          else if (
            filterValue && typeof filterValue === "object" &&
            "$not" in filterValue && filterValue["$not"] === null) {
            filterValue["$not"] = { $null: true };
          }
        }
      }

      const queryParams = Object.assign(options.queryParams, {
        filter:        options.filter,
        filterOptions: options.filterOptions
      });
      if (!queryParams.filter)        delete queryParams.filter;
      if (!queryParams.filterOptions) delete queryParams.filterOptions;

      const payload = context.payload;

      if (options.event === "delete" && !queryParams.pk && payload instanceof Bulk) {
        const pk = payload.getModelMetadata().primaryColumn;
        if (pk) queryParams.pk = context.payload.toArray().map(
          (e: any) => e[pk]
        );
      }

      const params = options.params;

      let path = http.path.trim();
      if (Array.isArray(params)) {
        for (const e of params) path = path.replace(/(\/:)([^/?\n])+(?=([/?\n]|$))/, `/${e}`);
      } else if (params) for (const key in params) {
        path = path.replace(new RegExp(`\\/:${key}`, "g"), `/${params[key]}`);
      }

      if (Object.keys(queryParams).length) http.path = `${path.split("?")[0]}?${
        param(queryParams)
      }`;
      else http.path = path;

      delete options.params;
      delete options.queryParams;
    });
  }
}
