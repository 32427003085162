import { Field, pattern, ValidateMany, Validate, FieldOptions } from "src/@prisma/js-proto";

export function DateField(options?: FieldOptions<Date>): PropertyDecorator {
  return Field<Date | void>({
    ...options,
    serialize: e => e.value instanceof Date ? e.value.toISOString() : void 0,
    unserialize: e => {
      const value = e.value;
      if (typeof value !== "string" && typeof value !== "number") return;

      const date = new Date(value);
      return isNaN(date.getTime()) ? void 0 : date;
    },
    type: () => Date
  });
}

export function DateFieldValidation(opts?: { code?: string, name?: string | string[] }): PropertyDecorator {
  const name       = opts?.name;
  const validation = [ pattern("date", [ /*@i18n*/("Invalid date input"), opts?.code ]) ];

  if (Array.isArray(name))
    return ValidateMany(name, validation);
  else if (name)
    return Validate(name, validation);
  else
    return Validate(validation);
}
