import { MetadataManager } from "../core";
import { FormatContextOptions } from "../interfaces";
import { ModelMetadata } from "../metadata";
import { TaskContext } from "../runner";
import { FormatterPool } from "./pool";
import { FormatTaskTemplate } from "./task-template";

export class FormatChildTask extends FormatTaskTemplate {
  static readonly singleton = new FormatChildTask();

  override configure(): this {
    return FormatChildTask.singleton as this;
  }

  override async format(value: any, context: TaskContext<any, any, FormatContextOptions>): Promise<any> {
    const formatter = MetadataManager.get(value, ModelMetadata)?.formatter;
    const event     = context.options?.event;

    if (Array.isArray(event)) {
      for (let i = 0; i < event.length; i++)
        await formatter?.get(event[i])?.format(value, context.options);
    } else {
      await formatter?.get(event || FormatterPool.DEFAULT)?.format(value, context.options);
    }

    return value;
  }
}
